import * as photos from '../../images/candidates'
import { CandidateStatuses, LinkTitle, LocationType, Parties, Person, Project } from '../../types'

const rodina: Person[] = [
  {
    alias: 'hatunzev',
    name: 'Хатунцев Станислав Витальевич',
    birthdate: '16.10.1967',
    photo: photos.hatunzev_stanislav,
    candidate: [
      {
        areaNumber: 1,
        callNumber: 5,
        locationType: LocationType.city,
        party: Parties.rodina,
        role: 'временно неработающий',
        status: CandidateStatuses.declined,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 1,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 3,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ryabykh',
    name: 'Рябых Александр Александрович',
    birthdate: '01.09.1969',
    photo: photos.ryabykh_aleksandr,
    candidate: [
      {
        areaNumber: 3,
        callNumber: 5,
        locationType: LocationType.city,
        party: Parties.rodina,
        role: 'коммерческий директор «Мега Авто»',
        status: CandidateStatuses.declined,
      },
    ],
  },
  {
    alias: 'batishev',
    name: 'Батищев Эдуард Владимирович',
    birthdate: '14.12.1975',
    photo: photos.batishev_eduard,
    candidate: [
      {
        areaNumber: 5,
        callNumber: 5,
        locationType: LocationType.city,
        role: 'гендиректор «Аксиос Инвест»',
        party: Parties.rodina,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zaharov',
    name: 'Захаров Александр Александрович',
    birthdate: '06.03.1971',
    photo: photos.zakharov_aleksandr,
    candidate: [
      {
        areaNumber: 6,
        callNumber: 5,
        locationType: LocationType.city,
        role: 'предприниматель',
        party: Parties.rodina,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 0,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'grachev',
    name: 'Грачев Константин Александрович',
    birthdate: '17.03.1978',
    photo: photos.grachev_konstantin,
    candidate: [
      {
        areaNumber: 7,
        callNumber: 5,
        locationType: LocationType.city,
        role: 'временно не работает',
        party: Parties.rodina,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 7,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 3,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'krasiv',
    name: 'Красиков Александр Семенович',
    birthdate: '20.05.1975',
    photo: photos.krasikov_aleksandr,
    candidate: [
      {
        areaNumber: 8,
        callNumber: 5,
        locationType: LocationType.city,
        role: 'председатель Совета кооператива Социально-экономической взаимопомощи «Развитие»',
        party: Parties.rodina,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 8,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'petukhov',
    name: 'Петухов Максим Викторович',
    birthdate: '05.01.1986',
    photo: photos.petukhov_maksim,
    candidate: [
      {
        areaNumber: 9,
        callNumber: 5,
        locationType: LocationType.city,
        role: 'гендиректор «Евростроя»',
        party: Parties.rodina,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 16,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ivanishev',
    name: 'Иванищев Виталий Игоревич',
    birthdate: '06.05.1987',
    photo: photos.ivanishev_vitaliy,
    candidate: [
      {
        areaNumber: 10,
        callNumber: 5,
        locationType: LocationType.city,
        party: Parties.rodina,
        project: Project.git,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 10,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 6,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
    links: [
      { title: LinkTitle.telegram, url: 'http://t.me/Vitaly_scout' },
      { title: LinkTitle.facebook, url: 'https://www.facebook.com/ivanishchev' },
      { title: LinkTitle.vk, url: 'https://vk.com/id206447045' },
      { title: LinkTitle.instagram, url: 'https://www.instagram.com/vitaly_scout/' },
      { title: LinkTitle.twitter, url: 'https://twitter.com/vitaly_scout/' },
      { title: LinkTitle.lj, url: 'https://vitaly1987.livejournal.com//' },
    ],
  },
  {
    alias: 'filatov',
    name: 'Филатов Андрей Викторович',
    birthdate: '22.08.1984',
    photo: photos.filatov_andrey,
    candidate: [
      {
        areaNumber: 11,
        callNumber: 5,
        locationType: LocationType.city,
        role: 'менеджер',
        party: Parties.rodina,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 13,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 7,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kucherin',
    name: 'Кучерин Павел Николаевич',
    birthdate: '29.05.1979',
    photo: photos.kucherin_pavel,
    candidate: [
      {
        areaNumber: 12,
        callNumber: 5,
        locationType: LocationType.city,
        role: 'директор некоммерческой организации дополнительного профессионального образования «ДОН-100»',
        party: Parties.rodina,
        status: CandidateStatuses.declined,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 12,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 22,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shvydkii',
    name: 'Швыдкий Владимир Валерьевич',
    birthdate: '26.10.1982',
    photo: photos.shvidkiy_vladimir,
    candidate: [
      {
        areaNumber: 13,
        callNumber: 5,
        locationType: LocationType.city,
        role: 'гендиректор «СВС Авто Групп»',
        party: Parties.rodina,
        status: CandidateStatuses.declined,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 13,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ofitzerova',
    name: 'Офицерова Ольга Александровна',
    birthdate: '21.08.1968',
    photo: photos.ofizerova_olga,
    candidate: [
      {
        areaNumber: 14,
        callNumber: 5,
        locationType: LocationType.city,
        party: Parties.rodina,
        role: 'предприниматель',
        status: CandidateStatuses.declined,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 16,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kudinova',
    name: 'Кудинова Татьяна Михайловна',
    birthdate: '12.08.1955',
    photo: photos.kudinova_tatiana,
    candidate: [
      {
        areaNumber: 16,
        callNumber: 5,
        locationType: LocationType.city,
        role: 'пенсионер',
        party: Parties.rodina,
        status: CandidateStatuses.declined,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 11,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 5,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'andreev',
    name: 'Андреев Николай Юрьевич',
    birthdate: '21.07.1990',
    photo: photos.andreev_nikolay,
    candidate: [
      {
        areaNumber: 17,
        callNumber: 5,
        locationType: LocationType.city,
        party: Parties.rodina,
        role: 'доцент ВГУ',
        status: CandidateStatuses.declined,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 24,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
    links: [
      {
        title: LinkTitle.vk,
        url: 'https://vk.com/id2953185',
      },
    ],
  },
  {
    alias: 'yakovez',
    name: 'Яковец Елена Владимировна',
    birthdate: '06.12.1982',
    photo: photos.yakovez_elena,
    candidate: [
      {
        areaNumber: 21,
        callNumber: 5,
        locationType: LocationType.city,
        role: 'домохозяйка',
        party: Parties.rodina,
        status: CandidateStatuses.declined,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 21,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 0,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
    links: [
      { title: LinkTitle.facebook, url: 'https://www.facebook.com/elena.yakovec' },
      { title: LinkTitle.vk, url: 'https://vk.com/elena.yakovec' },
    ],
  },
  {
    alias: 'shalygin',
    name: 'Шалыгин Николай Николаевич',
    birthdate: '09.05.1983',
    photo: photos.shalygin_nikolay,
    candidate: [
      {
        areaNumber: 22,
        callNumber: 5,
        locationType: LocationType.city,
        role: 'директор ООО «Парсек»',
        party: Parties.rodina,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 22,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'sokolov_v',
    name: 'Соколов Вячеслав Олегович',
    birthdate: '28.04.1980',
    photo: photos.sokolov_vyacheslav,
    candidate: [
      {
        areaNumber: 24,
        callNumber: 5,
        locationType: LocationType.city,
        role: 'гендиректор «Ника Моторс»',
        party: Parties.rodina,
        status: CandidateStatuses.declined,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 24,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'borisov_igor',
    name: 'Борисов Игорь Борисович',
    birthdate: '05.11.1983',
    photo: photos.borisov_igor,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 20,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 14,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 20,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'vladimirov_vyacheslav',
    name: 'Владимиров Вячеслав Вячеславович',
    birthdate: '01.08.1979',
    photo: photos.vladimirov_vyacheslav,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 19,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 19,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'volkov_andrej',
    name: 'Волков Андрей Валериевич',
    birthdate: '18.02.1970',
    photo: photos.volkov_andrey,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 4,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 7,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 4,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'gushchin_roman',
    name: 'Гущин Роман Александрович',
    birthdate: '15.12.1973',
    photo: photos.gushin_roman,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 6,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 12,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 14,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'dolbnya_gennadij',
    name: 'Долбня Геннадий Викторович',
    birthdate: '15.02.1962',
    photo: photos.dolbnya_gennadiy,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 24,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 24,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ivanov_anton',
    name: 'Иванов Антон Данилович',
    birthdate: '26.07.1988',
    photo: photos.ivanov_anton,
    candidate: [
      {
        areaNumber: 15,
        callNumber: 5,
        locationType: LocationType.city,
        role: 'гендиректор «ЮЦ Солидарность»',
        party: Parties.rodina,
        status: CandidateStatuses.declined,
      },
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 8,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 21,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 8,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kopachev_gennadij',
    name: 'Копачев Геннадий Анатольевич',
    birthdate: '06.05.1967',
    photo: photos.kopachev_gennadiy,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 3,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 3,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kryukov_aleksej',
    name: 'Крюков Алексей Николаевич',
    birthdate: '28.04.1974',
    photo: photos.krukov_alexey,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 9,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 5,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 10,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'malcev_yurij',
    name: 'Мальцев Юрий Николаевич',
    birthdate: '27.12.1968',
    photo: photos.malzev_yuriy,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 5,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 10,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 7,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'masloboev_sergej',
    name: 'Маслобоев Сергей Алексеевич',
    birthdate: '28.05.1961',
    photo: photos.masloboev_sergey,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 22,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 22,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'osinin_kirill',
    name: 'Осинин Кирилл Сергеевич',
    birthdate: '28.08.1977',
    photo: photos.osinin_kirill,
    candidate: [
      {
        party: Parties.rodina,
        role: 'травматолог-ортопед «Воронежской областной клинической больницы №1»',
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 25,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 25,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ostroushko_ivan',
    name: 'Остроушко Иван Петрович',
    birthdate: '14.01.1989',
    photo: photos.ostroushko_ivan,
    candidate: [
      {
        party: Parties.rodina,
        areaNumber: 19,
        locationType: LocationType.city,
        callNumber: 5,
        status: CandidateStatuses.declined,
      },
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 14,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 20,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 11,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'polunin_artem',
    name: 'Полунин Артем Николаевич',
    birthdate: '12.01.1990',
    photo: photos.polunin_artem,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 28,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'polnikov_igor',
    name: 'Польников Игорь Валерьевич',
    birthdate: '11.03.1968',
    photo: photos.polnikov_igor,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 13,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 17,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 13,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ponomarev_aleksandr',
    name: 'Пономарев Александр Сергеевич',
    birthdate: '04.06.1978',
    photo: photos.ponomarev_aleksandr,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 16,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'rosnovskaya_svetlana',
    name: 'Росновская Светлана Анатольевна',
    birthdate: '08.02.1975',
    photo: photos.rosnovskaya_svetlana,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 17,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 18,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'safonov_sergej',
    name: 'Сафонов Сергей Иванович',
    birthdate: '14.11.1976',
    photo: photos.safonov_sergey,
    candidate: [
      {
        areaNumber: 23,
        callNumber: 5,
        locationType: LocationType.city,
        role: 'начальник цеха АО «Завод железобетонных изделий №2',
        party: Parties.rodina,
        status: CandidateStatuses.registered,
      },
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 10,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 2,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 10,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'semin_gennadij',
    name: 'Семин Геннадий Владимирович',
    birthdate: '04.12.1967',
    photo: photos.semin_gennadiy,
    candidate: [
      {
        areaNumber: 4,
        callNumber: 5,
        locationType: LocationType.city,
        role: 'временно не работает',
        party: Parties.rodina,
        status: CandidateStatuses.declined,
      },
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 18,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 4,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'skrynnikov_boris',
    name: 'Скрынников Борис Михайлович',
    birthdate: '17.06.1956',
    photo: photos.skrinnikov_boris,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 12,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 12,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'sokolov_viktor',
    name: 'Соколов Виктор Александрович',
    birthdate: '31.08.1981',
    photo: photos.sokolov_viktor,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 18,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 18,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'suprunov_vladimir',
    name: 'Супрунов Владимир Иванович',
    birthdate: '15.07.1962',
    photo: photos.suprunov_vladimir,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 27,
        status: CandidateStatuses.declined,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 27,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'chekmarev_artem',
    name: 'Чекмарев Артем Витальевич',
    birthdate: '27.05.1993',
    photo: photos.chekmarev_artem,
    links: [{ title: LinkTitle.instagram, url: 'https://instagram.com/a.chekmarev36' }],
    candidate: [
      {
        areaNumber: 18,
        callNumber: 5,
        locationType: LocationType.city,
        role: 'заместитель генерального директора по коммерческим вопросам «Завода железобетонных изделий №2»',
        party: Parties.rodina,
        status: CandidateStatuses.declined,
      },
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 15,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 0,
        listNumber: 1,
        status: CandidateStatuses.registered,
        deputat: {},
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 14,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'cherednikov_vladimir',
    name: 'Чередников Владимир Викторович',
    birthdate: '16.10.1961',
    photo: photos.cherednikov_vladimir,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 21,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 14,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shulekina_natalya',
    name: 'Шулекина Наталья Валерьевна',
    birthdate: '09.05.1982',
    photo: photos.shulekina_nataliya,
    candidate: [
      {
        party: Parties.rodina,
        locationType: LocationType.region,
        callNumber: 7,
        areaNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 11,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'yarkina_irina',
    name: 'Яркина Ирина Владимировна',
    birthdate: '05.05.1979',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 0,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'vysochina_elena',
    name: 'Высочина Елена Анатольевна',
    birthdate: '12.05.1963',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 1,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 25,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'golubcov_evgenij',
    name: 'Голубцов Евгений Владимирович',
    birthdate: '01.06.1983',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 2,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 6,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kolcova_lyubov',
    name: 'Кольцова Любовь Владимировна',
    birthdate: '13.12.1962',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 3,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 5,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'razinkov_aleksandr',
    name: 'Разинков Александр Николаевич',
    birthdate: '02.06.1980',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 4,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'majorov_pavel',
    name: 'Майоров Павел Александрович',
    birthdate: '29.07.1987',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 4,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kastyrin_roman',
    name: 'Кастырин Роман Николаевич',
    birthdate: '26.06.1973',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 5,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 2,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'galushko_vitalij',
    name: 'Галушко Виталий Владимирович',
    birthdate: '05.10.1963',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 6,
        listNumber: 1,
        status: CandidateStatuses.lost,
      },
    ],
  },
  {
    alias: 'timoshenko_evgenij',
    name: 'Тимошенко Евгений Владимирович',
    birthdate: '28.06.1980',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 6,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kartashova_lyudmila',
    name: 'Карташова Людмила Николаевна',
    birthdate: '17.01.1960',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 8,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 27,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'durov_dmitrij',
    name: 'Дуров Дмитрий Владимирович',
    birthdate: '18.04.1990',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 9,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 5,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'korotenko_ulyana',
    name: 'Коротенко Ульяна Шухратовна',
    birthdate: '10.07.1987',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 9,
        listNumber: 2,
        status: CandidateStatuses.lost,
      },
    ],
  },
  {
    alias: 'zhukov_stanislav',
    name: 'Жуков Станислав Андреевич',
    birthdate: '01.03.1992',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 11,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 6,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'bogatyreva_svetlana',
    name: 'Богатырева Светлана Михайловна',
    birthdate: '25.04.1969',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 14,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 1,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'rubcova_olga',
    name: 'Рубцова Ольга Николаевна',
    birthdate: '21.03.1970',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 15,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 10,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ivanova_irina',
    name: 'Иванова Ирина Александровна',
    birthdate: '19.04.1987',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 15,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 11,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'solopov_aleksej',
    name: 'Солопов Алексей Геннадьевич',
    birthdate: '13.11.1981',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 17,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 12,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shushpanov_pavel',
    name: 'Шушпанов Павел Николаевич',
    birthdate: '16.01.1983',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 18,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 2,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'korotenko_andrej',
    name: 'Коротенко Андрей Борисович',
    birthdate: '16.02.1989',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 19,
        listNumber: 1,
        status: CandidateStatuses.lost,
      },
    ],
  },
  {
    alias: 'denisov_aleksandr',
    name: 'Денисов Александр Николаевич',
    birthdate: '29.01.1962',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 19,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 2,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ivanov_svyatoslav',
    photo: photos.ivanov_svyatoslav,
    name: 'Иванов Святослав Павлович',
    birthdate: '14.03.1957',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 20,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 1,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'popova_olga',
    name: 'Попова Ольга Владимировна',
    birthdate: '27.02.1986',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 22,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 13,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kiselev_vladimir',
    name: 'Киселев Владимир Васильевич',
    birthdate: '02.03.1958',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 23,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'gusev_maksim',
    name: 'Гусев Максим Викторович',
    birthdate: '14.07.1991',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.rodina,
        areaNumber: 24,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 12,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'radinovich_lyubomir',
    photo: photos.radinovich_lubomir,
    name: 'Радинович Любомир Миланович',
    birthdate: '17.02.1964',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 0,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kovalev_artur',
    photo: photos.kovalev_artur,
    name: 'Ковалев Артур Александрович',
    birthdate: '27.03.1963',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 0,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'najdanova_yuliya',
    name: 'Найданова Юлия Викторовна',
    birthdate: '05.01.1992',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 1,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'korotkov_aleksej',
    name: 'Коротков Алексей Александрович',
    birthdate: '28.04.1994',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 4,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'fomenko_aleksej',
    name: 'Фоменко Алексей Николаевич',
    birthdate: '18.03.1989',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 7,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shcherbakova_tatyana',
    name: 'Щербакова Татьяна Анатольевна',
    birthdate: '20.11.1969',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 8,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'starodubceva_lyudmila',
    name: 'Стародубцева Людмила Николаевна',
    birthdate: '25.04.1977',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 8,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'chernyh_aleksandr',
    name: 'Черных Александр Иванович',
    birthdate: '13.05.1981',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 13,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'gorlova_natalya',
    name: 'Горлова Наталья Александровна',
    birthdate: '08.12.1974',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 18,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'lavrova_elena',
    name: 'Лаврова Елена Вадимовна',
    birthdate: '26.04.1990',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 19,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'galizin_sergej',
    name: 'Гализин Сергей Николаевич',
    birthdate: '11.09.1983',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 19,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'yudin_denis',
    name: 'Юдин Денис Сергеевич',
    birthdate: '06.09.1987',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 20,
        listNumber: 2,
        status: CandidateStatuses.lost,
      },
    ],
  },
  {
    alias: 'strukov_aleksandr',
    name: 'Струков Александр Викторович',
    birthdate: '01.01.1974',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 20,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'irhina_natalya',
    name: 'Ирхина Наталья Алексеевна',
    birthdate: '02.07.1980',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 22,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'semenov_maksim',
    photo: photos.semenov_maksim,
    name: 'Семенов Максим Вячеславович',
    birthdate: '12.01.1983',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 24,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'hrypchenko_natalya',
    name: 'Хрыпченко Наталья Сергеевна',
    birthdate: '18.02.1976',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 25,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'eremeev_oleg',
    name: 'Еремеев Олег Николаевич',
    birthdate: '02.01.1976',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.rodina,
        areaNumber: 27,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
]

export default rodina
