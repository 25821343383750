import * as photos from '../../images/candidates'
import { CandidateStatuses, LinkTitle, LocationType, Parties, Person } from '../../types'

const kprf: Person[] = [
  {
    name: 'Бирюков Михаил Евгеньевич',
    alias: 'birukov',
    birthdate: '07.05.1961',
    candidate: [
      {
        areaNumber: 1,
        callNumber: 5,
        party: Parties.kprf,
        locationType: LocationType.city,
        status: CandidateStatuses.declined,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 1,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'verbovaya',
    name: 'Вербовая Елена Петровна',
    birthdate: '31.12.1972',
    candidate: [
      {
        areaNumber: 3,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 3,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 2,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zarezkih',
    name: 'Зарецких Алексей Александрович',
    birthdate: '06.10.1977',
    candidate: [
      {
        areaNumber: 4,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 3,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kovalevskii',
    name: 'Ковалевский Валентин Вячеславович',
    photo: photos.kovalevskiy_valentin,
    birthdate: '14.02.1996',
    candidate: [
      {
        areaNumber: 5,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 5,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 3,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shelyakin',
    name: 'Шелякин Артур Дмитриевич',
    birthdate: '05.05.1967',
    candidate: [
      {
        areaNumber: 6,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 6,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kalinin',
    name: 'Калинин Владимир Александрович',
    birthdate: '08.06.1985',
    photo: 'http://gorduma-voronezh.ru/wp-content/uploads/2019/05/kalinin_va.jpg',
    candidate: [
      {
        callNumber: 4,
        areaNumber: 7,
        party: Parties.kprf,
        locationType: LocationType.city,
        deputat: {
          office: 'Ленинский проспект, 15 оф 203',
          phones: ['+74732585816', '+79290064478'],
          website: 'http://gorduma-voronezh.ru/kalinin-vladimir-aleksandrovich/',
        },
      },
      {
        callNumber: 5,
        areaNumber: 7,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        locationType: LocationType.city,
        deputat: {
          // TODO
          website: 'http://gorduma-voronezh.ru/kalinin-vladimir-aleksandrovich/',
        },
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 7,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zazepin',
    name: 'Зацепин Алексей Александрович',
    photo: photos.zacepin_alexey,
    birthdate: '27.05.1979',
    candidate: [
      {
        areaNumber: 8,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
    ],
  },
  {
    alias: 'korchagin',
    name: 'Корчагин Павел Александрович',
    birthdate: '16.06.1969',
    photo: photos.korchagin,
    candidate: [
      {
        areaNumber: 9,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 9,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 5,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kovalev',
    name: 'Ковалёв Василий Николаевич',
    birthdate: '20.07.1980',
    photo: photos.kovalev_vasiliy,
    candidate: [
      {
        party: Parties.kprf,
        areaNumber: 10,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 10,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'sivoplyasov',
    name: 'Сивоплясов Александр Александрович',
    birthdate: '27.09.1990',
    photo: photos.sivoplyasov,
    candidate: [
      {
        areaNumber: 11,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 11,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 6,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'krylov',
    name: 'Крылов Андрей Игоревич',
    birthdate: '08.06.1966',
    candidate: [
      {
        areaNumber: 12,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 12,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'spivakov',
    name: 'Спиваков Максим Васильевич',
    birthdate: '12.01.1977',
    candidate: [
      {
        areaNumber: 13,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 13,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 7,
        listNumber: 2,
        status: CandidateStatuses.lost,
      },
    ],
  },
  {
    alias: 'pomeranzev',
    name: 'Померанцев Андрей Сергеевич',
    birthdate: '08.03.1976',
    photo: 'http://gorduma-voronezh.ru/wp-content/uploads/2019/05/pomerantsev_as.jpg',
    candidate: [
      {
        areaNumber: 14,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
        deputat: {
          // TODO
          office: 'ул. 20 лет Октября, 105/1, 2 подъезд, 1 этаж',
          website: 'http://gorduma-voronezh.ru/pomerancev-andrej-sergeevich/',
          time: 'в 17.00 по вторникам',
        },
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        areaNumber: 0,
        listNumber: 2,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 8,
        listNumber: 1,
        status: CandidateStatuses.mandatRejected,
      },
    ],
  },
  {
    alias: 'kudrevatykh',
    name: 'Кудреватых Вячеслав Анатольевич',
    birthdate: '06.10.1977',
    candidate: [
      {
        areaNumber: 15,
        party: Parties.kprf,
        locationType: LocationType.city,
        callNumber: 5,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ryabov',
    name: 'Рябов Алексей Анатольевич',
    birthdate: '26.12.1992',
    photo: photos.ryabov_aleksey,
    links: [{ title: LinkTitle.vk, url: 'https://vk.com/id108611370' }],
    candidate: [
      {
        areaNumber: 16,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 16,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'sitnikov',
    name: 'Ситников Максим Михайлович',
    photo: photos.sitnikov_maksim,
    birthdate: '17.05.1989',
    candidate: [
      {
        areaNumber: 17,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 17,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 5,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'gluhovcev_daniil',
    name: 'Глуховцев Даниил Сергеевич',
    photo: photos.gluhovzev_daniil,
    birthdate: '02.08.1998',
    candidate: [
      {
        areaNumber: 18,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 18,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shabunin_aleksandr',
    name: 'Шабунин Александр Николаевич',
    photo: photos.shabunin_aleksandr,
    birthdate: '19.07.1985',
    candidate: [
      {
        areaNumber: 19,
        party: Parties.kprf,
        locationType: LocationType.city,
        callNumber: 5,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 19,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 2,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'emelyanov_evgeniy',
    name: 'Емельянов Евгений Владимирович',
    birthdate: '04.07.1968',
    photo: photos.emelyanov_evgeniy,
    links: [
      { title: LinkTitle.vk, url: 'https://vk.com/vrn20' },
      { title: LinkTitle.whatsapp, url: 'https://wa.me/79009259595' },
      { title: LinkTitle.instagram, url: 'https://instagram.com/eemvrn' },
    ],
    candidate: [
      {
        areaNumber: 20,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 20,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
    bio:
      'Родился 4 июля 1968 года в городе Воронеже. ' +
      'В 1992 году получил диплом, с присвоением квалификации инженер-механик, в Воронежском политехническом институте. ' +
      'В 2002 году с отличием закончил экономический факультет Воронежского филиала ВЗФЭИ г. Москва по специальности экономист. ' +
      'Трудовую деятельность начал в 1992 году специалистом по автоматизации финансово-хозяйственной деятельности завода АО «Автогенмаш». ' +
      'С 1996 по 2008 г. - директор ООО «Информ-Бухгалтер-Воронеж». ' +
      'С января 2008 г. по настоящее время - директор ООО «Инфо-Бизнес-Воронеж», которое занимается развитием малого, среднего и крупного предпринимательства г. Воронежа. ' +
      'По настоящее время активно участвует в деятельности Центра социальных инициатив Северного района и экологических сообществ: Эко Северный Воронеж , Волонтеры Северный лес и других, в качестве координатора. ' +
      'Женат. Воспитывает сына.',
  },
  {
    alias: 'kadanzev',
    name: 'Каданцев Михаил Михайлович',
    birthdate: '15.01.1991',
    candidate: [
      {
        callNumber: 5,
        areaNumber: 21,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 21,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kobylkin_vladislav',
    name: 'Кобылкин Владислав Васильевич',
    birthdate: '27.06.1983',
    candidate: [
      {
        callNumber: 5,
        areaNumber: 22,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 22,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zverev_nikolaj',
    name: 'Зверев Николай Николаевич',
    photo: photos.zverev_nikolay,
    birthdate: '04.09.1997',
    candidate: [
      {
        callNumber: 5,
        areaNumber: 23,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 23,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'roslik_denis',
    name: 'Рослик Денис Викторович',
    birthdate: '09.07.1986',
    photo: photos.roslik,
    candidate: [
      {
        callNumber: 5,
        areaNumber: 24,
        party: Parties.kprf,
        status: CandidateStatuses.registered,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 24,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 0,
        listNumber: 3,
        status: CandidateStatuses.registered,
        deputat: {},
      },
    ],
  },
  {
    alias: 'babenkov_konstantin',
    name: 'Бабенков Константин Викторович',
    birthdate: '18.05.1988',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 26,
        status: CandidateStatuses.lost,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 6,
        listNumber: 1,
        status: CandidateStatuses.lost,
      },
    ],
  },
  {
    alias: 'berezhnoj_sergej',
    name: 'Бережной Сергей Александрович',
    photo: photos.berezhnoy_sergey,
    birthdate: '05.07.1978',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 11,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 1,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'verlin_aleksandr',
    name: 'Верлин Александр Анатольевич',
    birthdate: '08.07.1982',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 13,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 3,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'grudinin_grigorij',
    name: 'Грудинин Григорий Михайлович',
    birthdate: '12.05.1975',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 18,
        status: CandidateStatuses.lost,
      },
    ],
  },
  {
    alias: 'dvornikov_sergej',
    name: 'Дворников Сергей Васильевич',
    birthdate: '17.05.1951',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 17,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 7,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'dedov_viktor',
    name: 'Дедов Виктор Анатольевич',
    birthdate: '27.09.1978',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 5,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 5,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'derkachev_nikolaj',
    name: 'Деркачев Николай Петрович',
    birthdate: '04.09.1958',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 21,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 1,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zhabin_vasilij',
    name: 'Жабин Василий Александрович',
    birthdate: '27.04.1980',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 4,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 4,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zlobina_lyubov',
    name: 'Злобина Любовь Владимировна',
    birthdate: '05.07.1973',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 24,
        status: CandidateStatuses.lost,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 4,
        listNumber: 1,
        status: CandidateStatuses.lost,
      },
    ],
  },
  {
    alias: 'kasatkin_yurij',
    name: 'Касаткин Юрий Станиславович',
    birthdate: '13.06.1959',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 16,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 6,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kolomencev_denis',
    name: 'Коломенцев Денис Витальевич',
    photo: photos.kolomenzev_denis,
    birthdate: '02.07.1972',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 6,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 6,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'konopelko_elena',
    name: 'Конопелько Елена Валерьевна',
    birthdate: '12.07.1974',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 14,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 4,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'melkumov_konstantin',
    name: 'Мелкумов Константин Растиславович',
    photo: photos.melkumov_konstantin,
    birthdate: '15.04.1980',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 9,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 9,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'proshunin_aleksandr',
    name: 'Прошунин Александр Вячеславович',
    birthdate: '02.08.1984',
    candidate: [
      {
        areaNumber: 2,
        party: Parties.kprf,
        callNumber: 5,
        locationType: LocationType.city,
        status: CandidateStatuses.registered,
      },
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 2,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 1,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'rogatnev_andrej',
    name: 'Рогатнев Андрей Иванович',
    photo: photos.rogatnev_andrey,
    birthdate: '23.06.1966',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 0,
        listNumber: 2,
        status: CandidateStatuses.registered,
        deputat: {},
      },
    ],
  },
  {
    alias: 'rudov_nikolaj',
    name: 'Рудов Николай Ильич',
    birthdate: '21.12.1981',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 20,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 0,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'sakuta_valerij',
    name: 'Сакута Валерий Владимирович',
    birthdate: '19.11.1959',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 3,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 5,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 3,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'sinicin_vitalij',
    name: 'Синицин Виталий Александрович',
    birthdate: '28.03.1970',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 15,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 5,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'skrynnikov_vladimir',
    name: 'Скрынников Владимир Евгеньевич',
    birthdate: '20.03.1959',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 25,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 5,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'spivak_oleg',
    name: 'Спивак Олег Игоревич',
    birthdate: '30.03.1974',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 8,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 8,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'sukochev_andrej',
    name: 'Сукочев Андрей Иванович',
    photo: photos.sukochev_andrey,
    birthdate: '28.03.1963',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 12,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 2,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'suhinin_aleksandr',
    name: 'Сухинин Александр Анатольевич',
    birthdate: '20.03.1968',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 19,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 9,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'tominov_evgenij',
    name: 'Томинов Евгений Иванович',
    birthdate: '03.08.1961',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 28,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 8,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'turkov_viktor',
    name: 'Турков Виктор Васильевич',
    birthdate: '02.11.1961',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 7,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 7,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'carenko_aleksandr',
    name: 'Царенко Александр Михайлович',
    birthdate: '10.01.1970',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 22,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 2,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'chernolyahov_yurij',
    name: 'Черноляхов Юрий Иванович',
    birthdate: '23.02.1957',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 27,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shevchenko_pavel',
    name: 'Шевченко Павел Александрович',
    birthdate: '22.05.1989',
    candidate: [
      {
        callNumber: 7,
        locationType: LocationType.region,
        party: Parties.kprf,
        areaNumber: 23,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 3,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ashifin_konstantin',
    name: 'Ашифин Константин Григорьевич',
    birthdate: '26.08.1969',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        areaNumber: 0,
        listNumber: 1,
        status: CandidateStatuses.registered,
        party: Parties.kprf,
        deputat: {},
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 0,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'gukov_oleg',
    name: 'Гуков Олег Александрович',
    birthdate: '14.10.1972',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        areaNumber: 0,
        listNumber: 3,
        status: CandidateStatuses.registered,
        party: Parties.kprf,
        deputat: {},
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 5,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'borzikov_aleksej',
    name: 'Борзиков Алексей Валерьевич',
    birthdate: '29.11.1987',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 1,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 1,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'volkov_vladimir',
    name: 'Волков Владимир Владимирович',
    birthdate: '07.05.1959',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 2,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 1,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'rogatnev_maksim',
    name: 'Рогатнев Максим Андреевич',
    birthdate: '30.04.1997',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 3,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 1,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'rumyancev_dmitrij',
    name: 'Румянцев Дмитрий Сергеевич',
    birthdate: '09.07.1964',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 4,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 2,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'solodkov_aleksandr',
    name: 'Солодков Александр Александрович',
    birthdate: '02.07.1971',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 4,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'tuzikov_valerij',
    name: 'Тузиков Валерий Валерьевич',
    birthdate: '15.05.1994',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 6,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zvyagina_svetlana',
    name: 'Звягина Светлана Александровна',
    birthdate: '01.11.1968',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 7,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'bovkunov_sergej',
    name: 'Бовкунов Сергей Валентинович',
    birthdate: '15.11.1970',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 8,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zacepin_aleksandr',
    name: 'Зацепин Александр Александрович',
    birthdate: '27.05.1979',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 8,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'skisov_yurij',
    name: 'Скисов Юрий Александрович',
    birthdate: '01.01.1995',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 9,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 5,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'izotov_evgenij',
    name: 'Изотов Евгений Николаевич',
    birthdate: '29.09.1976',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 10,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'popov_pavel',
    name: 'Попов Павел Александрович',
    birthdate: '12.07.1986',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 11,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'rykunov_konstantin',
    name: 'Рыкунов Константин Михайлович',
    birthdate: '09.10.1971',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 12,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'vasilenko_kirill',
    name: 'Василенко Кирилл Сергеевич',
    birthdate: '02.09.1989',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 13,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 7,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'dudarev_vladimir',
    name: 'Дударев Владимир Александрович',
    birthdate: '15.05.1977',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 14,
        listNumber: 1,
        status: CandidateStatuses.registered,
        deputat: {},
      },
    ],
  },
  {
    alias: 'grichenyov_vladimir',
    name: 'Гриченёв Владимир Васильевич',
    birthdate: '26.10.1972',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 14,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'pevunov_oleg',
    name: 'Певунов Олег Владимирович',
    birthdate: '11.04.1981',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 15,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'borisov_mihail',
    name: 'Борисов Михаил Юрьевич',
    birthdate: '18.04.1979',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 15,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'biryuchenko_vadim',
    name: 'Бирюченко Вадим Павлович',
    birthdate: '09.05.1962',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 16,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'popov_ivan',
    name: 'Попов Иван Васильевич',
    birthdate: '13.01.1976',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 17,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ponomaryov_dmitrij',
    name: 'Пономарёв Дмитрий Владимирович',
    birthdate: '11.07.1988',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 18,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shkarlat_roman',
    name: 'Шкарлат Роман Николаевич',
    birthdate: '14.07.1986',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 19,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kozlov_maksim',
    name: 'Козлов Максим Геннадьевич',
    birthdate: '07.12.1992',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 20,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'popov_gennadij',
    name: 'Попов Геннадий Анатольевич',
    birthdate: '19.03.1951',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 21,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'tonkih_aleksandr',
    name: 'Тонких Александр Иванович',
    birthdate: '08.09.1968',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 22,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'dadakin_vladimir',
    name: 'Дадакин Владимир Эдуардович',
    birthdate: '25.11.1966',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 23,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'sobackij_vasilij',
    name: 'Собацкий Василий Николаевич',
    birthdate: '18.04.1960',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.kprf,
        areaNumber: 24,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'rudakov_sergej',
    name: 'Рудаков Сергей Иванович',
    birthdate: '10.02.1957',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 0,
        listNumber: 1,
        status: CandidateStatuses.registered,
        deputat: {},
      },
    ],
  },
  {
    alias: 'chirkov_evgenij',
    name: 'Чирков Евгений Викторович',
    birthdate: '01.03.1990',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 2,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'petrosyan_grigorij',
    name: 'Петросян Григорий Владимирович',
    birthdate: '06.06.1982',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 4,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'razzhivin_yurij',
    name: 'Разживин Юрий Александрович',
    birthdate: '15.11.1978',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 4,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'livencev_dmitrij',
    name: 'Ливенцев Дмитрий Вячеславович',
    birthdate: '12.05.1975',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 6,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'degtyareva_margarita',
    name: 'Дегтярева Маргарита Васильевна',
    birthdate: '12.09.1961',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 8,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kalinichenko_denis',
    name: 'Калиниченко Денис Константинович',
    birthdate: '26.04.1979',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 9,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'cukanov_vadim',
    name: 'Цуканов Вадим Михайлович',
    birthdate: '13.02.1991',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 9,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kovalenko_anzhelika',
    name: 'Коваленко Анжелика Валерьевна',
    birthdate: '27.09.1996',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 0,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'luckevich_aleksandr',
    name: 'Луцкевич Александр Леонидович',
    birthdate: '10.09.1972',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 0,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'sychev_aleksandr',
    name: 'Сычев Александр Иванович',
    birthdate: '26.08.1960',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 1,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'sidelnikova_mariya',
    name: 'Сидельникова Мария Анатольевна',
    birthdate: '16.07.1987',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 1,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'makarov_dmitrij',
    name: 'Макаров Дмитрий Александрович',
    birthdate: '26.12.1987',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 2,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'chanchin_evgenij',
    name: 'Чанчин Евгений Васильевич',
    birthdate: '01.08.1983',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 3,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'andreev_vladimir',
    name: 'Андреев Владимир Кузьмич',
    birthdate: '05.02.1957',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 3,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'makeev_vadim',
    name: 'Макеев Вадим Михайлович',
    birthdate: '06.02.1975',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 4,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kireev_dmitrij',
    name: 'Киреев Дмитрий Алексеевич',
    birthdate: '12.06.1984',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 4,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'lesnyh_lyudmila',
    name: 'Лесных Людмила Кузьминична',
    birthdate: '16.12.1965',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 5,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ponomarev_igor',
    name: 'Пономарев Игорь Алексеевич',
    birthdate: '27.02.1970',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 6,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'merenkov_sergej',
    name: 'Меренков Сергей Валентинович',
    birthdate: '27.03.1966',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 6,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kopij_sergej',
    name: 'Копий Сергей Николаевич',
    birthdate: '08.02.1953',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 7,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shein_viktor',
    name: 'Шеин Виктор Иванович',
    birthdate: '09.11.1956',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 7,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'aistov_viktor',
    name: 'Аистов Виктор Максимович',
    birthdate: '24.03.1954',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 8,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'nemcov_ivan',
    name: 'Немцов Иван Петрович',
    birthdate: '14.02.1959',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 8,
        listNumber: 2,
        status: CandidateStatuses.lost,
      },
    ],
  },
  {
    alias: 'bashlykov_gennadij',
    name: 'Башлыков Геннадий Николаевич',
    birthdate: '05.02.1956',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 8,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shatilov_anatolij',
    name: 'Шатилов Анатолий Александрович',
    birthdate: '29.11.1965',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 9,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ryazanceva_ekaterina',
    name: 'Рязанцева Екатерина Александровна',
    birthdate: '15.12.1963',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 9,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'tyulpin_gennadij',
    name: 'Тюльпин Геннадий Анатольевич',
    birthdate: '10.11.1964',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 0,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ruban_marina',
    name: 'Рубан Марина Юрьевна',
    birthdate: '10.12.1985',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 0,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'rybin_sergej',
    name: 'Рыбин Сергей Валентинович',
    birthdate: '26.05.1968',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 1,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'lanovenko_evgenij',
    name: 'Лановенко Евгений Владимирович',
    birthdate: '07.01.1989',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 2,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'bezzubcev_aleksandr',
    name: 'Беззубцев Александр Петрович',
    birthdate: '27.05.1966',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 2,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'chukin_aleksej',
    name: 'Чукин Алексей Александрович',
    birthdate: '11.11.1988',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 3,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'yanshin_dmitrij',
    name: 'Яньшин Дмитрий Владимирович',
    birthdate: '15.05.1989',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 3,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'pilipenko_ivan',
    name: 'Пилипенко Иван Андреевич',
    birthdate: '19.08.1954',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 4,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'karionov_aleksej',
    name: 'Карионов Алексей Митрофанович',
    birthdate: '27.01.1953',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 4,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'nemchinov_viktor',
    name: 'Немчинов Виктор Петрович',
    birthdate: '28.09.1954',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 5,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'suhoverhov_sergej',
    name: 'Суховерхов Сергей Александрович',
    birthdate: '22.12.1974',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 6,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kosenko_maksim',
    name: 'Косенко Максим Александрович',
    birthdate: '14.02.1991',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 6,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'voronin_nikolaj',
    name: 'Воронин Николай Иванович',
    birthdate: '23.08.1948',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 7,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'tolstun_sergej',
    name: 'Толстун Сергей Владимирович',
    birthdate: '09.12.1977',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 7,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'yashnev_denis',
    name: 'Яшнев Денис Юрьевич',
    birthdate: '11.05.1977',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 7,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'moiseenko_anzhelika',
    name: 'Моисеенко Анжелика Самедовна',
    birthdate: '09.08.1970',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kprf,
        areaNumber: 8,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'reshetnikov_yurij',
    name: 'Решетников Юрий Иванович',
    birthdate: '10.02.1962',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.kpkr,
        areaNumber: 8,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
]

export default kprf
