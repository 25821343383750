const areas = {
  type: 'FeatureCollection',
  metadata: {
    name: 'Карта муниципальных депутатов Воронеж',
    creator: 'Yandex Map Constructor',
  },
  features: [
    {
      type: 'Feature',
      id: 0,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.142750109283924, 51.71495569214147],
            [39.14156836140989, 51.713107379489394],
            [39.14487820733414, 51.712227619837854],
            [39.14450269807221, 51.71054469833859],
            [39.145103512891474, 51.71056469381494],
            [39.14561849702236, 51.71101792222934],
            [39.14665919412015, 51.711041250040765],
            [39.14683085549714, 51.70925163866848],
            [39.14513569939976, 51.70888504282613],
            [39.14531272519422, 51.706908706562444],
            [39.14515179265333, 51.70661541485969],
            [39.14516788590764, 51.70635878305208],
            [39.147909103520675, 51.70645210387967],
            [39.14795470107383, 51.70589550894204],
            [39.14817196000402, 51.7057321953472],
            [39.148703037388785, 51.70552888576018],
            [39.15036064256037, 51.70556554821104],
            [39.15524226296752, 51.70575885882955],
            [39.15495794881166, 51.708661751200175],
            [39.15475946534476, 51.710461383787916],
            [39.156325875409465, 51.71053136804475],
            [39.15654581654809, 51.70862509132045],
            [39.1579781161618, 51.70867174940718],
            [39.157940565235634, 51.708901706421415],
            [39.16007023919372, 51.70900502005684],
            [39.16044769887444, 51.70602202526761],
            [39.16305480603736, 51.70613034487775],
            [39.16281877164389, 51.70817503689584],
            [39.164696317954075, 51.708241691959365],
            [39.16479287747837, 51.70749514961363],
            [39.16599450711789, 51.707568471283814],
            [39.16594086293719, 51.70818836791526],
            [39.16944919232869, 51.708328343394015],
            [39.16953502301709, 51.707868422332965],
            [39.17043624524619, 51.70794174339563],
            [39.170404058738, 51.70870827463364],
            [39.17202411298332, 51.70878159433053],
            [39.17198119763921, 51.70924817140855],
            [39.172099214836095, 51.70924817141032],
            [39.17189536695049, 51.711680959239025],
            [39.167823773665475, 51.711507669111185],
            [39.167759400649636, 51.71225081243923],
            [39.17088149194278, 51.71236744817044],
            [39.17075811032817, 51.7134604769011],
            [39.17139647607355, 51.713473806356035],
            [39.171267730040604, 51.71453015309161],
            [39.17281804685148, 51.71461346013894],
            [39.17276440267104, 51.71515995054273],
            [39.1722601473761, 51.71528657542122],
            [39.17134283189377, 51.71566311571815],
            [39.17082479104225, 51.71611363527566],
            [39.17078187569791, 51.71679006023153],
            [39.16426410779258, 51.71651349362047],
            [39.16442504033274, 51.71547385452587],
            [39.16467180356264, 51.71372107525394],
            [39.16240801915408, 51.713637766554356],
            [39.16183402642469, 51.71818952785315],
            [39.16291763886679, 51.71823617602635],
            [39.16288545235845, 51.71885592570751],
            [39.16172137364537, 51.71878595444731],
            [39.161640907374924, 51.71956562861713],
            [39.16355332240243, 51.719602279637314],
            [39.16336288556249, 51.7226342149278],
            [39.16282644376019, 51.72378196567762],
            [39.16715016469239, 51.72597577059566],
            [39.166227484790525, 51.72698851611138],
            [39.16466107472633, 51.7265354485681],
            [39.162450934497826, 51.72598909634199],
            [39.158052111713765, 51.724776437200745],
            [39.1554128180431, 51.72341715402572],
            [39.142750109283924, 51.71495569214147],
          ],
        ],
      },
      properties: {
        description: '19',
        fill: '#ed4543',
        'fill-opacity': 0.5,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 1,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.14274359101376, 51.71495425381932],
            [39.14729605265309, 51.71799529373223],
            [39.1493764879859, 51.719377306541304],
            [39.15041521492873, 51.7200803374298],
            [39.15234013317921, 51.72136309059582],
            [39.154562156874476, 51.72284979707527],
            [39.155408247058965, 51.72341541957773],
            [39.15622912786899, 51.72383728485415],
            [39.158054393011874, 51.724775460406],
            [39.161314479823375, 51.72567858880447],
            [39.164671194391616, 51.72654060170932],
            [39.16623217002443, 51.726986262401205],
            [39.16715216771667, 51.72597684826357],
            [39.16283112899366, 51.72378304339395],
            [39.16336757079668, 51.722630295143105],
            [39.1635587877749, 51.71960832524003],
            [39.161649054956484, 51.7195616784917],
            [39.16172952122714, 51.71878866819192],
            [39.16289359993953, 51.71886530342711],
            [39.162925786447225, 51.718235557842604],
            [39.16183144516938, 51.71818890967271],
            [39.162419532216134, 51.71363700071284],
            [39.16466722337062, 51.71372364175892],
            [39.164270256436566, 51.71651605996623],
            [39.17078802434251, 51.716795958675704],
            [39.170830939687406, 51.71611620163961],
            [39.17136738149022, 51.71565802627491],
            [39.17226323930099, 51.715276487591765],
            [39.17277285901299, 51.71516319166577],
            [39.17281577435779, 51.71461336902183],
            [39.17127082196496, 51.71453006197429],
            [39.17138883916192, 51.7134737152652],
            [39.1707612022523, 51.71345372108154],
            [39.170884583866574, 51.71236735707693],
            [39.16776785699179, 51.71225072134484],
            [39.16783759442573, 51.71151424303062],
            [39.1718930944564, 51.71168086813986],
            [39.17212376443101, 51.70922808430173],
            [39.172794316684865, 51.70927140908959],
            [39.17323956338141, 51.709414713860355],
            [39.17327174988911, 51.70962133854216],
            [39.17661914674005, 51.709761309561685],
            [39.1767157062642, 51.70935472587057],
            [39.178013895427675, 51.70940804853094],
            [39.17806753960822, 51.70910144237032],
            [39.178754185115956, 51.70912810385857],
            [39.17877564278806, 51.7094147138603],
            [39.179269169246645, 51.70942804451332],
            [39.17931208459124, 51.708961469298984],
            [39.18097502116816, 51.70901663490043],
            [39.178636134907684, 51.72822217939428],
            [39.18026691798825, 51.73437794653354],
            [39.19254950864251, 51.770090969337325],
            [39.1764991698976, 51.77112934591504],
            [39.17053393704848, 51.76525820858665],
            [39.16435412747841, 51.75609716892016],
            [39.16924647672141, 51.7551383474721],
            [39.161242765020404, 51.74664123005442],
            [39.15980510098866, 51.74795985359417],
            [39.15834597928485, 51.74698753897261],
            [39.14452723844076, 51.74786661885647],
            [39.14422683103088, 51.74740044227127],
            [39.144677442144584, 51.74717401189949],
            [39.14384059293246, 51.74484304469221],
            [39.141780656408955, 51.74509612698236],
            [39.14051465375396, 51.74540248784759],
            [39.1396563468688, 51.74485636484815],
            [39.138280395213485, 51.744894581281926],
            [39.137894157115156, 51.7443218114937],
            [39.138559344950096, 51.74373571392238],
            [39.14055490845716, 51.74324285323085],
            [39.13791561478736, 51.740272254077055],
            [39.1374220883285, 51.737408037622195],
            [39.13519049042787, 51.7320121574743],
            [39.13214350098701, 51.72838789421508],
            [39.12439728135153, 51.72208471135182],
            [39.14274359101376, 51.71495425381932],
          ],
        ],
      },
      properties: {
        description: '20',
        fill: '#82cdff',
        'fill-opacity': 0.5,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 2,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.19376590121443, 51.773704767297495],
            [39.18035485613991, 51.734604889478575],
            [39.1786597000425, 51.728235952640816],
            [39.180494331008646, 51.71304946142449],
            [39.19268228877248, 51.713535988409724],
            [39.194527648574656, 51.712916165441456],
            [39.19580974448375, 51.711886440666795],
            [39.19638373721296, 51.711006657144864],
            [39.1981969105069, 51.710810036505976],
            [39.201329730636175, 51.709716943390504],
            [39.20423188079036, 51.70967028638637],
            [39.20890965331238, 51.70596089975804],
            [39.209118865615466, 51.70293450702852],
            [39.206082605010515, 51.699097895143275],
            [39.206286452895746, 51.698077854685735],
            [39.20761146414926, 51.69815785869327],
            [39.20862533915673, 51.69293063135086],
            [39.20909740794333, 51.69051683433213],
            [39.21033658850794, 51.68824461298004],
            [39.21106218967938, 51.68692371304321],
            [39.214054130202676, 51.68171404741303],
            [39.21689995396759, 51.68274946447094],
            [39.21738006938121, 51.68250770255472],
            [39.21673923457676, 51.68213008774795],
            [39.21669631923252, 51.68179995281738],
            [39.21579509700347, 51.68125305731017],
            [39.216685590396544, 51.680622785345896],
            [39.21653538669145, 51.68038934904765],
            [39.2159989448887, 51.68035600090622],
            [39.21611427987625, 51.67924716115118],
            [39.215623435626604, 51.679122102323014],
            [39.21531229938072, 51.67875025869121],
            [39.21528051545687, 51.67839523849329],
            [39.21582191909358, 51.677534658763825],
            [39.216921624789656, 51.67753799378598],
            [39.21789258445284, 51.67729120133586],
            [39.218788442264135, 51.67707942565648],
            [39.219496545443924, 51.67726118594656],
            [39.220601615557875, 51.6769977166634],
            [39.220617708812135, 51.67682429300327],
            [39.2210575910906, 51.67685097360961],
            [39.221282896647814, 51.677304541503744],
            [39.2243164750437, 51.67738458242487],
            [39.22438084806022, 51.67780812992117],
            [39.2250406714778, 51.677786452389896],
            [39.22457396710897, 51.676679216932634],
            [39.2250218960147, 51.676620852862705],
            [39.22493874753527, 51.676394066044175],
            [39.225488600383, 51.67632736382169],
            [39.225679037223266, 51.67651079469568],
            [39.2260202538296, 51.676454249668765],
            [39.22586142743652, 51.67575538821011],
            [39.225402769694654, 51.67578540460454],
            [39.22541618073998, 51.67613059169005],
            [39.2248743745188, 51.67620229688841],
            [39.22472148860492, 51.67601719718919],
            [39.224072394023295, 51.67564366034697],
            [39.22397583449872, 51.67513838014041],
            [39.224603471408365, 51.675178402540894],
            [39.22576755012101, 51.67500663948353],
            [39.22811403864715, 51.679328894876186],
            [39.2283983528027, 51.68097630110847],
            [39.2282964288601, 51.68276370207363],
            [39.228945523441624, 51.684230918516626],
            [39.230098873318134, 51.6848811468845],
            [39.2299666578899, 51.685530048005965],
            [39.23127904528461, 51.687431952140805],
            [39.2326362430462, 51.69001928804879],
            [39.233419448078386, 51.6918163320814],
            [39.23380836838578, 51.693234912664444],
            [39.23466667527031, 51.69604860419134],
            [39.23678562039206, 51.70043214522279],
            [39.237418621719584, 51.70198544973238],
            [39.23986874148698, 51.70813220508815],
            [39.24102351063584, 51.70899465293217],
            [39.24153313034842, 51.711000890554374],
            [39.24196228379084, 51.71586947737438],
            [39.24162432545505, 51.718795041368665],
            [39.24173161381558, 51.719424779120224],
            [39.243576973617934, 51.720770855654735],
            [39.244928806961525, 51.721050727896],
            [39.244735687912296, 51.72150385069017],
            [39.24007937306251, 51.72339625537264],
            [39.23971459263638, 51.7242891228961],
            [39.23311972800199, 51.72783958293244],
            [39.227164234252136, 51.73067109808736],
            [39.22731443795683, 51.73163044160861],
            [39.2261986390065, 51.733615684727184],
            [39.22606989297396, 51.73612043017248],
            [39.228923763365685, 51.75006717826321],
            [39.205234493346154, 51.75042677928952],
            [39.207541193098876, 51.77278963838004],
            [39.19376590121443, 51.773704767297495],
          ],
        ],
      },
      properties: {
        description: '23',
        fill: '#56db40',
        'fill-opacity': 0.5,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 3,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.164672726793796, 51.708247742445884],
            [39.16282736699091, 51.708194418438744],
            [39.16354619900696, 51.701861739988885],
            [39.16921894979062, 51.70210374976357],
            [39.19170939366335, 51.70311168130412],
            [39.19291102330208, 51.70313501321113],
            [39.192986125154505, 51.70244838343262],
            [39.192256564302454, 51.70236838704798],
            [39.19212781826972, 51.70278836647626],
            [39.19165574948312, 51.7028350306153],
            [39.19175767342564, 51.70131842133381],
            [39.18935977856637, 51.70119509041945],
            [39.189413422746604, 51.70052176434147],
            [39.1957541648579, 51.700821762353684],
            [39.195813173456216, 51.70043176454604],
            [39.19489049355512, 51.70036843125015],
            [39.19494413773541, 51.69978176069472],
            [39.19431650082591, 51.69975509366865],
            [39.1945471708012, 51.698828404735366],
            [39.1964569036198, 51.69889174019789],
            [39.19682168404587, 51.69594154643488],
            [39.20561396519686, 51.69234104805942],
            [39.20675122181917, 51.69230104090469],
            [39.20681559483551, 51.69374794372926],
            [39.20843028466245, 51.693867961231234],
            [39.207598799867874, 51.69816171024188],
            [39.20628451745056, 51.698071705730975],
            [39.20607530514741, 51.69910174661073],
            [39.20912229458836, 51.702928358736735],
            [39.20891844670323, 51.70596141771794],
            [39.204240674181314, 51.70966747165965],
            [39.20133315960915, 51.70971412866596],
            [39.19820570389783, 51.71081055440942],
            [39.19638716618573, 51.71100717504611],
            [39.19580780903855, 51.71188029359521],
            [39.194498891039295, 51.712920015724436],
            [39.19273936192552, 51.71353317392249],
            [39.18050848881762, 51.713039982115546],
            [39.180669421358516, 51.71154704423802],
            [39.18073379437491, 51.711007175041495],
            [39.18099128644038, 51.709007603085915],
            [39.17929613034292, 51.70896094534715],
            [39.179274672670836, 51.70943418589075],
            [39.17877041737605, 51.709427520565505],
            [39.17877041737605, 51.7091209145381],
            [39.17806231419616, 51.709100918418336],
            [39.17800867001584, 51.70939419392354],
            [39.17669975201659, 51.70936086725786],
            [39.176635379000224, 51.70976078561831],
            [39.17326652447753, 51.70963414518805],
            [39.173223609133245, 51.70942085523928],
            [39.17280518452696, 51.70926088711266],
            [39.17198979298644, 51.70922756034824],
            [39.17200052182249, 51.708794310166674],
            [39.170391196413604, 51.70870765962757],
            [39.17042338292173, 51.707954459468446],
            [39.16951143185671, 51.707874472869456],
            [39.16948997418456, 51.70833439387049],
            [39.16593872944884, 51.70818775290161],
            [39.16599237362918, 51.707567856261896],
            [39.16475855748232, 51.70751453142001],
            [39.164672726793796, 51.708247742445884],
          ],
        ],
      },
      properties: {
        description: '22',
        fill: '#f371d1',
        'fill-opacity': 0.5,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 4,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.19163429181063, 51.70311857105184],
            [39.16921005323581, 51.70210262352052],
            [39.169425924166035, 51.69872630057814],
            [39.17082534327184, 51.6987389713633],
            [39.17083706621244, 51.698102778931684],
            [39.16878885262958, 51.69791993783172],
            [39.169346752104765, 51.6938862039767],
            [39.16866815322399, 51.69363116650824],
            [39.16878617042095, 51.69344280457451],
            [39.16996097796917, 51.693752851232254],
            [39.17008704179299, 51.693019404076814],
            [39.17442149156122, 51.69333278750365],
            [39.175279798445956, 51.693266110361165],
            [39.17881226771853, 51.69253598919191],
            [39.17965179914006, 51.69247431174708],
            [39.179712148842846, 51.69214341915102],
            [39.179938795504654, 51.692147586576766],
            [39.17996366371959, 51.69103838313496],
            [39.17980130698697, 51.68762601899234],
            [39.180533982413316, 51.68728696340411],
            [39.184035012214785, 51.686363769000664],
            [39.18442076676874, 51.683163382664276],
            [39.18741411202953, 51.683330112734005],
            [39.18738192552106, 51.6834434888272],
            [39.18801492684894, 51.68351684967714],
            [39.18821877473387, 51.68305667509662],
            [39.190064134535916, 51.68332344354127],
            [39.18986028665099, 51.68391032856001],
            [39.191179933486474, 51.68393700497008],
            [39.19131940835501, 51.68309002123765],
            [39.19082588189663, 51.68300332121706],
            [39.191469612060004, 51.68184285871895],
            [39.186858894763155, 51.68090913155846],
            [39.187305206460195, 51.67999404357028],
            [39.190438026589874, 51.68065100268549],
            [39.19059895913067, 51.68030418282308],
            [39.19735812584838, 51.68165809907286],
            [39.19759416024157, 51.68124459202142],
            [39.1987314168638, 51.681411329192365],
            [39.19846319596215, 51.68183817353785],
            [39.20139216820629, 51.682358384613956],
            [39.201145404977204, 51.682778550713785],
            [39.20167111794412, 51.682885258940466],
            [39.20188569466523, 51.68266517294603],
            [39.20451034821214, 51.68003492655995],
            [39.214049512547696, 51.68171812397335],
            [39.21237384517671, 51.6846363051934],
            [39.21102149824432, 51.68699540110548],
            [39.20909279028837, 51.690520910095756],
            [39.20844101349773, 51.69385818332823],
            [39.20682632367062, 51.69374483345001],
            [39.206756586236274, 51.692294596592355],
            [39.20560860077807, 51.692341271609536],
            [39.196813637418344, 51.6959417699619],
            [39.196451539201284, 51.698890296985525],
            [39.19455253521872, 51.69882696152296],
            [39.19430972512713, 51.69975551732416],
            [39.19494413773498, 51.69978198420232],
            [39.19488781134562, 51.700368654753944],
            [39.19580244461958, 51.7004319880476],
            [39.195748800439446, 51.70081531925147],
            [39.189413422746455, 51.70051865452006],
            [39.18936514298399, 51.70119864718922],
            [39.191752309007335, 51.70132864461881],
            [39.19163429181063, 51.70311857105184],
          ],
        ],
      },
      properties: {
        description: '21',
        fill: '#1e98ff',
        'fill-opacity': 0.5,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 5,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.16921183304975, 51.70210028830885],
            [39.1483622299141, 51.701215518310576],
            [39.14808864459455, 51.70374539989194],
            [39.14681727752153, 51.70368873743424],
            [39.146897743791925, 51.70301044894745],
            [39.14533133372728, 51.702938786420916],
            [39.14521599873971, 51.70370540286946],
            [39.142246793360215, 51.70365540654064],
            [39.14212877616356, 51.70525526145718],
            [39.140272687525254, 51.7052819252231],
            [39.14015467032857, 51.70612849160837],
            [39.1420107589669, 51.70623847741082],
            [39.14201880559396, 51.70647177973877],
            [39.14323384627766, 51.70649844278442],
            [39.14024050101712, 51.70868142609488],
            [39.141152452082196, 51.710587700484744],
            [39.14029414519754, 51.71110757950052],
            [39.134125797040724, 51.70143884690116],
            [39.12312032005719, 51.68856698155872],
            [39.124431153110194, 51.68814061775472],
            [39.124212984320096, 51.68789388338682],
            [39.12626985504868, 51.68723730098006],
            [39.129391758014336, 51.68800862370086],
            [39.12977799611232, 51.687281751588124],
            [39.128630010654184, 51.68686162749335],
            [39.12976994948543, 51.685407834513484],
            [39.132537989188855, 51.68609305700584],
            [39.13290545182364, 51.68542950837721],
            [39.13745716052217, 51.686476510297986],
            [39.140817968417814, 51.6870750243472],
            [39.14472326474335, 51.6884670805814],
            [39.14684757428323, 51.687151713595064],
            [39.14698704915199, 51.68720506256038],
            [39.146198479701624, 51.68802862918747],
            [39.1511605663791, 51.68918226399565],
            [39.1512678547398, 51.68902222393761],
            [39.15333852009926, 51.68932896688136],
            [39.15342435078789, 51.6881619988814],
            [39.15440067486912, 51.68815533040589],
            [39.15431484418083, 51.68870881050926],
            [39.1576193256871, 51.68887552004974],
            [39.15769442753945, 51.68833537889577],
            [39.159475414325456, 51.688408731779354],
            [39.159850923587534, 51.689562356850715],
            [39.16497930722405, 51.6898057479611],
            [39.16479691701113, 51.691536120809],
            [39.175472108890276, 51.69077596520192],
            [39.17527898984142, 51.69221624915649],
            [39.17582616048032, 51.69225625638663],
            [39.17589053349671, 51.691576128641245],
            [39.17995676236309, 51.69103601987865],
            [39.17993530469121, 51.69214957035199],
            [39.17970463471585, 51.692142902467424],
            [39.179650990535485, 51.6924729615896],
            [39.17881414132311, 51.69253297207831],
            [39.17527898984153, 51.69326476022543],
            [39.174420682956644, 51.69333143736978],
            [39.17008086877056, 51.693019720871575],
            [39.16995774454708, 51.69375471930732],
            [39.16878267960749, 51.6934431213655],
            [39.168664662410706, 51.69363315021411],
            [39.16934326129163, 51.693888187669494],
            [39.16878536181617, 51.69792025458617],
            [39.170834569503626, 51.6981069310402],
            [39.17082384066778, 51.698738625079685],
            [39.169431774189036, 51.698726957985926],
            [39.16921183304975, 51.70210028830885],
          ],
        ],
      },
      properties: {
        description: '17',
        fill: '#ed4543',
        'fill-opacity': 0.6,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 6,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.2016695211655, 51.682884565817744],
            [39.20113844378061, 51.682777857588405],
            [39.20139057142803, 51.68235602414755],
            [39.19846696360178, 51.68184081510135],
            [39.19874054892126, 51.681410636043864],
            [39.19759189274605, 51.68124144346202],
            [39.19735820520332, 51.6816610391303],
            [39.190594680143086, 51.68030182224582],
            [39.19043642981115, 51.68064697473056],
            [39.18730629189081, 51.679991682974745],
            [39.18685836298528, 51.68091209005898],
            [39.18997777206961, 51.681540690611776],
            [39.18964635317409, 51.682163896292714],
            [39.18791901056849, 51.68175706330819],
            [39.187779535699704, 51.681943806773674],
            [39.18686758463466, 51.681730385607054],
            [39.18673883860195, 51.68203050883479],
            [39.18641697352016, 51.681963814956205],
            [39.18656717722498, 51.681623674644555],
            [39.185483564782956, 51.681530302345],
            [39.1856337684878, 51.68063658625769],
            [39.184432138849125, 51.680476515832495],
            [39.18450724070154, 51.67904919606102],
            [39.182554592538665, 51.679022516757264],
            [39.181245674539404, 51.6772149570628],
            [39.179668535638655, 51.67656795312779],
            [39.17686830942709, 51.67599431300149],
            [39.175355543542686, 51.67590759932368],
            [39.17513023798545, 51.67688145104061],
            [39.176460613656836, 51.677581810837985],
            [39.175870527673524, 51.6781754406184],
            [39.17604218905047, 51.67896915810234],
            [39.175827612329286, 51.67950274182785],
            [39.17028080408648, 51.67628113397859],
            [39.168757309366015, 51.67736836900753],
            [39.16819940989092, 51.677375039080104],
            [39.167416204858576, 51.67838220870586],
            [39.165109505105725, 51.67931598824482],
            [39.16306029741835, 51.67979621018607],
            [39.16326414530347, 51.68014970362005],
            [39.1630710262544, 51.68058322951754],
            [39.1628886360414, 51.68064992543915],
            [39.162792076516844, 51.68042982852305],
            [39.16196595614027, 51.68035646264539],
            [39.16191231195993, 51.68061657749169],
            [39.16413318102426, 51.68141692144142],
            [39.161751379419016, 51.683784522372285],
            [39.16138659899294, 51.68456480302012],
            [39.16528116648257, 51.684471436817056],
            [39.16522752230225, 51.685391752370286],
            [39.16377912943421, 51.68532506346939],
            [39.16346531097946, 51.687138966392794],
            [39.15397297327578, 51.686742181372246],
            [39.154107083726515, 51.68575353794115],
            [39.15143023912966, 51.68555180533248],
            [39.14554010813291, 51.68691890118331],
            [39.14493124668653, 51.68688222354469],
            [39.141452421594224, 51.68495160380456],
            [39.14087306444699, 51.684231351411114],
            [39.13854490702206, 51.68307092055248],
            [39.13645278399044, 51.68294420502746],
            [39.13386713450009, 51.68086335173367],
            [39.13607995693735, 51.68013636430638],
            [39.135763456273544, 51.679784538195605],
            [39.136216749597104, 51.67966948545791],
            [39.135508646417136, 51.678742383118184],
            [39.13595925753168, 51.678602315636965],
            [39.13561593477777, 51.67823547017254],
            [39.116175283837734, 51.684731527912305],
            [39.116620530534206, 51.68360445570048],
            [39.11662857716127, 51.68273412324681],
            [39.11414753382249, 51.68006299795384],
            [39.11222707216781, 51.678869110468206],
            [39.111998493232555, 51.67820593473398],
            [39.112615401305945, 51.677155405508984],
            [39.118803257503316, 51.672614502992445],
            [39.120055849113264, 51.67303142461468],
            [39.120544011153974, 51.672501099642666],
            [39.11982786134699, 51.67218423583109],
            [39.12293385938624, 51.67003617398086],
            [39.12406843379953, 51.67054651471872],
            [39.124921376266286, 51.6701028855178],
            [39.1240872092626, 51.66960087878547],
            [39.12733268217065, 51.66718082767882],
            [39.12785571292856, 51.66711744761277],
            [39.1294462628744, 51.665399480294745],
            [39.13414817527747, 51.66712578710224],
            [39.13750361875509, 51.66769787225876],
            [39.144662434615796, 51.66816153944946],
            [39.14519082979183, 51.66743267916992],
            [39.150694722690346, 51.66675718028295],
            [39.15095757917384, 51.667330938008135],
            [39.157145435371184, 51.666004946154466],
            [39.159669394054205, 51.66573640720478],
            [39.16258308828303, 51.66583654640287],
            [39.16378903173321, 51.66597889195653],
            [39.16738960683771, 51.666843973955],
            [39.169257139789956, 51.667683407187496],
            [39.17973723961692, 51.674187408115614],
            [39.18507483555654, 51.67658373732268],
            [39.20449939324229, 51.680033760602214],
            [39.2016695211655, 51.682884565817744],
          ],
        ],
      },
      properties: {
        description: '16',
        fill: '#ffd21e',
        'fill-opacity': 0.6,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 7,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.22601630656249, 51.67645212139348],
            [39.22568103043493, 51.676510485695104],
            [39.22549595801304, 51.676327054818756],
            [39.22493805853833, 51.67639375704164],
            [39.22502120701763, 51.67662221140734],
            [39.22457059590306, 51.67668057547498],
            [39.22503730027138, 51.67778447589403],
            [39.22438015906284, 51.677806153427824],
            [39.22432115046478, 51.67738093839676],
            [39.221282207650766, 51.677302564989546],
            [39.2210569020933, 51.67685066461471],
            [39.22061970202412, 51.67682231647001],
            [39.220600926561055, 51.67699574013597],
            [39.219493174237584, 51.67725754190846],
            [39.21878238884843, 51.67707744913181],
            [39.21691557137496, 51.67753434977025],
            [39.215818547887494, 51.67753101474574],
            [39.215275515579386, 51.67839425026154],
            [39.21530892817471, 51.67874661477405],
            [39.21562006441996, 51.67912179334272],
            [39.21611359087877, 51.679250187068035],
            [39.215995573682775, 51.68035735934384],
            [39.21653201548551, 51.68038904007651],
            [39.21667953698111, 51.680622476376506],
            [39.21579172579683, 51.68125274834394],
            [39.21669831244423, 51.68180131121171],
            [39.21673318116158, 51.682133113473256],
            [39.21737422911637, 51.68251159849597],
            [39.21689947812035, 51.68274335647572],
            [39.21415289608925, 51.681742953775355],
            [39.18508043257647, 51.67658219028355],
            [39.18545594183931, 51.673775620661516],
            [39.20511385170903, 51.65756980788182],
            [39.204609596414315, 51.65719611811965],
            [39.20420190064388, 51.65661555823764],
            [39.20488854615159, 51.65499395482828],
            [39.207077228708314, 51.655748041033796],
            [39.207731687707486, 51.65451346854397],
            [39.20924445359184, 51.65361921654852],
            [39.2101027604766, 51.653272188609634],
            [39.20976748435005, 51.65287844213956],
            [39.21193202702508, 51.65273495739755],
            [39.21214660374624, 51.65497727135918],
            [39.21258648602491, 51.65605167395008],
            [39.215633475465914, 51.655758050939625],
            [39.21949585644683, 51.661416630453296],
            [39.21889504162726, 51.66141663045223],
            [39.225769543332646, 51.675004662853944],
            [39.22461082903856, 51.67518142871667],
            [39.2239751455021, 51.67513640351646],
            [39.22406634060858, 51.67564168374737],
            [39.22472079960828, 51.676016888174516],
            [39.224873685522184, 51.67620198787344],
            [39.22541280953389, 51.676131950239096],
            [39.22540744511566, 51.675786763162584],
            [39.22586073843881, 51.675756746771086],
            [39.22601630656249, 51.67645212139348],
          ],
        ],
      },
      properties: {
        description: '24',
        fill: '#82cdff',
        'fill-opacity': 0.6,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 8,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.185079091472126, 51.67658146837746],
            [39.17973881332352, 51.67418513905139],
            [39.169248693866514, 51.6676808889454],
            [39.176337772293, 51.665430866986036],
            [39.17386477558115, 51.664423407849334],
            [39.175232702178214, 51.662853795020254],
            [39.175484829825784, 51.66228164837783],
            [39.17545264331796, 51.66170115398312],
            [39.1749698456952, 51.6611206521143],
            [39.17419200508084, 51.660857088454556],
            [39.17316203681936, 51.662111504246845],
            [39.17336229067338, 51.662194499554616],
            [39.17265529525053, 51.663193463920884],
            [39.17156085172948, 51.662778851175936],
            [39.17837088672625, 51.65527851889445],
            [39.176638179702145, 51.6546445468606],
            [39.17881076900401, 51.65211190648238],
            [39.17373602954811, 51.64937722926644],
            [39.17416518299017, 51.648973437367744],
            [39.17505567638367, 51.64925208753236],
            [39.17568867771127, 51.64852292147985],
            [39.17504762975712, 51.648299330444445],
            [39.17533730833044, 51.64799898253356],
            [39.1777727541158, 51.64903016867917],
            [39.17838161556218, 51.64875652287351],
            [39.17917823163981, 51.648789894404416],
            [39.17951350776638, 51.64882326590757],
            [39.17976295320454, 51.64872648847648],
            [39.17942231265988, 51.648389434430385],
            [39.18058907358144, 51.64814248233865],
            [39.181012862605705, 51.648205889085844],
            [39.18131058780637, 51.64839610879186],
            [39.181924813670804, 51.64906354000487],
            [39.18224131433461, 51.649247081858256],
            [39.18250953523578, 51.64966755673906],
            [39.182989650649574, 51.65042006350975],
            [39.18364947406738, 51.65118256864916],
            [39.184043758792065, 51.65153962351355],
            [39.18433880178411, 51.65188165940279],
            [39.18469553558312, 51.6518149208957],
            [39.18586497871366, 51.65271755082602],
            [39.186747425479105, 51.65275425628396],
            [39.18672865001665, 51.65328481362938],
            [39.18712829915943, 51.65328815044869],
            [39.18837016193341, 51.652460611799164],
            [39.18877249328552, 51.65271087710476],
            [39.18747162191358, 51.65369857728421],
            [39.18753063051149, 51.65422578676943],
            [39.18708195386134, 51.654814727467816],
            [39.186420195979366, 51.655518758616005],
            [39.18722485868457, 51.65583407128641],
            [39.18815826742119, 51.65499823763426],
            [39.19013237325595, 51.65597420954358],
            [39.193267875594564, 51.65376030783435],
            [39.19409936038917, 51.65470127105444],
            [39.19277971355401, 51.65523180549041],
            [39.19202869502968, 51.65483557360169],
            [39.1862807211106, 51.659203942015154],
            [39.18707465497938, 51.660158136875054],
            [39.18614661065994, 51.66041836833277],
            [39.18812608091285, 51.662750375491775],
            [39.19155394403419, 51.66158939130091],
            [39.19094240037844, 51.66091213671452],
            [39.19229155151269, 51.66043504978955],
            [39.191714876575006, 51.65983784994936],
            [39.19098531572311, 51.66009641509787],
            [39.19035767881316, 51.65944582899302],
            [39.191639774722866, 51.659007095115136],
            [39.19220840303356, 51.65930069696554],
            [39.19306670991847, 51.658534992675456],
            [39.19412886468839, 51.65805287589328],
            [39.19427102176611, 51.658493287277224],
            [39.19522320596666, 51.65884861605111],
            [39.19594740240022, 51.65821803066748],
            [39.19513469306912, 51.65773090576116],
            [39.19545387594172, 51.65704692012754],
            [39.19566040603591, 51.65725878995611],
            [39.19632559387157, 51.657051924936376],
            [39.195936673564375, 51.65664820176152],
            [39.19658040372782, 51.65648971440364],
            [39.19600909320761, 51.65606262990684],
            [39.196465068739975, 51.65575899706083],
            [39.196773522777306, 51.655910813738465],
            [39.19767206279688, 51.65532856897325],
            [39.19730996457967, 51.65504661966648],
            [39.19764255849817, 51.654879784927246],
            [39.197393113059455, 51.65470293942853],
            [39.197111481112564, 51.65485142295897],
            [39.19676011173163, 51.65458281756012],
            [39.19711952773981, 51.65440597089679],
            [39.19773375360431, 51.654831402735],
            [39.19800733892384, 51.654706276140615],
            [39.19869666664089, 51.65523180550517],
            [39.20056616632416, 51.653957177667984],
            [39.200791471881395, 51.65407563281995],
            [39.20095508663092, 51.65396051443683],
            [39.20073514549217, 51.65385206931509],
            [39.20131182042998, 51.653151340692936],
            [39.20118307439737, 51.65267083477421],
            [39.20228278009345, 51.65237385286122],
            [39.20262610284744, 51.652510664996576],
            [39.20631682245156, 51.65159968856909],
            [39.20766060916794, 51.651608030927434],
            [39.207724982184736, 51.65184161633988],
            [39.208221190852385, 51.65176653544648],
            [39.20814340679075, 51.6515696560677],
            [39.208537691516014, 51.65149290624599],
            [39.20855378476994, 51.650962327819094],
            [39.209176057261516, 51.65092895789682],
            [39.209267252368036, 51.65120259052048],
            [39.2117026981536, 51.65089558795111],
            [39.21192800371063, 51.652734235159826],
            [39.209098273200254, 51.652919430498514],
            [39.2084786829177, 51.6531530091159],
            [39.209020489138744, 51.65374696072373],
            [39.20772766439309, 51.65451107797027],
            [39.207073205394174, 51.6557439822075],
            [39.20488184062861, 51.65499156427996],
            [39.20419251291186, 51.656613167775774],
            [39.204606914204334, 51.65719873249246],
            [39.20510714618526, 51.657569085740356],
            [39.18544923631638, 51.673778234023914],
            [39.185079091472126, 51.67658146837746],
          ],
        ],
      },
      properties: {
        description: '15',
        fill: '#56db40',
        'fill-opacity': 0.6,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 9,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.1881287631197, 51.66275173811333],
            [39.18614521196985, 51.660423383923515],
            [39.187073256289715, 51.660166488804705],
            [39.18627932242043, 51.65921563050239],
            [39.19203278125527, 51.65484226226611],
            [39.19277758490817, 51.65523515605471],
            [39.19410064390786, 51.65470461892464],
            [39.19326915911369, 51.65376031898963],
            [39.19013902119343, 51.65597755732443],
            [39.18815501401069, 51.6549991219351],
            [39.18722160527379, 51.65583662388922],
            [39.18643035361403, 51.655519642905695],
            [39.18707517425164, 51.65482935062632],
            [39.18752737710137, 51.654231676209136],
            [39.187468368503154, 51.65370113000775],
            [39.18877728650234, 51.652716766744106],
            [39.1883642263142, 51.6524631645931],
            [39.18711968133078, 51.65329070319247],
            [39.18672954469474, 51.653287181705494],
            [39.186747103828765, 51.65275643971693],
            [39.185863891010875, 51.65271853860673],
            [39.18469303391389, 51.65181920577295],
            [39.18433630011559, 51.651882607352704],
            [39.184043584728016, 51.65153718594701],
            [39.18364894539805, 51.65118508794604],
            [39.18299060674612, 51.65041604370174],
            [39.182236732140424, 51.649242684640264],
            [39.18191486705859, 51.64905914277031],
            [39.181523264541994, 51.64862531356267],
            [39.18130868782135, 51.648398385856474],
            [39.18101632703817, 51.64821150335524],
            [39.18058985580527, 51.648141422217805],
            [39.17941879969653, 51.6483907355647],
            [39.179762122450114, 51.64872778960105],
            [39.179515359220545, 51.6488245670259],
            [39.17917471867577, 51.64879119552125],
            [39.178380784807224, 51.64875782399373],
            [39.17776924115196, 51.64903146979354],
            [39.1753445242025, 51.64800028367452],
            [39.175049481211026, 51.64830063157898],
            [39.175687846956244, 51.64852422260922],
            [39.175052163420034, 51.64925672575896],
            [39.174164352235906, 51.64897473848191],
            [39.173737881002985, 51.6493785303667],
            [39.172528204736786, 51.6487311267538],
            [39.17169671994241, 51.64843745607215],
            [39.16953485947681, 51.64778670172566],
            [39.165275195561165, 51.649613067600015],
            [39.16356394620954, 51.65029716600812],
            [39.156203084602325, 51.64772007038665],
            [39.1557417446517, 51.64662210809201],
            [39.15375690998107, 51.6455174442828],
            [39.14876263679562, 51.64477653678107],
            [39.14161705235612, 51.63799543632523],
            [39.1411986277498, 51.63632309222056],
            [39.14834939698342, 51.633175178603],
            [39.15491866926042, 51.63625719493621],
            [39.15650624913431, 51.63810376883931],
            [39.15578984479125, 51.63836594727764],
            [39.15633165101166, 51.638849943500965],
            [39.156774215499475, 51.63927719102762],
            [39.15783100585113, 51.63883325406327],
            [39.15850692252317, 51.63947412406909],
            [39.15884219864936, 51.63935896840997],
            [39.15973000983372, 51.640288549897384],
            [39.16043543080427, 51.64118640395545],
            [39.161663882533865, 51.64445390783083],
            [39.16364871720465, 51.64536502860816],
            [39.162492685119, 51.64617767941615],
            [39.162868194381915, 51.64635455834991],
            [39.16359775523297, 51.64637291367195],
            [39.16233467687226, 51.64789008158886],
            [39.16439525755583, 51.64861199788541],
            [39.16563379396094, 51.64833559801551],
            [39.166797872673, 51.647768272956725],
            [39.16907506812681, 51.64735946076956],
            [39.16933256019195, 51.647666487410554],
            [39.17061733831049, 51.64712752077662],
            [39.17128252614678, 51.647541340932186],
            [39.17211937535839, 51.64683383965248],
            [39.1725377999645, 51.647010716013035],
            [39.17211937535839, 51.64744122350058],
            [39.171676810870856, 51.64762477195721],
            [39.17142468322369, 51.64794848286335],
            [39.17201745141579, 51.64820377900296],
            [39.17228567231764, 51.64792845957648],
            [39.172846254001016, 51.64812869205321],
            [39.17325126756252, 51.64784169189145],
            [39.174796219955574, 51.64769819112509],
            [39.17550432313549, 51.64713085804876],
            [39.174506541381916, 51.64657019246624],
            [39.17483377088186, 51.64632990508368],
            [39.175230737815866, 51.64656018051877],
            [39.17552175749406, 51.64639373154736],
            [39.17559820045103, 51.646447545943694],
            [39.17724239457678, 51.6454421655974],
            [39.17698490251177, 51.64517517288758],
            [39.17719411481449, 51.645065037921064],
            [39.17714583505238, 51.64493154063357],
            [39.17667376626547, 51.644838092297526],
            [39.176689859519804, 51.644637845211236],
            [39.176480647216195, 51.6444609595455],
            [39.17512344945559, 51.64508839990535],
            [39.174624558578344, 51.644694581977184],
            [39.17397546399725, 51.6449231970411],
            [39.173401471267944, 51.64433580417459],
            [39.17390840877131, 51.64414222984986],
            [39.17370187867785, 51.643908604559535],
            [39.17608904470026, 51.643055861974155],
            [39.17678641904451, 51.643778441370024],
            [39.17795317996607, 51.64335290525195],
            [39.17809801925286, 51.643491413527414],
            [39.178717609535795, 51.64323108436838],
            [39.17964028943679, 51.64408048613719],
            [39.18271410096793, 51.64280554308524],
            [39.17959200967456, 51.63960133864481],
            [39.18052541841135, 51.63921081066836],
            [39.179473992477746, 51.638192751763775],
            [39.17667913068386, 51.639264216404136],
            [39.175681348930716, 51.63815770342607],
            [39.177070733200445, 51.63760026428603],
            [39.17778688300753, 51.638299565581036],
            [39.18002116311631, 51.63742835801003],
            [39.18222593892686, 51.639801608085264],
            [39.1837065183032, 51.63928424353405],
            [39.182140108238876, 51.63715797982327],
            [39.18288039792642, 51.636937670475554],
            [39.1848448276187, 51.63947453561783],
            [39.18525788780762, 51.63948454914031],
            [39.18546173569232, 51.63890209227448],
            [39.18718907829799, 51.63912906776199],
            [39.18628785606909, 51.6414321368136],
            [39.185713863339586, 51.64169080751651],
            [39.18564144369628, 51.64186770404517],
            [39.186054503885046, 51.641951145563475],
            [39.184322358336935, 51.64625283865273],
            [39.18565541621698, 51.64610766415653],
            [39.185717107024004, 51.64638800069493],
            [39.18517798301171, 51.64644473525938],
            [39.18556422111058, 51.6473674962211],
            [39.18689996119997, 51.64714223081849],
            [39.18718695756448, 51.647542701866506],
            [39.18637424823361, 51.64830024987044],
            [39.1856098186636, 51.64838201089962],
            [39.185768068995685, 51.648850882503744],
            [39.18645471450329, 51.64871572784434],
            [39.18653518077379, 51.648800825268125],
            [39.186344743933724, 51.64894432252689],
            [39.186591507163016, 51.649057785149616],
            [39.186765850749055, 51.64894932823568],
            [39.187034071650274, 51.64908615076134],
            [39.18731302138866, 51.648889259678946],
            [39.188605846133505, 51.649633436711355],
            [39.190223218168896, 51.64851883514268],
            [39.189469517436116, 51.6480766577268],
            [39.19020176049697, 51.64606427858031],
            [39.19011324759961, 51.64592244084158],
            [39.19026881572291, 51.645773927906816],
            [39.18964654323142, 51.64561873971262],
            [39.18876677867412, 51.646281206022344],
            [39.18847441789178, 51.646159393049146],
            [39.188015760150044, 51.64695033717743],
            [39.18717891093726, 51.64673841890802],
            [39.188155235019266, 51.64554364845734],
            [39.18832153197826, 51.64530502431153],
            [39.190115929808904, 51.64560372146958],
            [39.19058531638611, 51.64475935455692],
            [39.191754759516606, 51.64361459472985],
            [39.191266597475696, 51.643334240955845],
            [39.19169306870943, 51.6430789172539],
            [39.193208516802876, 51.640013251183895],
            [39.19112712260716, 51.6397095102682],
            [39.192856841967505, 51.63548060843815],
            [39.19384925930381, 51.63568757241802],
            [39.194482260631204, 51.63571260832122],
            [39.19504552452426, 51.63570092490269],
            [39.19508843986794, 51.63598633326437],
            [39.19578581421223, 51.6359679737151],
            [39.195753627703745, 51.63563416245095],
            [39.195431762622086, 51.635645845886735],
            [39.19538884727767, 51.635383802381114],
            [39.195010655806804, 51.63539715495315],
            [39.19504820673254, 51.635642507763684],
            [39.19471293060596, 51.63565419119616],
            [39.19469683735152, 51.63550063725309],
            [39.19438033668835, 51.63549062284722],
            [39.19434815018006, 51.635130102759476],
            [39.1944339808677, 51.635130102759476],
            [39.194393747733514, 51.63427886334498],
            [39.194777303622686, 51.63428553979297],
            [39.19529765217137, 51.63440571572464],
            [39.19679432480181, 51.63438234820688],
            [39.196770184920865, 51.63407856938209],
            [39.198492163108, 51.6340101354084],
            [39.19856994716938, 51.63436231889646],
            [39.204029310203474, 51.634771783321234],
            [39.20429753110508, 51.634624902774505],
            [39.204501378989846, 51.634828532494595],
            [39.204903710342315, 51.63468165213328],
            [39.20468913362108, 51.63421597146321],
            [39.206057060218804, 51.634528094881496],
            [39.20692609593943, 51.63524914179432],
            [39.20849787042198, 51.63724197541555],
            [39.21045800021318, 51.639562440892306],
            [39.21066721251644, 51.640466984938996],
            [39.21088178923788, 51.64443207839836],
            [39.21069864828922, 51.6445304755247],
            [39.20952191291005, 51.647193099199455],
            [39.20910014735101, 51.64742749122127],
            [39.20843917534468, 51.64962778911912],
            [39.20856792137696, 51.650301876184706],
            [39.2085303704509, 51.65149652058905],
            [39.20813876793485, 51.65156659651189],
            [39.20821923420565, 51.65176514437219],
            [39.20772838995629, 51.651838556804485],
            [39.207661334729885, 51.651606639851046],
            [39.20630681917784, 51.65159996595983],
            [39.202618781781915, 51.65251427925959],
            [39.202286187865276, 51.65237413024754],
            [39.201178435541195, 51.6526694437274],
            [39.201312545991776, 51.65315161807851],
            [39.200741235472094, 51.65385568346834],
            [39.200953129983986, 51.65396412858305],
            [39.200794879652, 51.65407591019723],
            [39.200564209677296, 51.65395411827568],
            [39.19869202778499, 51.655230414534785],
            [39.198010746694344, 51.65470655351176],
            [39.19773179695738, 51.65483001175539],
            [39.19712293551072, 51.654404579905226],
            [39.19675547287637, 51.65458309493322],
            [39.197109524466164, 51.6548550370326],
            [39.197393838622226, 51.654704885155056],
            [39.197637919641735, 51.65487672559672],
            [39.197316054559565, 51.655050233724424],
            [39.197667423940956, 51.6553271780059],
            [39.196760837294036, 51.655907754477],
            [39.196465794302355, 51.6557576061067],
            [39.19600445435148, 51.656061238962934],
            [39.19657576487226, 51.65648999176389],
            [39.19593203470822, 51.65664681083784],
            [39.19632095501506, 51.65704886575824],
            [39.19566113159784, 51.65726073557813],
            [39.19545191929397, 51.657042192675135],
            [39.1951273720033, 51.65773118311637],
            [39.195942763544046, 51.658218308019734],
            [39.19522393152841, 51.658845556992496],
            [39.19426906511867, 51.658491896409345],
            [39.194131546895335, 51.658049233954834],
            [39.1930657243088, 51.6585348482628],
            [39.19220840303163, 51.65929705513119],
            [39.191634410302576, 51.65900178505599],
            [39.19035499660231, 51.65945219625731],
            [39.19098263351158, 51.660101114111626],
            [39.1917121943634, 51.65984421715616],
            [39.19229155151037, 51.66043641248084],
            [39.190942400376606, 51.660915167518894],
            [39.19155394403195, 51.661592422060906],
            [39.1881287631197, 51.66275173811333],
          ],
        ],
      },
      properties: {
        description: '14',
        fill: '#ed4543',
        'fill-opacity': 0.6,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 10,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.1869015432758, 51.64713022833924],
            [39.18556825901729, 51.647359504798],
            [39.185179338710704, 51.64644341832248],
            [39.185718462722356, 51.64638501509174],
            [39.18565945412427, 51.64610134118507],
            [39.18432371403473, 51.646246515705315],
            [39.18606178547625, 51.64194614835014],
            [39.18564067866105, 51.64186270682495],
            [39.18571578051345, 51.641683307018454],
            [39.18628709103366, 51.641432146089656],
            [39.187193677680504, 51.6391224013019],
            [39.185463652866105, 51.63889876366324],
            [39.18525444056323, 51.639476213809104],
            [39.18484942700177, 51.639469538125965],
            [39.182886050002864, 51.636934376473086],
            [39.182137713687496, 51.63715802384903],
            [39.183701441543384, 51.6392792807747],
            [39.18222890879412, 51.63979330756614],
            [39.180021450774966, 51.637425064040066],
            [39.17778985287461, 51.6382946027078],
            [39.17707102085859, 51.63759363234553],
            [39.17567627217104, 51.638154409505475],
            [39.1766740539243, 51.639262591493114],
            [39.17947428013599, 51.6381911268157],
            [39.180517920823064, 51.639205668930416],
            [39.17958644444399, 51.63960032808337],
            [39.18270585352797, 51.64280286379834],
            [39.17964277083332, 51.6440744694227],
            [39.178719172579115, 51.643221668048284],
            [39.17810494671471, 51.64348533480372],
            [39.17795206080108, 51.64335016406526],
            [39.17679066429748, 51.64377069392475],
            [39.176087925535235, 51.6430497831936],
            [39.173695395094114, 51.6439025258888],
            [39.173907289606234, 51.644136151212656],
            [39.17339498768448, 51.64433306304386],
            [39.173976875488215, 51.644921523058066],
            [39.17462597006979, 51.64469457671368],
            [39.175124860946674, 51.645086725930526],
            [39.176476694290294, 51.64445928554717],
            [39.17669127101145, 51.64463450249102],
            [39.17667468335648, 51.6448321781756],
            [39.17714675214291, 51.644932301399834],
            [39.177192349696384, 51.64505912382974],
            [39.17697777297523, 51.64517259622809],
            [39.17723526504047, 51.64544125767905],
            [39.175601799750645, 51.646440797724715],
            [39.17552452483945, 51.64638814099836],
            [39.17523535541609, 51.646549073030855],
            [39.17483034185451, 51.64632547220872],
            [39.17450579456399, 51.64656742827293],
            [39.175495529690366, 51.64712642525116],
            [39.17479010871949, 51.64769542700025],
            [39.17324782593908, 51.6478341720396],
            [39.172845494586895, 51.648121172247635],
            [39.17228223069405, 51.64792093973897],
            [39.17201669200182, 51.648196259211105],
            [39.17143197043633, 51.647944300242315],
            [39.1716840980838, 51.647613914827204],
            [39.17212129815308, 51.64743704083231],
            [39.17254240496853, 51.64700653330481],
            [39.17211593373477, 51.64682798827995],
            [39.1712844489403, 51.647532152405326],
            [39.17061657889543, 51.64712166944049],
            [39.16933984740479, 51.64766230476038],
            [39.16907967313005, 51.64735527808972],
            [39.166797113258596, 51.64776242170105],
            [39.16563035233679, 51.648329746833475],
            [39.16439117177208, 51.648606732394704],
            [39.16233928187561, 51.64788589895411],
            [39.16360528453075, 51.64636743896965],
            [39.16287035926078, 51.64634908364529],
            [39.16249753220759, 51.64617387336263],
            [39.163650882084205, 51.645362891189826],
            [39.16166336520388, 51.6444501016302],
            [39.16044027789294, 51.64118092861777],
            [39.15974548285475, 51.64029674953167],
            [39.15884168132041, 51.63935349284757],
            [39.15851424990003, 51.639466329107854],
            [39.1578304885218, 51.6388277784361],
            [39.156773698169964, 51.63927505330727],
            [39.15579469187961, 51.63836714743912],
            [39.156513523895306, 51.63810345076683],
            [39.1549149273227, 51.63625419282386],
            [39.14835424407212, 51.63317470974713],
            [39.15402175172049, 51.632380185693414],
            [39.15690780862067, 51.63296606507105],
            [39.15772588237003, 51.633234799222755],
            [39.165348720390426, 51.63061582490233],
            [39.16512609704238, 51.630340398120325],
            [39.16566253884548, 51.6301484330055],
            [39.16388423426849, 51.628429056869415],
            [39.17273552401769, 51.624582758931254],
            [39.172274184067085, 51.62221204746227],
            [39.172520947296654, 51.619707639557014],
            [39.176270675499325, 51.620078300686565],
            [39.1769358633352, 51.618602316680125],
            [39.180175971825086, 51.618816036806884],
            [39.18006727470562, 51.61912180392133],
            [39.1912198997897, 51.62329245503804],
            [39.19356951488679, 51.622444331417576],
            [39.19891247524449, 51.62599031711538],
            [39.20115480198097, 51.6278066086786],
            [39.202721212045596, 51.62960947286966],
            [39.20602301134285, 51.63275596606862],
            [39.20657554640008, 51.6342248108533],
            [39.206060562269066, 51.63452525048861],
            [39.204684589044376, 51.634211457936324],
            [39.204899165765816, 51.634683815045555],
            [39.20450756324948, 51.634824019029814],
            [39.20430103315536, 51.63462038928887],
            [39.20403013004483, 51.63477227713567],
            [39.19857719911771, 51.63435833982652],
            [39.19849136842939, 51.63400782542783],
            [39.19676939024162, 51.634076259406136],
            [39.19679084791375, 51.634383376465046],
            [39.19530222191056, 51.634405074872696],
            [39.19477382673463, 51.634283229826494],
            [39.19439295305427, 51.63427321515061],
            [39.19443050398084, 51.635129461914836],
            [39.194344673292086, 51.635127792834645],
            [39.19438490642744, 51.6354849748082],
            [39.194696042673044, 51.63550166548207],
            [39.19471213592712, 51.63565521942079],
            [39.19504741205402, 51.63564520505045],
            [39.19501790775511, 51.63539818318338],
            [39.19539341701683, 51.63538316153962],
            [39.195433650152005, 51.635646874112574],
            [39.19575551523405, 51.63563519067569],
            [39.195782337324154, 51.63596566383559],
            [39.1950876451892, 51.63598402338426],
            [39.19505009426304, 51.635698615004806],
            [39.194478783742795, 51.63571029842298],
            [39.19385114683335, 51.63568526252183],
            [39.192853365079806, 51.635476629463945],
            [39.19112334026533, 51.63970751929872],
            [39.19320473446084, 51.640011260228384],
            [39.191697332994636, 51.64307525764062],
            [39.19126817955209, 51.64333225014006],
            [39.19175097717485, 51.64361594146229],
            [39.19058421625301, 51.64475903253323],
            [39.19012019409367, 51.64560173075191],
            [39.18832847847177, 51.64529969617263],
            [39.18716976417725, 51.646736428238206],
            [39.18801734222603, 51.646950015161],
            [39.18847599996774, 51.64615907102667],
            [39.188768360750366, 51.6462825526691],
            [39.189648125307045, 51.64562175507274],
            [39.19026503338067, 51.645771937195796],
            [39.19011482967573, 51.64592045013566],
            [39.19019797815526, 51.6460589505269],
            [39.189471099512176, 51.64807800431995],
            [39.19021675361843, 51.64851183879353],
            [39.18860474600038, 51.64962310344322],
            [39.187314603464166, 51.64888059481273],
            [39.18703028930844, 51.6490791544967],
            [39.18677279724332, 51.648945669090374],
            [39.18658772482113, 51.649055794580356],
            [39.18634364380065, 51.64893899480988],
            [39.186531398431775, 51.648807177564876],
            [39.18645361437032, 51.64871040010089],
            [39.18576696886274, 51.64885222907197],
            [39.18561408294865, 51.64837835170573],
            [39.18637583030878, 51.64829992785985],
            [39.187191221849254, 51.64753737398196],
            [39.1869015432758, 51.64713022833924],
          ],
        ],
      },
      properties: {
        description: '13',
        fill: '#f371d1',
        'fill-opacity': 0.6,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 11,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.12005541746039, 51.67303025019167],
            [39.118807160793324, 51.67261376215451],
            [39.122401320873195, 51.66980527753989],
            [39.12113531821808, 51.668754552438294],
            [39.122680270610395, 51.66360731334774],
            [39.12798939172609, 51.65788518205822],
            [39.126060883444175, 51.65762326772979],
            [39.12605551902635, 51.657348005808345],
            [39.126422981661314, 51.657319645393606],
            [39.1263800663169, 51.65690591496288],
            [39.130408744257394, 51.65655223916574],
            [39.13107929651141, 51.65602505688401],
            [39.132916609686085, 51.65606676456759],
            [39.133058766764066, 51.65472542613377],
            [39.1349148554025, 51.65479549703978],
            [39.13536726054812, 51.6542540883068],
            [39.135601500910234, 51.65191248983774],
            [39.1373395723518, 51.652072661740135],
            [39.142038802545876, 51.65163218763847],
            [39.1432297033485, 51.651772338955446],
            [39.148679952066935, 51.65390125063318],
            [39.152617434900826, 51.64997036024834],
            [39.15620086614472, 51.64772112283902],
            [39.16355011884557, 51.65029739135915],
            [39.165251552744735, 51.64961774674156],
            [39.169536809366846, 51.64777785572435],
            [39.17170939866863, 51.648438621732765],
            [39.172508696955354, 51.64871560662323],
            [39.17881188814034, 51.65211270461951],
            [39.176639298838126, 51.654642008233985],
            [39.178361277025616, 51.65527598030846],
            [39.17155919496405, 51.66277953041834],
            [39.172664265078296, 51.66318653477694],
            [39.17337606632626, 51.662193230428464],
            [39.17315964057079, 51.66211606853328],
            [39.17419848863428, 51.66086122270091],
            [39.17496560041279, 51.66111978197794],
            [39.17545108024461, 51.66171196054493],
            [39.17549131337976, 51.66228911864668],
            [39.1752257746872, 51.66284792075161],
            [39.17386053029882, 51.664419201782124],
            [39.17633084480168, 51.66543333285405],
            [39.169249813002104, 51.667680018939464],
            [39.167380313319036, 51.666839401174414],
            [39.16379688207499, 51.66597875246146],
            [39.16258452360034, 51.66583864530041],
            [39.15967700902843, 51.665738568490916],
            [39.157134274881955, 51.66600543948921],
            [39.150954465311756, 51.66733309921769],
            [39.15069965545517, 51.6667560056993],
            [39.145182351511714, 51.66743317248554],
            [39.144656638544944, 51.66816036489907],
            [39.13752196256534, 51.667700033448824],
            [39.1341531080426, 51.6671262804225],
            [39.129443149012516, 51.665398305672774],
            [39.12889597837306, 51.666012111238665],
            [39.12784723464857, 51.66711794093421],
            [39.127318839472714, 51.66718632468392],
            [39.12408945981871, 51.66960137207818],
            [39.12491558019506, 51.67010171101785],
            [39.12406800214671, 51.67054534023123],
            [39.1229307455244, 51.67003499947911],
            [39.11982206527602, 51.672183061385965],
            [39.12053821508267, 51.67250326060151],
            [39.12005541746039, 51.67303025019167],
          ],
        ],
      },
      properties: {
        description: '11',
        fill: '#82cdff',
        'fill-opacity': 0.6,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 12,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.11683752095556, 51.65786537714168],
            [39.114736766967695, 51.65990704028993],
            [39.10360023513754, 51.65830557395569],
            [39.10289213195821, 51.655576277327825],
            [39.10061761871313, 51.655729762940396],
            [39.10010920165692, 51.653250037628396],
            [39.09855609740675, 51.6457943947012],
            [39.097172931102904, 51.64406087963756],
            [39.0903109868953, 51.63836145205871],
            [39.080274160761164, 51.64123531242278],
            [39.07577877845273, 51.63568769633099],
            [39.07239919509388, 51.63383166285937],
            [39.06890695895656, 51.630897238354585],
            [39.0499276479665, 51.61863001266483],
            [39.04165294655552, 51.6098824662151],
            [39.034743576132996, 51.607664631939606],
            [39.028864173972195, 51.60205275763113],
            [39.02955081947988, 51.60197258294708],
            [39.02774837502268, 51.58954378650923],
            [39.02925041207058, 51.58387618980189],
            [39.03251197823277, 51.57906352151989],
            [39.03564479836232, 51.57745918490374],
            [39.03697517403358, 51.57398292693734],
            [39.03826263436045, 51.57197727192368],
            [39.04083755501536, 51.57061337570379],
            [39.043755798422936, 51.56625398058879],
            [39.05354049690945, 51.55903197217517],
            [39.065084724509255, 51.552103110242335],
            [39.07371070870132, 51.54859814669433],
            [39.081735878073694, 51.547527874720245],
            [39.08564117439963, 51.54875868530786],
            [39.08757236489003, 51.54849112063594],
            [39.089546470725395, 51.546457577437394],
            [39.090233116232774, 51.54410283433003],
            [39.09267929085515, 51.54399579764311],
            [39.09847286232687, 51.54803625706459],
            [39.11775597641956, 51.54870852083494],
            [39.11929386654697, 51.548211850215864],
            [39.12094257616758, 51.54637145180099],
            [39.12429893628796, 51.54784603290723],
            [39.12561233592208, 51.548944516643566],
            [39.12559535988487, 51.54958812574069],
            [39.137869148337536, 51.55150113200226],
            [39.1434910584323, 51.553788606749556],
            [39.1487267304295, 51.5534408110263],
            [39.152846603476554, 51.5551262577055],
            [39.15550735481978, 51.554992494388635],
            [39.15795352944163, 51.55627660589915],
            [39.16384366043799, 51.56202122107174],
            [39.16209486016001, 51.56509051271687],
            [39.15619400032668, 51.566414456584084],
            [39.15454175957368, 51.56839361353305],
            [39.15460613259028, 51.56947676411289],
            [39.15615108498244, 51.57047965819043],
            [39.156580238425796, 51.57156275881256],
            [39.16007783898074, 51.57050640172784],
            [39.165807037436494, 51.57080057959854],
            [39.17732980736455, 51.570817294192835],
            [39.18432500847519, 51.57249206521375],
            [39.185591011130185, 51.574905498389725],
            [39.191170005881816, 51.58251265249269],
            [39.192264347159735, 51.5874719880717],
            [39.1870501328351, 51.59189616512454],
            [39.18383742327108, 51.598101387720796],
            [39.184582500540664, 51.60309501560351],
            [39.183493774220445, 51.60565783854256],
            [39.18418603468585, 51.60870149551326],
            [39.186343031813024, 51.61150184221214],
            [39.18896814038716, 51.61616954279275],
            [39.190610186984756, 51.617203543722546],
            [39.19199474151755, 51.61938626404981],
            [39.19292756882747, 51.62071195737592],
            [39.19325740994524, 51.621611870586854],
            [39.19389938015348, 51.622328115219766],
            [39.191219589669686, 51.62329327976065],
            [39.18007157268007, 51.61911860898242],
            [39.18017636004069, 51.618819527746645],
            [39.176935766379785, 51.618602050822695],
            [39.17627334028643, 51.620081038194755],
            [39.172516224393455, 51.61971393195801],
            [39.172276499340974, 51.62222525065028],
            [39.17273529933794, 51.62458291045947],
            [39.16388238082653, 51.62843379902997],
            [39.165151991531836, 51.62966240832959],
            [39.15747337985865, 51.63213016769064],
            [39.15402691413332, 51.63193633233724],
            [39.147950047138025, 51.63273044690708],
            [39.139460021557426, 51.636223548534694],
            [39.138551967403316, 51.63856214966434],
            [39.13763343721799, 51.642827459231285],
            [39.137290114463816, 51.64458299888143],
            [39.13524058308101, 51.644922085736745],
            [39.13421093851504, 51.64581450588149],
            [39.12969946295193, 51.64619830200358],
            [39.12982820898449, 51.6467789960148],
            [39.12864803701815, 51.64689913866955],
            [39.12890552908382, 51.647973733739825],
            [39.128111595215486, 51.64803380351682],
            [39.127886289658406, 51.64693918615111],
            [39.1248607578894, 51.64719949390693],
            [39.12494658857759, 51.64757994100429],
            [39.12534355551124, 51.64769340705951],
            [39.1255259457245, 51.648954863400675],
            [39.12222146421899, 51.64934864413261],
            [39.12229656607116, 51.65066344641546],
            [39.12256478697258, 51.650917058690176],
            [39.12207126051372, 51.65155108313575],
            [39.12119149595678, 51.651591126485386],
            [39.120558494629584, 51.651844733541004],
            [39.11913155943339, 51.65204494863092],
            [39.119217390121534, 51.652605546153424],
            [39.11717891127054, 51.65270565212146],
            [39.11509751707472, 51.652652262300336],
            [39.11484002501004, 51.65103051632443],
            [39.113681310715116, 51.651050538234685],
            [39.112522596421364, 51.65059003204421],
            [39.108649486603355, 51.64994264582434],
            [39.10602092176858, 51.653993662378966],
            [39.107093805375264, 51.6541471533798],
            [39.107050890031076, 51.654447460347164],
            [39.10929321676713, 51.654334011283396],
            [39.10967945486516, 51.655428449169236],
            [39.10772680670266, 51.655561915386286],
            [39.10783409506265, 51.65620922093698],
            [39.107276195588035, 51.65627595293958],
            [39.10742639929259, 51.65685651719449],
            [39.10612821012962, 51.65696328681982],
            [39.106278413834694, 51.65860483795612],
            [39.11093472868412, 51.6591586811569],
            [39.11327361494559, 51.658818368672485],
            [39.1129732075356, 51.658297885784535],
            [39.11471127897705, 51.657710667107885],
            [39.11622404486153, 51.65771066710892],
            [39.11683752095556, 51.65786537714168],
          ],
        ],
      },
      properties: {
        description: '10',
        fill: '#82cdff',
        'fill-opacity': 0.6,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 13,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.13291510828907, 51.65606378967474],
            [39.13107779511388, 51.656027086910754],
            [39.130412607278174, 51.65654759601416],
            [39.12637320050175, 51.65690627667587],
            [39.126418798055, 51.65731833884508],
            [39.12605401762893, 51.65734503100027],
            [39.12605669983794, 51.65762529768685],
            [39.12798789032867, 51.65788721200358],
            [39.12267979868824, 51.66360390530242],
            [39.12113216408658, 51.668751144782796],
            [39.12239980469904, 51.66980529527117],
            [39.11880442763958, 51.67261211219493],
            [39.11261864575375, 51.67715134743352],
            [39.111993819930895, 51.67820687921957],
            [39.11222486147295, 51.67887220171447],
            [39.11414232739345, 51.68006266459174],
            [39.116629007626116, 51.68273373177754],
            [39.116622208795434, 51.68360341143998],
            [39.11617600304593, 51.684729807802576],
            [39.089015393342, 51.6956119929952],
            [39.08994611987015, 51.69686710824288],
            [39.09484115132236, 51.69563699563442],
            [39.100473790253616, 51.69535863215511],
            [39.10382923373121, 51.69614871322749],
            [39.10477068909546, 51.69997390968622],
            [39.104218154038385, 51.70111723864895],
            [39.102619557465495, 51.70226553836342],
            [39.10061058291334, 51.7027988461211],
            [39.09943309315581, 51.70223220641877],
            [39.093639521683635, 51.70137890023193],
            [39.09170833119291, 51.702125544030004],
            [39.089991717423395, 51.702178875255974],
            [39.085485606278375, 51.703832111920946],
            [39.0826531935586, 51.70636517933425],
            [39.08166614064111, 51.708844780923975],
            [39.0820952940835, 51.7092980266607],
            [39.080292849625486, 51.71281719116758],
            [39.07780375965969, 51.71404350201244],
            [39.07445636280908, 51.71478993572847],
            [39.072267680252935, 51.71470996127977],
            [39.06934943684474, 51.71425677005203],
            [39.06724658497707, 51.71329705591328],
            [39.06411376484762, 51.71108430364732],
            [39.06291213520894, 51.709644623263515],
            [39.06201091297995, 51.708764795910206],
            [39.06153884419332, 51.70721839099868],
            [39.05986514576804, 51.70631185307016],
            [39.05492988118063, 51.69809885399824],
            [39.055916934098065, 51.694605208605736],
            [39.052569537247486, 51.69087124209404],
            [39.05535903462297, 51.689884356357155],
            [39.055916934098065, 51.68764377872197],
            [39.05716147908098, 51.68521636048845],
            [39.058277278031184, 51.683909234943194],
            [39.0631267119301, 51.68089469876848],
            [39.06857696064836, 51.67934733636527],
            [39.0714093733681, 51.67627913305455],
            [39.0734263945473, 51.675105156519194],
            [39.0758296538246, 51.67158304337772],
            [39.07836165913466, 51.669448295364184],
            [39.08415523060683, 51.66058801041879],
            [39.0833827544105, 51.658799729651854],
            [39.084927706803064, 51.65506279459054],
            [39.083382754410444, 51.653888265317754],
            [39.08419814595097, 51.65167259271304],
            [39.086086421097455, 51.64972377863021],
            [39.06366315373289, 51.65139228902579],
            [39.05536976345887, 51.641547185334986],
            [39.046541985970656, 51.63479605854899],
            [39.050754081418454, 51.63382142421589],
            [39.05848742895999, 51.639523711628634],
            [39.06475528211488, 51.64752085559739],
            [39.06604384889008, 51.65063793259231],
            [39.08638682850714, 51.649109751267545],
            [39.08145156391972, 51.64403703151247],
            [39.080936579788855, 51.642942317145085],
            [39.075034241542156, 51.63654380382537],
            [39.06526000333767, 51.62902267362288],
            [39.04935745185399, 51.62015764200439],
            [39.031510258944714, 51.610520803646914],
            [39.034751482604996, 51.607668247520536],
            [39.04165548860932, 51.609889421649115],
            [39.049938150047346, 51.61863945071842],
            [39.05710016043883, 51.623271403751176],
            [39.065301843284615, 51.62857301966835],
            [39.06891390858955, 51.63090046436657],
            [39.07238095410364, 51.633821535629714],
            [39.07577289155623, 51.635684245766214],
            [39.080271251796695, 51.641234732636725],
            [39.090316124557454, 51.638359203271925],
            [39.09717721521752, 51.6440650224713],
            [39.09855587065117, 51.64579548366494],
            [39.10061312496564, 51.655731417750054],
            [39.10289032041927, 51.655574595495516],
            [39.10359305918121, 51.658307228672996],
            [39.114734955429036, 51.65990869495121],
            [39.11805821239845, 51.65665232042353],
            [39.11743862211594, 51.65382448883822],
            [39.13305444300068, 51.654723741831255],
            [39.13291510828907, 51.65606378967474],
          ],
        ],
      },
      properties: {
        description: '12',
        fill: '#b51eff',
        'fill-opacity': 0.5,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 14,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.145717491626016, 51.64178227459065],
            [39.14875375223081, 51.64477272790404],
            [39.15376411867047, 51.645513635471],
            [39.155738224505356, 51.64662163668747],
            [39.15620224666494, 51.64772126760821],
            [39.15262149762998, 51.649965499418116],
            [39.1486759681691, 51.65389805861493],
            [39.143234888336806, 51.65177396316919],
            [39.14203594090717, 51.65163214338671],
            [39.1373528039672, 51.65207929130579],
            [39.135601321480486, 51.651912445585225],
            [39.13536260487811, 51.654253234607104],
            [39.13491199376361, 51.65479545279158],
            [39.13306730017561, 51.65472337357408],
            [39.11743147501259, 51.65382126203509],
            [39.118053747504035, 51.656657435250295],
            [39.11685211786533, 51.65785191128383],
            [39.1161976588657, 51.657705105879394],
            [39.11459906229282, 51.65776516269459],
            [39.11293609270359, 51.658292324628974],
            [39.11324722894933, 51.65880613476077],
            [39.110854698508, 51.659153120109664],
            [39.10626636481517, 51.65858745598312],
            [39.10611616111036, 51.656972596587785],
            [39.10738216376535, 51.65685915388116],
            [39.10728560424085, 51.656291936048106],
            [39.10785423255203, 51.65621853086586],
            [39.107746944191426, 51.6555778987481],
            [39.10974250769849, 51.65543775925898],
            [39.10928116774789, 51.65430995418292],
            [39.10703884101146, 51.654443423709175],
            [39.1071032140278, 51.654143116714756],
            [39.10603033042186, 51.65397627863141],
            [39.10866962409247, 51.64993860878435],
            [39.11249981856574, 51.65059934313536],
            [39.11365853286015, 51.6510531752631],
            [39.114849433662776, 51.651046501294324],
            [39.11506401038393, 51.65264155174458],
            [39.11719904875979, 51.6527082890274],
            [39.11919461226685, 51.65262153054047],
            [39.11914096808657, 51.65205425939784],
            [39.12054962265998, 51.651868352540674],
            [39.12117189515145, 51.65158805006809],
            [39.122083846216526, 51.651548006715494],
            [39.122588101511255, 51.650927330204354],
            [39.12231988060979, 51.650673717988234],
            [39.12222332108524, 51.6493522417849],
            [39.125527802591506, 51.64895178680763],
            [39.12534541237846, 51.64769033038161],
            [39.12495917428033, 51.64757686431736],
            [39.124884072427854, 51.64721644080903],
            [39.12788814652455, 51.646949458574724],
            [39.12809199440967, 51.648037401278856],
            [39.128875199442014, 51.6479773315063],
            [39.12867135155688, 51.64690941110326],
            [39.12983006585132, 51.64679594307437],
            [39.12971204865467, 51.64620857459665],
            [39.13418755223057, 51.64581999207236],
            [39.13524966777972, 51.644913886647004],
            [39.137290566151464, 51.64458232026666],
            [39.137846240368376, 51.64172971335614],
            [39.138551780964086, 51.638569705707745],
            [39.13946204452292, 51.636220637831464],
            [39.14794644417078, 51.632727417776024],
            [39.15402953542563, 51.631934514903804],
            [39.15747333300984, 51.63212977065952],
            [39.165154862046656, 51.62966255695525],
            [39.16565375292342, 51.63014831580895],
            [39.16512267553847, 51.63034028092435],
            [39.16534529888675, 51.6306140384568],
            [39.15772259730311, 51.63323253444166],
            [39.15690720576259, 51.63296546943687],
            [39.15401042002643, 51.632377920865984],
            [39.14835364121398, 51.63317077580868],
            [39.141200189771304, 51.636313682625115],
            [39.141586427869484, 51.63797267526516],
            [39.145717491626016, 51.64178227459065],
          ],
        ],
      },
      properties: {
        description: '9',
        fill: '#ffd21e',
        'fill-opacity': 0.5,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 15,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.33101381443279, 51.563496147533606],
            [39.32899679325382, 51.56595686669657],
            [39.32629312656671, 51.5706104695562],
            [39.32886804722073, 51.57986276293194],
            [39.325348988993326, 51.58283058312978],
            [39.32479108951827, 51.585236780803086],
            [39.32852472446714, 51.58788345031082],
            [39.328610555155485, 51.58849831094083],
            [39.32680811069765, 51.58839137926436],
            [39.32676519535321, 51.59301594339266],
            [39.31256021641066, 51.59293575267009],
            [39.3105431952309, 51.600179073695756],
            [39.30817644758293, 51.607512110775566],
            [39.30321167869637, 51.61218481605209],
            [39.29410024641915, 51.60182959007273],
            [39.292206606854684, 51.60221877125935],
            [39.29272251752777, 51.60355413563528],
            [39.27849309502319, 51.60550560328562],
            [39.28049329028588, 51.61104142611431],
            [39.27787008986955, 51.61156078444164],
            [39.27370998368758, 51.613721651746275],
            [39.2683887603397, 51.6138641211895],
            [39.2571934992493, 51.61566473311786],
            [39.254993697949416, 51.610893375263394],
            [39.253030127914194, 51.61117486094023],
            [39.25130252166146, 51.61072775342841],
            [39.24767074004446, 51.610139421685886],
            [39.24544717482103, 51.60970435929826],
            [39.24408250086669, 51.606400033229406],
            [39.24119644396574, 51.60677080361328],
            [39.241483440331095, 51.608085176789636],
            [39.24053125613044, 51.6081469697575],
            [39.239686360290904, 51.60752903626752],
            [39.237237503460605, 51.607582479495186],
            [39.23719117237043, 51.60659488993083],
            [39.236851261718016, 51.59875668198556],
            [39.23274210633564, 51.59695897575899],
            [39.230480953346756, 51.59452534693018],
            [39.22022830879149, 51.59455793651249],
            [39.22021097383713, 51.59761083437863],
            [39.21070882703821, 51.597424606817626],
            [39.208439147004235, 51.5969026418979],
            [39.209643193248205, 51.59573794591924],
            [39.208871925354465, 51.59468247018471],
            [39.209795209406835, 51.59319122791121],
            [39.21010664772802, 51.59177284846667],
            [39.21230084574006, 51.5903397219309],
            [39.21294733363174, 51.5892889525533],
            [39.21230498233195, 51.587894565403055],
            [39.21093238074865, 51.586609945615685],
            [39.22131698778173, 51.580553682839735],
            [39.22349304136566, 51.580499905225466],
            [39.22536105657548, 51.57952382036712],
            [39.227026987184225, 51.57646211754537],
            [39.23050937580206, 51.57630163941223],
            [39.23119703202208, 51.57556626170988],
            [39.22705776960614, 51.57517931878304],
            [39.227615669081594, 51.573815519135366],
            [39.229160621474016, 51.57194357029305],
            [39.231520965407, 51.57132848447432],
            [39.2329800871113, 51.571087796441866],
            [39.234396293471086, 51.569376200157606],
            [39.235597923109786, 51.56774477465562],
            [39.23628456861739, 51.56619352861743],
            [39.23700340063346, 51.565585051206185],
            [39.23700340063346, 51.564796024350954],
            [39.23713214666607, 51.564381445403164],
            [39.236102178404316, 51.56360577136006],
            [39.23575417180561, 51.563262893482666],
            [39.2362906136085, 51.56193885737135],
            [39.23637644429695, 51.56133700996413],
            [39.235840002494335, 51.56065490655683],
            [39.23545376439574, 51.560280414038516],
            [39.23532501836311, 51.5598791686144],
            [39.23450962682267, 51.559544794709524],
            [39.234359423117674, 51.55919704322805],
            [39.2339731850198, 51.55907666709209],
            [39.23395172734749, 51.55852828286413],
            [39.23390881200333, 51.55808689560875],
            [39.2337156929542, 51.55768563073104],
            [39.234080473380125, 51.55719073248079],
            [39.23425213475707, 51.55668245296872],
            [39.23433796544567, 51.55637480733179],
            [39.23401268026443, 51.55591859177722],
            [39.233321958090066, 51.55556682949921],
            [39.23291426231988, 51.55551332474131],
            [39.232678227926314, 51.555219047442705],
            [39.232892804647754, 51.55500502638628],
            [39.23207741310708, 51.55456360476061],
            [39.231176190878315, 51.55408204898499],
            [39.23072557976369, 51.553921529254254],
            [39.23001747658354, 51.55406867236247],
            [39.22958832314125, 51.553975035894325],
            [39.22963123848549, 51.554296074406835],
            [39.23036079933777, 51.554429839781356],
            [39.23055391838669, 51.55484450992972],
            [39.23070412209148, 51.55507190807509],
            [39.22973852684596, 51.55491139185609],
            [39.22945957710862, 51.55455022828031],
            [39.22926645805939, 51.55481775713146],
            [39.22973852684596, 51.55531268133673],
            [39.22977107657151, 51.55585601078578],
            [39.23069375647271, 51.556471307022676],
            [39.2308439601776, 51.556952837372165],
            [39.23017877234203, 51.556738824516245],
            [39.22964233053888, 51.55680570364241],
            [39.23015731466974, 51.556872582669804],
            [39.22977107657151, 51.55703309193191],
            [39.23062938345632, 51.55716684921587],
            [39.231509148013565, 51.55857127682177],
            [39.231723724734366, 51.55882540668307],
            [39.231509148013565, 51.55917316102185],
            [39.23131602896418, 51.558785281010216],
            [39.23112290991503, 51.55850440030509],
            [39.23056501043995, 51.55831714553292],
            [39.230200230014205, 51.55799613555059],
            [39.22992128027638, 51.55795600914228],
            [39.23013585699774, 51.55833052090086],
            [39.23075812948908, 51.55869165430358],
            [39.230908333193995, 51.55909291025905],
            [39.23075812948908, 51.55946741260725],
            [39.23056501043995, 51.55977503721704],
            [39.229728161227605, 51.56000241058429],
            [39.22923463476889, 51.55990878639482],
            [39.22895568503094, 51.56041702966496],
            [39.22801154745783, 51.56055077694471],
            [39.22569411886903, 51.561620740951426],
            [39.225479542147696, 51.56235632652628],
            [39.225200592410346, 51.56257031281147],
            [39.22389167441112, 51.562396449031866],
            [39.22387021673899, 51.562864542300346],
            [39.22369855536189, 51.56307852618168],
            [39.22290462149355, 51.56342624782947],
            [39.22241109503474, 51.56343962168571],
            [39.22217506064114, 51.563666976635155],
            [39.221209465396264, 51.56368035042028],
            [39.22007220877363, 51.564268793045365],
            [39.21996492041308, 51.564710119992654],
            [39.2202438701509, 51.5651246959278],
            [39.220479904543836, 51.565325295822845],
            [39.22017949713423, 51.565673000201606],
            [39.219943462740716, 51.56594046328862],
            [39.21908515585631, 51.56600732881318],
            [39.218806206118686, 51.565753239293635],
            [39.218505798708925, 51.56540553553307],
            [39.21773332251264, 51.56540553553307],
            [39.21728271139817, 51.56540553553307],
            [39.217347084414385, 51.56520493599389],
            [39.21713250769343, 51.56492409514416],
            [39.21672481192314, 51.56507120247325],
            [39.21599525107092, 51.56501770895456],
            [39.215737759005805, 51.56472349346995],
            [39.21533006323562, 51.56438915536901],
            [39.214085518252304, 51.56433566104378],
            [39.21363490713788, 51.56437578179363],
            [39.21324866903985, 51.564148430406455],
            [39.21251910818778, 51.563894330444874],
            [39.211532055270055, 51.56377396680703],
            [39.21097415579516, 51.5635198647434],
            [39.21080249441837, 51.56232957817332],
            [39.210459171664446, 51.56140675017867],
            [39.21015876425459, 51.560657774489165],
            [39.208098827731355, 51.55982853694211],
            [39.20661824835493, 51.55894578349515],
            [39.206546473133244, 51.558138999809984],
            [39.20609586201882, 51.55749697503662],
            [39.205022978412856, 51.556895068537855],
            [39.204014467823306, 51.55622627414812],
            [39.20373551808576, 51.555998881803895],
            [39.202061819660045, 51.555530717496616],
            [39.19918649159637, 51.5548485265503],
            [39.196847605335435, 51.554580997880905],
            [39.194787668811976, 51.55464788019663],
            [39.193006682026095, 51.55482177375461],
            [39.1916548486826, 51.55492878484269],
            [39.18993823491339, 51.55452749195725],
            [39.1883289095041, 51.55407268905197],
            [39.187384771930816, 51.55376502567084],
            [39.18620459996425, 51.55380515579701],
            [39.184981512653486, 51.55349749059719],
            [39.183865713703234, 51.55337709929745],
            [39.183608221637996, 51.55249421997869],
            [39.18358676396556, 51.55198588771021],
            [39.18292157612996, 51.55133039819706],
            [39.182234930622165, 51.55066152156122],
            [39.18182723485204, 51.55042072355183],
            [39.18073289357396, 51.550179924261066],
            [39.17948834859105, 51.54992574584248],
            [39.17914502583718, 51.54967156599608],
            [39.1786729570506, 51.54840064535216],
            [39.17794339619822, 51.547825374801576],
            [39.17702071629723, 51.54742401892407],
            [39.17661302052669, 51.547290232840915],
            [39.17502515279011, 51.546929008440856],
            [39.17453162633153, 51.54623330888244],
            [39.173694777118726, 51.54585869699816],
            [39.17245023213576, 51.54580518076196],
            [39.17148463689073, 51.545791801692616],
            [39.170905279743344, 51.54557773605046],
            [39.167021441089865, 51.54565801078502],
            [39.16693561040114, 51.54512284319981],
            [39.16747205220421, 51.544908774393996],
            [39.16757934056451, 51.545096084654446],
            [39.16794412099102, 51.54505594680651],
            [39.16802995167913, 51.544788360245796],
            [39.167462811595925, 51.54456802949298],
            [39.167248234875025, 51.54396595101726],
            [39.166711793071876, 51.54352442171217],
            [39.16630409730169, 51.543497662222116],
            [39.166068062908174, 51.543577940645186],
            [39.16566036713799, 51.543457522957105],
            [39.16490934861383, 51.54343076342706],
            [39.16452311051601, 51.543029368582395],
            [39.16400812638499, 51.54316316725922],
            [39.162506089336404, 51.542962469095386],
            [39.16246317399237, 51.54269487015943],
            [39.162506089336404, 51.542413889574256],
            [39.161905274517295, 51.542159667541924],
            [39.16154049409117, 51.54207938660353],
            [39.161111340648446, 51.541999105522976],
            [39.16108988297655, 51.54182516269297],
            [39.16023157609194, 51.541223047736175],
            [39.15870808137168, 51.54091529700052],
            [39.158257470256906, 51.540901916487414],
            [39.15812872422406, 51.541035721452324],
            [39.15811294394234, 51.54080432590683],
            [39.15747994261495, 51.54057016576169],
            [39.15730828123808, 51.54016874555291],
            [39.156889856631615, 51.53981415474119],
            [39.156900585467895, 51.539579989474866],
            [39.15616029577974, 51.53915848894646],
            [39.15926711411826, 51.53857428527579],
            [39.158038888254076, 51.536225494442995],
            [39.15902146521483, 51.53608101057956],
            [39.158109514149444, 51.53361197514613],
            [39.159096567067046, 51.53298298525555],
            [39.15642508688806, 51.53210639966486],
            [39.15481077724532, 51.5316752411059],
            [39.154290428696456, 51.53158155830753],
            [39.153845181999934, 51.53162170810206],
            [39.153255096016544, 51.531186750101305],
            [39.15256845050924, 51.53075847967894],
            [39.15213929706642, 51.53041719878679],
            [39.15196763568947, 51.52995211578502],
            [39.15182816082094, 51.529824969960124],
            [39.151432370495684, 51.52987719918151],
            [39.15175423557737, 51.53034562886324],
            [39.15207610065926, 51.530867587658385],
            [39.15194735462653, 51.53101480571821],
            [39.151196336102295, 51.530747136162596],
            [39.15117487843032, 51.53113525650202],
            [39.1515825742005, 51.53165720619675],
            [39.15205464298715, 51.53319625360917],
            [39.15192589695417, 51.53436054168267],
            [39.15190443928229, 51.535390978569446],
            [39.15192589695417, 51.53555156402446],
            [39.15274128849474, 51.53564523860996],
            [39.152784203839204, 51.535819205183635],
            [39.152290677380265, 51.53572553095807],
            [39.15188298161013, 51.53568538480185],
            [39.15171132023313, 51.535886115226425],
            [39.1515825742005, 51.536153754408375],
            [39.15188298161013, 51.53623404585423],
            [39.15188298161013, 51.53647491933825],
            [39.15136476668986, 51.536889717449185],
            [39.15099998626343, 51.53729116673074],
            [39.149669610592504, 51.5379736223426],
            [39.14887567672383, 51.53821448657253],
            [39.14803882751121, 51.538749735829676],
            [39.147395097347584, 51.538923690476146],
            [39.1467942825285, 51.53897721484796],
            [39.14651533279082, 51.53929835975161],
            [39.14619346770918, 51.53908426340224],
            [39.14574285659453, 51.53899059593111],
            [39.146086179348146, 51.53870959235508],
            [39.145914517971455, 51.53824124918572],
            [39.145399533840795, 51.53749189003829],
            [39.146086179348146, 51.537438363910454],
            [39.146772824856214, 51.537746138281975],
            [39.1469874015774, 51.53800038509802],
            [39.147116147610156, 51.53773275683111],
            [39.14782425079028, 51.53739821927285],
            [39.147588216396784, 51.53714396907546],
            [39.14720197829868, 51.53697000759152],
            [39.14735218200367, 51.53659531904854],
            [39.14775987777386, 51.536314300606264],
            [39.14750238570821, 51.53597975256781],
            [39.146408044430345, 51.53618048168758],
            [39.14565702590606, 51.536702373234206],
            [39.14482017669341, 51.53741160082245],
            [39.143082105251786, 51.53770599391742],
            [39.14241691741596, 51.5379602409588],
            [39.140249692531995, 51.53773275683111],
            [39.13952013168007, 51.53758556060999],
            [39.136196357972835, 51.537159328548654],
            [39.133665382581896, 51.537650115117955],
            [39.1132376787245, 51.53534844252034],
            [39.11010485859515, 51.535482263899276],
            [39.10765868397341, 51.53735572166159],
            [39.104010879713144, 51.537998032192945],
            [39.10233718128779, 51.53759658917871],
            [39.10216551991095, 51.536204892505],
            [39.09881812306041, 51.533983441879165],
            [39.10658580036752, 51.52528394178151],
            [39.10063686853463, 51.52389482922191],
            [39.10265388971357, 51.5209766870448],
            [39.14647045618082, 51.50900766083148],
            [39.18058815485066, 51.49995515709747],
            [39.186449095684196, 51.509186762385575],
            [39.2132007774508, 51.50239890751956],
            [39.2075084589733, 51.493359805436285],
            [39.22553290355339, 51.48513479804256],
            [39.230039014698164, 51.48494723976478],
            [39.23381556499134, 51.48770694760743],
            [39.23562873828523, 51.489964156619045],
            [39.23912633884063, 51.495201520443835],
            [39.239748611332075, 51.49608551708175],
            [39.24283851611716, 51.49587121644712],
            [39.242259158969695, 51.504228190636155],
            [39.24183000552764, 51.509490686713555],
            [39.241045113959615, 51.516612786561154],
            [39.24121214909994, 51.52258746536178],
            [39.24177004857532, 51.52558581411119],
            [39.273688335852455, 51.52259415833082],
            [39.27407457395036, 51.53782478167235],
            [39.274289150671386, 51.539617857212846],
            [39.2710991370074, 51.54480195015572],
            [39.26916794651645, 51.547183414224165],
            [39.26122860783252, 51.54557794659144],
            [39.26182942265178, 51.5473707150719],
            [39.25770954960522, 51.54975204399385],
            [39.2558212744585, 51.55159815660048],
            [39.256422089277585, 51.55542391833628],
            [39.25667958134331, 51.557804823538206],
            [39.25743928735093, 51.56298762829855],
            [39.2769657689793, 51.5628003920832],
            [39.27915445153545, 51.561596712185185],
            [39.28988328759493, 51.56060699583218],
            [39.29117074792226, 51.5680694581997],
            [39.29172864739703, 51.569540407670885],
            [39.293316515134094, 51.56983459182425],
            [39.29898134057371, 51.567588046165994],
            [39.30074086968736, 51.56756130090266],
            [39.299796732113975, 51.57529002385713],
            [39.30336441187045, 51.57644923934818],
            [39.32572330621854, 51.56278302680063],
            [39.328684464971, 51.56219456484817],
            [39.33101381443279, 51.563496147533606],
          ],
        ],
      },
      properties: {
        description: '8',
        fill: '#ed4543',
        'fill-opacity': 0.5,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 16,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.2454457713191, 51.6097013312327],
            [39.24756865681129, 51.61012167641338],
            [39.25126844638831, 51.61071791847678],
            [39.253035146744324, 51.61117488121992],
            [39.25499496614944, 51.61089037534226],
            [39.25598382589117, 51.61301058661749],
            [39.25719268282684, 51.61566654594458],
            [39.2683935510667, 51.6138645243545],
            [39.273714484888416, 51.61371963548987],
            [39.277876631203036, 51.61156282686649],
            [39.28049318304854, 51.61104269070887],
            [39.27849505123533, 51.605504623963164],
            [39.292721487850365, 51.60355381532369],
            [39.292206503719505, 51.602217596411755],
            [39.29409477886599, 51.60183008553096],
            [39.30321428951667, 51.61218483714404],
            [39.3002558129732, 51.615031977673674],
            [39.26192910522646, 51.62219577139192],
            [39.257704697231, 51.6223811213868],
            [39.255580387691246, 51.63215696331278],
            [39.24962282917692, 51.632136193850634],
            [39.2496174647589, 51.632204630666365],
            [39.25159157059391, 51.63234818072207],
            [39.2507332637091, 51.63395056896173],
            [39.24987495682449, 51.635499490172236],
            [39.250239737250446, 51.63643415828359],
            [39.24716056130121, 51.636988273808086],
            [39.246921844698925, 51.63652595500251],
            [39.24517572663034, 51.63678131579436],
            [39.24077200153886, 51.636062744556845],
            [39.24088042333356, 51.63640882279916],
            [39.23976998880138, 51.636585740040054],
            [39.23943471267452, 51.63580796661361],
            [39.238919728543706, 51.63574120432011],
            [39.23788976028198, 51.63595484330785],
            [39.23538069912447, 51.6364975851187],
            [39.23574011513229, 51.63716185439516],
            [39.23541556784159, 51.63723362159569],
            [39.23648576923852, 51.63913958314482],
            [39.233995312281664, 51.63973241618834],
            [39.233678811618034, 51.639126597464525],
            [39.23259788138482, 51.639351902898746],
            [39.230985873766926, 51.63945537612853],
            [39.23013024909128, 51.63911491493208],
            [39.23061572892295, 51.63865094896384],
            [39.23066937310319, 51.63780978986731],
            [39.23066937310319, 51.63758948370251],
            [39.23050844056227, 51.63719560030266],
            [39.23060500008687, 51.636995319289106],
            [39.23043333870977, 51.63686847418717],
            [39.23041188103764, 51.63654134570006],
            [39.2300685582837, 51.63606066280567],
            [39.229446285792406, 51.63570014727265],
            [39.22893499446949, 51.635338090617935],
            [39.227604618798026, 51.63493751121064],
            [39.22692870212639, 51.63478395482836],
            [39.22627424312664, 51.634677132688964],
            [39.225437393913914, 51.634610368723365],
            [39.224664917717654, 51.63404954751266],
            [39.22436451030794, 51.63367566283302],
            [39.2241070182426, 51.633048063728886],
            [39.224343052635945, 51.632814380980825],
            [39.22455762935702, 51.6327409375816],
            [39.224664917717654, 51.631679333277695],
            [39.22480975709286, 51.630314870491276],
            [39.2246595533882, 51.62996098614008],
            [39.22454153619142, 51.62978738148142],
            [39.224337688306335, 51.629660516116296],
            [39.222556701520524, 51.627857653963126],
            [39.222523321661356, 51.62668695901807],
            [39.22229801610412, 51.626359756715615],
            [39.22245894864501, 51.62602587437002],
            [39.21892931313318, 51.623091613816],
            [39.21748092026509, 51.61941182759426],
            [39.21499597940529, 51.61322128491258],
            [39.21486186895455, 51.61301087809974],
            [39.21460437688913, 51.61284388786701],
            [39.214153765774604, 51.61255666322218],
            [39.213864087201, 51.612142522146115],
            [39.21364951047982, 51.61197552870222],
            [39.21352076444711, 51.6115279832274],
            [39.21337056074227, 51.61100695455097],
            [39.21327400121774, 51.61080655730509],
            [39.21302723798835, 51.61051931970309],
            [39.213059424496535, 51.610258800298155],
            [39.2128555766114, 51.607753729310694],
            [39.21268391523446, 51.60717253298376],
            [39.212533711529616, 51.607125769735816],
            [39.21259808454599, 51.60700552116166],
            [39.21235132131662, 51.60633078711309],
            [39.21250152502141, 51.605549151902224],
            [39.21254444036565, 51.60478086479449],
            [39.21258735570988, 51.60331106174264],
            [39.212522982693514, 51.602596185700584],
            [39.21215820226749, 51.60215522848051],
            [39.21140718374333, 51.60129334512206],
            [39.21066689405521, 51.60062520717453],
            [39.21044158849797, 51.600204275193185],
            [39.21052741918645, 51.59989024401777],
            [39.210570334530686, 51.59942921557161],
            [39.210902928448526, 51.59870759638492],
            [39.211042403317315, 51.59838019128628],
            [39.211085318661546, 51.597912465602235],
            [39.21071517381737, 51.59742468937628],
            [39.22021310374492, 51.59761063254064],
            [39.22022651478996, 51.59455858318265],
            [39.23047791764486, 51.59452350095646],
            [39.23274018038172, 51.5969586122946],
            [39.236850846264296, 51.59875765510875],
            [39.236939074501805, 51.60121391773657],
            [39.23706782053444, 51.60404003692841],
            [39.23724060861917, 51.60758306897789],
            [39.239688498333265, 51.60753210033105],
            [39.24054144080002, 51.608150033783744],
            [39.241485672166995, 51.608088266309814],
            [39.24120505845262, 51.60677236608249],
            [39.244078319404146, 51.60640308419912],
            [39.2454457713191, 51.6097013312327],
          ],
        ],
      },
      properties: {
        description: '7',
        fill: '#82cdff',
        'fill-opacity': 0.6,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 17,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.22934602543972, 51.63918217107134],
            [39.23012923047198, 51.639115413771854],
            [39.23097680852076, 51.63945587496661],
            [39.23260759160157, 51.63934906388808],
            [39.23368047520777, 51.63912876523958],
            [39.23399161145335, 51.63973625284502],
            [39.23649143025523, 51.63914211670365],
            [39.23541677708988, 51.63723364122896],
            [39.23574400658957, 51.637163543033196],
            [39.23537922616367, 51.63649927378636],
            [39.2380319308794, 51.63592512364213],
            [39.23838330026023, 51.636642810179865],
            [39.23966271396037, 51.636399131844904],
            [39.23976732011204, 51.63658940134215],
            [39.24088311906219, 51.6364091460493],
            [39.24077583070151, 51.63606365472287],
            [39.245174653485975, 51.63678300808141],
            [39.2469234537636, 51.636530985357844],
            [39.24715948815697, 51.63698996608721],
            [39.25023473754466, 51.63643618014383],
            [39.24987800374583, 51.63549984301038],
            [39.25159461751522, 51.63234352603256],
            [39.24961782947127, 51.63220164515101],
            [39.249612465053296, 51.632138215907034],
            [39.25557769790223, 51.632154907820045],
            [39.25770444950263, 51.62238261165289],
            [39.261935519100156, 51.62219480307342],
            [39.300256239295614, 51.615031824188314],
            [39.301836060405584, 51.628824137400336],
            [39.286914538081774, 51.63111773862594],
            [39.280007849868255, 51.63243474153511],
            [39.280652761715096, 51.63351267004239],
            [39.2736682894404, 51.63494643936448],
            [39.27257536082291, 51.635377762795784],
            [39.27324098296878, 51.63667225892731],
            [39.274769271300556, 51.639822763844904],
            [39.242868199314366, 51.645851884406525],
            [39.23200750096614, 51.64824856710781],
            [39.22934602543972, 51.63918217107134],
          ],
        ],
      },
      properties: {
        description: '6',
        fill: '#56db40',
        'fill-opacity': 0.6,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 18,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.23200978605575, 51.648246333599836],
            [39.242808283061336, 51.64586632466809],
            [39.243057373807645, 51.64581794432157],
            [39.26116542514732, 51.64239494522666],
            [39.26328110687971, 51.64702987846545],
            [39.26401364539204, 51.6503671267048],
            [39.26540957767017, 51.65394279755445],
            [39.26575398982841, 51.65484474381648],
            [39.2673842525202, 51.65812272886462],
            [39.26838634839733, 51.66173117406095],
            [39.26868987908779, 51.66332010205178],
            [39.26980499886449, 51.66391554522984],
            [39.26886675695038, 51.66518834478208],
            [39.25255578019533, 51.67272930970157],
            [39.25155799844179, 51.67318958977927],
            [39.24939309357799, 51.67412746736407],
            [39.24790178536574, 51.67460107412739],
            [39.24597059487501, 51.67485455175891],
            [39.24456511735121, 51.67483454041879],
            [39.243878471843395, 51.67474115404739],
            [39.24257149452459, 51.67423841987104],
            [39.24333324188495, 51.67331787643212],
            [39.24331253384203, 51.6723533871969],
            [39.243130143629, 51.6713727666476],
            [39.24288338039961, 51.67097250723097],
            [39.242894109235735, 51.67068565245937],
            [39.24278682087513, 51.670452164671005],
            [39.24284046505545, 51.670265373569286],
            [39.242808278547265, 51.66998518546503],
            [39.24302285526845, 51.66985843313128],
            [39.24317305897329, 51.66905788383874],
            [39.2430335841045, 51.6686709465834],
            [39.24317305897329, 51.668437448356514],
            [39.243237431989634, 51.66781700433278],
            [39.24279754971121, 51.666516045583066],
            [39.242658074842474, 51.665415205001814],
            [39.243248160825736, 51.664914814031555],
            [39.24447124813654, 51.664060800611374],
            [39.245640691267035, 51.66302662207465],
            [39.24566214893914, 51.66269301103249],
            [39.24542611454583, 51.6619724027573],
            [39.244632180677435, 51.66066460275937],
            [39.24368804310418, 51.65994396205824],
            [39.24292629574395, 51.65963701900214],
            [39.2422141013138, 51.65952240461688],
            [39.24125923490454, 51.65951573189839],
            [39.24026145315099, 51.65942231378039],
            [39.239360230922, 51.65975594902723],
            [39.23889889097145, 51.66045657500755],
            [39.23780454969335, 51.66140407091931],
            [39.23728956556251, 51.66139072604474],
            [39.23732175207067, 51.66145077794923],
            [39.236956971644645, 51.661464122806024],
            [39.23598064756323, 51.66105710289651],
            [39.23583044385839, 51.66087027285498],
            [39.23594846105505, 51.66083023774535],
            [39.23562659597326, 51.65998949222982],
            [39.23579825735023, 51.65996947428834],
            [39.23414601659709, 51.65466440650317],
            [39.23400654172832, 51.654691100230615],
            [39.23375977849896, 51.653957016967766],
            [39.2339314398759, 51.65396369050579],
            [39.23325552320419, 51.65170797839561],
            [39.233083861827225, 51.651748021606174],
            [39.23279418325362, 51.65089375873576],
            [39.23296584463056, 51.650860388763974],
            [39.23200978605575, 51.648246333599836],
          ],
        ],
      },
      properties: {
        description: '5',
        fill: '#ffd21e',
        'fill-opacity': 0.5,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 19,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.30056856390472, 51.6660070989883],
            [39.30008576628278, 51.66885584830504],
            [39.300429089036186, 51.671564328211225],
            [39.300557835069476, 51.67407252895278],
            [39.30068658110181, 51.67592690674983],
            [39.30190966841256, 51.68086264836099],
            [39.31638230728712, 51.68146576314978],
            [39.31977261948186, 51.6773596957863],
            [39.33095206665585, 51.676955484583935],
            [39.33361281799859, 51.67723043041507],
            [39.33145632195053, 51.68167189826857],
            [39.32979476577449, 51.68487008819033],
            [39.32829258961106, 51.685795551674275],
            [39.32824903711773, 51.687478062769124],
            [39.329450348181844, 51.68902123532904],
            [39.33125263335259, 51.69025348713141],
            [39.33423200077785, 51.69230145487682],
            [39.33980061992692, 51.69404551078712],
            [39.338373454238244, 51.695864284854295],
            [39.34596445338503, 51.698283748430335],
            [39.3465816338282, 51.70156574217213],
            [39.351572490718674, 51.70134672795424],
            [39.356741632543205, 51.70551693486745],
            [39.36825633143125, 51.70145579418588],
            [39.370501549180034, 51.698113346700175],
            [39.371994936632966, 51.69939388388852],
            [39.371727954026234, 51.699780802867814],
            [39.372687971592086, 51.700079317586315],
            [39.3726529962441, 51.7005430011494],
            [39.37229218581625, 51.700590336848556],
            [39.37206886525804, 51.7008045925333],
            [39.36948957268519, 51.70440292065145],
            [39.371478771858726, 51.705365133223836],
            [39.367230589132575, 51.710707072414976],
            [39.33675497394613, 51.723726109378575],
            [39.311021078364504, 51.72680302878064],
            [39.303195546953305, 51.692210328777556],
            [39.27812609378534, 51.68248112514455],
            [39.273287461549415, 51.68364362297312],
            [39.272390299047494, 51.679435388796996],
            [39.27253600215454, 51.67442407432844],
            [39.27243926756877, 51.674053495210956],
            [39.27379194365432, 51.67389821991133],
            [39.272942526138635, 51.6698554785423],
            [39.27145826782844, 51.6699351638181],
            [39.268230766702395, 51.67058018763718],
            [39.26737514202645, 51.66945609697143],
            [39.267187387395374, 51.66949529051478],
            [39.26699158613741, 51.66926429828475],
            [39.26711630885679, 51.669238447095225],
            [39.26655036275437, 51.668189376633876],
            [39.26579397981262, 51.66703020043295],
            [39.26494640176323, 51.66733208947942],
            [39.265761793304236, 51.66855963958217],
            [39.26614803140262, 51.669170066451834],
            [39.26644843881184, 51.66909001093995],
            [39.266604006934784, 51.66933684831187],
            [39.26694464747977, 51.66926513218875],
            [39.267113626647934, 51.669503629554654],
            [39.26643234555792, 51.66968041699914],
            [39.26649121922011, 51.670106639996796],
            [39.26683990639177, 51.67091050617196],
            [39.26459533920074, 51.67142886624773],
            [39.264986941717225, 51.67208595026443],
            [39.26642997016717, 51.671825786163126],
            [39.26620734681872, 51.671328801850116],
            [39.266585538290265, 51.67123874370222],
            [39.26650238981009, 51.67107363663346],
            [39.26688911421849, 51.67099150287488],
            [39.26726998789862, 51.67162858050223],
            [39.268967826204964, 51.67128002343811],
            [39.2687666605288, 51.67081805609308],
            [39.271532246499476, 51.67042202036634],
            [39.2724334687286, 51.67405095340776],
            [39.269906756048314, 51.67435301162956],
            [39.26961171305668, 51.67324235644798],
            [39.26996576464653, 51.673158972821966],
            [39.26987993395813, 51.67282877214839],
            [39.26956838780289, 51.67285892826213],
            [39.26594218226162, 51.673710293779074],
            [39.26621576758084, 51.67423059871455],
            [39.26577052088409, 51.67431064509569],
            [39.26561495276163, 51.67402381157483],
            [39.26531163990044, 51.67406048911386],
            [39.26500050365501, 51.6736602535684],
            [39.26488248645802, 51.673690271357536],
            [39.26454989254038, 51.67280307048346],
            [39.26148680984561, 51.67365358294209],
            [39.25877241432221, 51.67435399291498],
            [39.25742058097872, 51.67465416528486],
            [39.25728647052788, 51.67431396978112],
            [39.25824133693762, 51.67407383023228],
            [39.25807503997842, 51.67360355324066],
            [39.25757078468331, 51.67278972897843],
            [39.256701508192265, 51.67288516463335],
            [39.255883434442886, 51.67119243338355],
            [39.26201650527669, 51.66830542484587],
            [39.26886664116242, 51.66519019035801],
            [39.2698025578477, 51.663917018896356],
            [39.26868675889785, 51.66331652910681],
            [39.268391834436436, 51.66177037012576],
            [39.267383323846985, 51.6581271022943],
            [39.26575254076615, 51.654843905455046],
            [39.26401446932422, 51.65035915275508],
            [39.26328490847281, 51.647035342911565],
            [39.26116864555984, 51.6423929455097],
            [39.27476886379417, 51.63982253339976],
            [39.27257623534879, 51.635375976839654],
            [39.27366293577789, 51.63494878897386],
            [39.280652772470766, 51.63351335058154],
            [39.280006360098305, 51.63243507263519],
            [39.28689427284923, 51.631119738965815],
            [39.3177812436382, 51.62637309753873],
            [39.31674308476282, 51.63725784234755],
            [39.316818186615265, 51.63919384363614],
            [39.3163032024844, 51.64163041683461],
            [39.31500501332125, 51.643105647876],
            [39.311112960022484, 51.64527053032623],
            [39.300212462586, 51.65030306689705],
            [39.30017739294113, 51.652110514069996],
            [39.30228251194095, 51.65376225224268],
            [39.30056856390472, 51.6660070989883],
          ],
        ],
      },
      properties: {
        description: '4',
        fill: '#82cdff',
        'fill-opacity': 0.5,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 20,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.25807028359335, 51.673589503177695],
            [39.25823013077159, 51.674083629749916],
            [39.257291357616175, 51.67431709872132],
            [39.257417421439996, 51.67465562658284],
            [39.25880144129185, 51.674345448442736],
            [39.26132634118686, 51.67369413161862],
            [39.26455022047605, 51.67280556274682],
            [39.264874767766926, 51.67368942826114],
            [39.264999427817244, 51.67366143490237],
            [39.26530519964503, 51.67405666751394],
            [39.265608289263724, 51.6740249823259],
            [39.2657745862226, 51.674315151100195],
            [39.26621715070995, 51.674230101825266],
            [39.265943565390934, 51.673709796882974],
            [39.269575276396665, 51.67285928548246],
            [39.269886412642705, 51.67283260251199],
            [39.26996956112217, 51.673159467809825],
            [39.26961371710709, 51.67323962091909],
            [39.269903395680416, 51.67435694668638],
            [39.27243942430406, 51.67405427087705],
            [39.27248147498018, 51.67404993531555],
            [39.27378829282801, 51.67390059796065],
            [39.272939794943476, 51.669858416575],
            [39.27146448632896, 51.66993532280116],
            [39.26823182431825, 51.67057918027446],
            [39.26737760702513, 51.66945430259855],
            [39.26718279179858, 51.66949560436503],
            [39.26699236666538, 51.669264415392455],
            [39.26711498275668, 51.669239716691855],
            [39.266554656196405, 51.66819231412295],
            [39.265793954671075, 51.667029802196026],
            [39.26495316337186, 51.667329189412044],
            [39.26614949757359, 51.6691680004184],
            [39.26644999894246, 51.66908877881665],
            [39.26659656036007, 51.669335199240656],
            [39.26695058806731, 51.669264108546884],
            [39.26711070436452, 51.669508755964834],
            [39.26643449459264, 51.669681113288235],
            [39.26648301510877, 51.670102518171184],
            [39.26683582345765, 51.670910280198015],
            [39.264596131076466, 51.67142589438144],
            [39.2649914749794, 51.67208145078916],
            [39.26642166108653, 51.671826359867026],
            [39.26620929709943, 51.67132857670404],
            [39.26659280269171, 51.67123645138427],
            [39.26650497310462, 51.67107531397037],
            [39.266889352492086, 51.67099641280027],
            [39.26727627320188, 51.67163073037151],
            [39.26896642769617, 51.67127912557594],
            [39.268764721977874, 51.67082063761435],
            [39.27153400242916, 51.670420445228366],
            [39.27253231231135, 51.674423834210934],
            [39.27239299836529, 51.679441905512284],
            [39.27328412520229, 51.68364471858986],
            [39.26565055834579, 51.68562374353983],
            [39.26284125952198, 51.685774442046636],
            [39.25872216309349, 51.68688591711853],
            [39.26308790032915, 51.69407555834557],
            [39.260834844756765, 51.69450561644582],
            [39.26038423364214, 51.69380551976176],
            [39.259574206519645, 51.69401554990797],
            [39.26002294166462, 51.69465227264703],
            [39.26046818836102, 51.69456892864516],
            [39.26127285106531, 51.69549570525336],
            [39.261678164968956, 51.69543435089306],
            [39.26210381980664, 51.696672223777014],
            [39.261744403798744, 51.696715560653125],
            [39.2616907596184, 51.69660221796835],
            [39.26077344413525, 51.69678889988463],
            [39.26036034114184, 51.69679936340441],
            [39.26029530112344, 51.697654870157216],
            [39.25953355376346, 51.69776154313921],
            [39.258911281271786, 51.697174838610444],
            [39.258362637355766, 51.697091863193805],
            [39.25786217606716, 51.697195153661774],
            [39.257905091411395, 51.69790186467405],
            [39.25686439431353, 51.698048539153476],
            [39.255941714412536, 51.69742183575999],
            [39.25561984933083, 51.696895131480524],
            [39.2551079344036, 51.69644036096377],
            [39.25459295027266, 51.69602032230958],
            [39.25413161032223, 51.6958203025255],
            [39.25383120291245, 51.695533605952015],
            [39.253906304765025, 51.695073554145914],
            [39.25365079769225, 51.69461987766984],
            [39.25272811779098, 51.69337303440664],
            [39.251794709054, 51.69226618204764],
            [39.25066818126754, 51.691406018956584],
            [39.24965967067815, 51.69072587836928],
            [39.24887177177441, 51.68970207539614],
            [39.24840032550818, 51.688928195448234],
            [39.248948118667705, 51.68791423641997],
            [39.249646738052526, 51.68760677049208],
            [39.25084979242581, 51.68667399639649],
            [39.251471830688104, 51.68572990359149],
            [39.25170456092527, 51.684886102513836],
            [39.25176026536741, 51.68408896919766],
            [39.25140206374404, 51.68342622469069],
            [39.25049550660697, 51.682815528372366],
            [39.249086261628584, 51.68224855436685],
            [39.248270870087936, 51.681674986569135],
            [39.247793436883306, 51.68119144990316],
            [39.24753594481789, 51.68107806814053],
            [39.247106791375955, 51.680974690393604],
            [39.24495029532764, 51.68071457761615],
            [39.2441670902953, 51.680444458909314],
            [39.24352336013206, 51.6800876329523],
            [39.24306738459923, 51.67960407924667],
            [39.24285817229622, 51.67939064699505],
            [39.242681146501305, 51.67937063766952],
            [39.242622137902806, 51.67927059090908],
            [39.242724061845536, 51.67912719016493],
            [39.242777706025954, 51.67902047303561],
            [39.24269187533746, 51.679023807949754],
            [39.24245584094391, 51.67893710010176],
            [39.242434383271835, 51.678686980375794],
            [39.242579222558774, 51.678273446051506],
            [39.2427562483538, 51.67811670221071],
            [39.24270260417349, 51.67775985781497],
            [39.2426704176653, 51.67712286686934],
            [39.242262721895116, 51.67656257419386],
            [39.24217689120644, 51.67597559344156],
            [39.242155433534286, 51.674864978256934],
            [39.24238610350972, 51.67442806207639],
            [39.24257117593169, 51.67423961982212],
            [39.24388545834902, 51.67474324249631],
            [39.24454259955763, 51.67483496124925],
            [39.245966852544456, 51.67485497258921],
            [39.247908597593934, 51.67459904685135],
            [39.2493968726118, 51.674125127920554],
            [39.25169836537283, 51.673127554085546],
            [39.255878541377825, 51.67119483037336],
            [39.25670344235881, 51.672890246519906],
            [39.25756979587064, 51.67279018536962],
            [39.25807028359335, 51.673589503177695],
          ],
        ],
      },
      properties: {
        description: '3',
        fill: '#56db40',
        'fill-opacity': 0.6,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 21,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.25686488638298, 51.69804721358703],
            [39.25790583395204, 51.697904240882835],
            [39.2578575541898, 51.69719586312255],
            [39.2583591272756, 51.697089188799296],
            [39.25890898012361, 51.69717419492129],
            [39.25953661703308, 51.69776256622071],
            [39.26029568218435, 51.6976492261706],
            [39.26036005520074, 51.696795833107764],
            [39.260775797598036, 51.696790832711095],
            [39.26169043087213, 51.696600817187246],
            [39.261749439470464, 51.69671415987578],
            [39.26210885547842, 51.69667248980284],
            [39.26167701982701, 51.69543070393087],
            [39.2612800528928, 51.69549737786792],
            [39.2604700257703, 51.69456726752795],
            [39.260017349391376, 51.694654221957535],
            [39.25957210269491, 51.69401080229556],
            [39.26038749423538, 51.693807439764726],
            [39.26083542314091, 51.69450253576611],
            [39.26308847871343, 51.69407581143445],
            [39.25872206890095, 51.686884425288284],
            [39.26283121311177, 51.68577741343581],
            [39.265652896995434, 51.68562403012951],
            [39.273285414919194, 51.68364595392662],
            [39.274792816385556, 51.69027626193724],
            [39.27618756507331, 51.6968105864727],
            [39.277353783562894, 51.702773694906924],
            [39.29016600264507, 51.70215591413347],
            [39.2916465820213, 51.7060355932967],
            [39.29351339949565, 51.71279423546916],
            [39.295079809560356, 51.71905873678622],
            [39.29767618788681, 51.728600428268315],
            [39.29880339804766, 51.733600863348734],
            [39.271599919023934, 51.72970247005677],
            [39.27100446862262, 51.73058521910341],
            [39.27005496663136, 51.731324716287084],
            [39.26952388924642, 51.731581205713255],
            [39.26911082905816, 51.731201467599966],
            [39.26903036278771, 51.73083171951441],
            [39.268429547968374, 51.73047196170223],
            [39.267469317141035, 51.7295259181633],
            [39.266652312201025, 51.72885689739855],
            [39.26587447158674, 51.72896016521649],
            [39.26821481159426, 51.731656650015296],
            [39.2684041097384, 51.73188950230544],
            [39.2684497072917, 51.732660621999585],
            [39.26863746192283, 51.733821440317655],
            [39.2690478399021, 51.73393302385612],
            [39.26908539082831, 51.734131208862685],
            [39.26871464506901, 51.734149743007414],
            [39.268796124789674, 51.734947083893196],
            [39.26880953583479, 51.73583639139862],
            [39.26893617685626, 51.736584190700775],
            [39.26916949173362, 51.73805718298234],
            [39.26938406845493, 51.73939770625319],
            [39.27036843916341, 51.73939604103084],
            [39.27035771032731, 51.73910629155174],
            [39.270688825606705, 51.73910787203284],
            [39.27064859247148, 51.739778955602425],
            [39.270399147033096, 51.73979061205236],
            [39.270380371569985, 51.73957246941672],
            [39.26939147536711, 51.73956358362976],
            [39.26954167907198, 51.743579903569966],
            [39.266635033016726, 51.7443321972929],
            [39.26340565336285, 51.74432553713601],
            [39.262740465527145, 51.746896283714804],
            [39.258953186398124, 51.74705611802562],
            [39.257992955570785, 51.747126045354975],
            [39.25779885535397, 51.7468986514439],
            [39.25765938048521, 51.74674880626991],
            [39.257616465141, 51.74655234183873],
            [39.257407252837844, 51.74651238286662],
            [39.25724632029694, 51.74631591740357],
            [39.257031743575766, 51.74567323624419],
            [39.25695127730532, 51.7455766667785],
            [39.25683326010866, 51.7455766667785],
            [39.256779615928345, 51.74544013717982],
            [39.25657040362519, 51.745410167212405],
            [39.25655967478914, 51.74534023721072],
            [39.25690836196108, 51.74534023721072],
            [39.25698882823153, 51.74514709664],
            [39.256784980346396, 51.74509381633672],
            [39.256774251510315, 51.744987255540686],
            [39.25660259013337, 51.744987255540686],
            [39.2566240478055, 51.74489068460103],
            [39.256725971748054, 51.74490067470786],
            [39.256881539870925, 51.74408813876416],
            [39.25650603060885, 51.74360194220022],
            [39.256597225715346, 51.7428426658128],
            [39.25698093435925, 51.74251841914263],
            [39.2567878153102, 51.741815738972115],
            [39.25680927298233, 51.74144607817016],
            [39.25737790129349, 51.74078001499772],
            [39.25775877497364, 51.74059684589421],
            [39.25786069891622, 51.74033041677614],
            [39.258134284235716, 51.740223844685914],
            [39.25825230143237, 51.74024382697192],
            [39.258413233973265, 51.74044697970876],
            [39.258563437678106, 51.74050026552052],
            [39.2596416857021, 51.740180549702444],
            [39.26076284907033, 51.739644354527904],
            [39.26149706973589, 51.739167127911664],
            [39.262441207309145, 51.738694200099914],
            [39.26258861288409, 51.73838740564575],
            [39.26250676055653, 51.73804312687628],
            [39.26162163158162, 51.73736036031498],
            [39.26047901054131, 51.73664094627935],
            [39.25927382632785, 51.73550695537621],
            [39.2580775611072, 51.735097273573494],
            [39.25783079787784, 51.73485079654404],
            [39.25768779136352, 51.73459100809017],
            [39.25741957046207, 51.73438116725993],
            [39.25744102813415, 51.73359508814724],
            [39.25704942561802, 51.73321203613844],
            [39.25675974704442, 51.732695743488584],
            [39.25674901820837, 51.73253585811096],
            [39.25696359492955, 51.731816366875336],
            [39.2571513495606, 51.73162316819418],
            [39.2573337397736, 51.73149992032598],
            [39.25763414718328, 51.731416644547224],
            [39.258320792691094, 51.73154988571777],
            [39.258513911740145, 51.731514909949595],
            [39.25867484428104, 51.73142663764872],
            [39.258889421002245, 51.73118680260009],
            [39.25878749705967, 51.730983608016274],
            [39.25843809775479, 51.73070946653378],
            [39.257735358992875, 51.73037968775263],
            [39.257402765075035, 51.72997329037255],
            [39.257252561370194, 51.72986336258127],
            [39.25706939294765, 51.72912841043805],
            [39.25691918924283, 51.728305595594904],
            [39.25704207579011, 51.727792447399395],
            [39.256913329757396, 51.72719614026898],
            [39.25698306719179, 51.72686300435063],
            [39.25721910158511, 51.72651654037875],
            [39.257208372749055, 51.726369958664115],
            [39.25704744020816, 51.72621005079418],
            [39.25700988928195, 51.72579362138049],
            [39.257203008331004, 51.725257254615464],
            [39.25714936415071, 51.72514731529608],
            [39.25737466970797, 51.724847479422785],
            [39.25756154703019, 51.72481169789484],
            [39.25816772626755, 51.72434195063155],
            [39.2588972871196, 51.72292934775037],
            [39.259970170725545, 51.72175658712666],
            [39.259669763315884, 51.72135677537986],
            [39.25940256856649, 51.7208607972054],
            [39.258937813881474, 51.72000974810571],
            [39.259130932930546, 51.719583265554924],
            [39.258937813881474, 51.71927672872411],
            [39.258980729225705, 51.718423745680475],
            [39.25958154404505, 51.717837310456225],
            [39.26054713929041, 51.71714424078916],
            [39.26101920807701, 51.715464835395395],
            [39.26111689668507, 51.714279589050996],
            [39.260666285570565, 51.71401300397874],
            [39.260666285570565, 51.713533146868436],
            [39.26118126970143, 51.71321323928514],
            [39.26154605012746, 51.711373726542064],
            [39.26191083055348, 51.710027368831064],
            [39.2617177115044, 51.70960079164821],
            [39.26103106599659, 51.709427493512635],
            [39.25991526704639, 51.70949414672068],
            [39.25864926439137, 51.70937417087513],
            [39.256975565966066, 51.70950747735044],
            [39.255580817278336, 51.70936084020587],
            [39.25334921937793, 51.70928085610743],
            [39.25304881196828, 51.709147548960814],
            [39.25412169557421, 51.70882761019777],
            [39.26195374589772, 51.70901424141839],
            [39.26289788347095, 51.70817439482734],
            [39.26324120622487, 51.70821438787664],
            [39.263498698290284, 51.708587654631884],
            [39.265086566027094, 51.708934256703934],
            [39.26654568773122, 51.71038729018291],
            [39.26680317979663, 51.710827190149175],
            [39.26705798965309, 51.71070555157257],
            [39.267229651030036, 51.71019566574602],
            [39.26814160209511, 51.70975242696662],
            [39.268914078291395, 51.70964578239843],
            [39.26913938384865, 51.70946581911655],
            [39.26947197776652, 51.70880594759146],
            [39.270067428167835, 51.70834603141328],
            [39.27023372512675, 51.7080427507812],
            [39.270255182798884, 51.7078627810888],
            [39.27013716560223, 51.70771613858509],
            [39.27014253002025, 51.707526169176674],
            [39.270308826979225, 51.7072562112686],
            [39.270260547216964, 51.70715289361642],
            [39.27049658161025, 51.70698958458564],
            [39.27006206374986, 51.70657964297932],
            [39.269493435438704, 51.70634967410187],
            [39.26919839244705, 51.70636300566308],
            [39.26884922755381, 51.70648058576019],
            [39.26865610850476, 51.70649058440239],
            [39.268355701095075, 51.7063539361001],
            [39.267915818816626, 51.70638726499258],
            [39.267867539054365, 51.70647725287896],
            [39.267728064185604, 51.70655057620892],
            [39.26727745307108, 51.70651724743739],
            [39.26649424803874, 51.706717219696365],
            [39.2653355337443, 51.70746377494608],
            [39.265217516547644, 51.707493770210725],
            [39.264799091941335, 51.70747044056217],
            [39.26423046363018, 51.70760708547453],
            [39.26332387698313, 51.70763041505131],
            [39.26202568781993, 51.70683053691447],
            [39.26183793318888, 51.70653724470141],
            [39.26191272736019, 51.70637570814789],
            [39.26152648926203, 51.70567246310243],
            [39.2617732524914, 51.70527250841092],
            [39.26185371876185, 51.7049692070677],
            [39.26225068569605, 51.704692567795654],
            [39.262851500515396, 51.70420594519277],
            [39.26321091652339, 51.703812643628225],
            [39.26397802830165, 51.70333934396892],
            [39.264246249203175, 51.702922702825184],
            [39.26436434278988, 51.702466922190375],
            [39.26448772440457, 51.70186694609355],
            [39.26440725813412, 51.70141029226101],
            [39.26424364338286, 51.7010006026582],
            [39.26328877697355, 51.70008393942838],
            [39.26245729217898, 51.699677268308136],
            [39.26161507854835, 51.69942393034301],
            [39.26054755936041, 51.69923392593425],
            [39.26000038872139, 51.699207258584636],
            [39.25905088673011, 51.69917059095313],
            [39.25838569889443, 51.699033920427105],
            [39.25776879082098, 51.69869057556688],
            [39.25686488638298, 51.69804721358703],
          ],
        ],
      },
      properties: {
        description: '2',
        fill: '#82cdff',
        'fill-opacity': 0.6,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 22,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.26887994192482, 51.744657069698455],
            [39.26859830997829, 51.74382288360959],
            [39.269547811969574, 51.74358311468421],
            [39.269392243846674, 51.73956346460382],
            [39.27037661455508, 51.73957345589555],
            [39.270400754436274, 51.739793263731556],
            [39.2706582465017, 51.739779942075025],
            [39.27069043300988, 51.739107193286216],
            [39.27035515688298, 51.73910719328791],
            [39.270363203510065, 51.73939194709816],
            [39.2693868794286, 51.739391947098184],
            [39.2690137202723, 51.73704946310176],
            [39.26881020449041, 51.735846550331395],
            [39.268794111236275, 51.734938923779815],
            [39.26871632717479, 51.734152854418106],
            [39.26909183643697, 51.73413453483761],
            [39.26905160330157, 51.733934684426266],
            [39.268630496486246, 51.73381810460602],
            [39.26844871816721, 51.73261700012149],
            [39.26840580282297, 51.73188085519001],
            [39.26587379751286, 51.72896280468126],
            [39.26665163812719, 51.72885287441896],
            [39.26744020757753, 51.72950412401904],
            [39.26842189607697, 51.73046848909324],
            [39.26903880415039, 51.730838240167266],
            [39.269103177166755, 51.7311946639986],
            [39.26952696619102, 51.73158272972569],
            [39.27006877241207, 51.73131791270524],
            [39.27101827440335, 51.730568422118814],
            [39.271597631550534, 51.72969899738933],
            [39.29880713908335, 51.73359964746991],
            [39.29630075985528, 51.723602181215],
            [39.29367829419491, 51.71333964249842],
            [39.29164161983296, 51.70601115724035],
            [39.290162268952365, 51.70215854309238],
            [39.27735203869724, 51.702771847926556],
            [39.27328925130568, 51.683644612474716],
            [39.27813208235277, 51.68248373046455],
            [39.28700751198309, 51.68592496591905],
            [39.303198303802105, 51.69221163899956],
            [39.311019625289376, 51.726802949716316],
            [39.336752738578184, 51.72373131619285],
            [39.36723336182333, 51.71070952529945],
            [39.36699196301199, 51.72564694740547],
            [39.36680957279899, 51.72872511257369],
            [39.37490984402396, 51.73121680625876],
            [39.378965344054464, 51.74120884289362],
            [39.38302084408504, 51.744459106733316],
            [39.38931867085195, 51.74712308214593],
            [39.3875698705742, 51.74914759785375],
            [39.3871968509775, 51.75133504544994],
            [39.38422084380361, 51.75295579691125],
            [39.37497589174565, 51.75201227781179],
            [39.375049299420375, 51.76248494620311],
            [39.36528778486684, 51.762279443412055],
            [39.36427002520013, 51.74851663580893],
            [39.362092071479985, 51.7486298477561],
            [39.35931330294053, 51.74847667858334],
            [39.34901362032341, 51.74609249982124],
            [39.34650307268548, 51.74914262770526],
            [39.34457188219471, 51.75204605056446],
            [39.33731918901844, 51.75287176041665],
            [39.33047419161245, 51.75431005747178],
            [39.32478790850086, 51.75661390032686],
            [39.32026033968373, 51.75446986541352],
            [39.32023569873057, 51.75321136248643],
            [39.32302837938703, 51.75095395939338],
            [39.320582204765444, 51.74815701950278],
            [39.320346170372076, 51.74574618400915],
            [39.320002847618184, 51.74526666539763],
            [39.3182218608323, 51.744667259938716],
            [39.317899995750516, 51.74313540966973],
            [39.31652670473489, 51.742496013244576],
            [39.31043272585301, 51.74197649695356],
            [39.30416708559421, 51.74185660772647],
            [39.29509049028777, 51.74093744635629],
            [39.29202204317474, 51.740164803418644],
            [39.28903942675018, 51.73967190357138],
            [39.28236609072114, 51.73980512027927],
            [39.282730871147166, 51.74171007601534],
            [39.27783852190399, 51.742003138963405],
            [39.277924352592436, 51.74241608805707],
            [39.27625065416713, 51.74276242953224],
            [39.27391176790614, 51.7435616689053],
            [39.27155142397303, 51.74417440946686],
            [39.26887994192482, 51.744657069698455],
          ],
        ],
      },
      properties: {
        description: '1',
        fill: '#ed4543',
        'fill-opacity': 0.5,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 23,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.19118335916541, 51.68393517680808],
            [39.19033305810481, 51.68392021325976],
            [39.19064419435098, 51.683166598312994],
            [39.19025795625275, 51.68306655998264],
            [39.1900648372038, 51.6833333283694],
            [39.18820874856542, 51.68307322921145],
            [39.18797271417165, 51.68352006530023],
            [39.18737189935287, 51.683446704455356],
            [39.18742554353318, 51.68333332836826],
            [39.184410740600214, 51.683146590663746],
            [39.1841747062071, 51.68516064934023],
            [39.18403523133788, 51.68636770757093],
            [39.1805269019464, 51.687287984377654],
            [39.17980270551241, 51.68762808191623],
            [39.17996632026229, 51.69103391709494],
            [39.175883998141856, 51.69157569287379],
            [39.17582767175238, 51.69225582062469],
            [39.17528050111358, 51.69221414642625],
            [39.17548434899869, 51.690768861335904],
            [39.164801110492526, 51.691535685036925],
            [39.164994270343925, 51.68980360321116],
            [39.162966520328546, 51.6897169161119],
            [39.159862789164286, 51.689563585813175],
            [39.15947845225544, 51.68840801498579],
            [39.15769561493814, 51.68833447560626],
            [39.15762104784149, 51.688872719597256],
            [39.15432380661391, 51.688707053025695],
            [39.15440628454143, 51.68815276642708],
            [39.1534229196612, 51.68816071628669],
            [39.15333688588134, 51.68932988959533],
            [39.151274277410046, 51.68902363751695],
            [39.15116222396059, 51.689181004607704],
            [39.14620863383654, 51.68802925658078],
            [39.146991913570304, 51.68720518532675],
            [39.14685840535948, 51.687153838073954],
            [39.14472665143306, 51.6884645382219],
            [39.140825043714216, 51.68707565176354],
            [39.137461006513135, 51.686473386568174],
            [39.13290775250662, 51.685429856601324],
            [39.132542950194356, 51.68608799943272],
            [39.12977354749941, 51.68540569551511],
            [39.12862927011648, 51.68686351118255],
            [39.12978027305976, 51.68728428820607],
            [39.12939108466369, 51.688014622362594],
            [39.12627586969834, 51.68723821649497],
            [39.12421577198768, 51.68789030892703],
            [39.12442953364562, 51.68813632992813],
            [39.12311960440905, 51.68856757686315],
            [39.13410396744926, 51.70141393432294],
            [39.14029375132057, 51.71111131117421],
            [39.141156212412554, 51.710591756076916],
            [39.14024278401232, 51.708679464170906],
            [39.143227892217105, 51.70649777632443],
            [39.14201692709224, 51.706473704407045],
            [39.1420140880311, 51.70623975180008],
            [39.14015994147693, 51.70612679896774],
            [39.140280417155466, 51.70528179755463],
            [39.14212707960098, 51.7052549308311],
            [39.142246398546995, 51.70365800306246],
            [39.14522071688747, 51.703707377352785],
            [39.14533177710436, 51.70294244627256],
            [39.14689231983715, 51.70301019354087],
            [39.146818894365026, 51.70369077449769],
            [39.14809093198945, 51.703746752722274],
            [39.14836988172751, 51.70121552722619],
            [39.16354570655107, 51.70186370618871],
            [39.16306011293653, 51.70612746573207],
            [39.160443390476296, 51.70602501796989],
            [39.16007251386938, 51.70900573573071],
            [39.157944335224755, 51.70890530688681],
            [39.157984876778194, 51.708673177339776],
            [39.15655051179151, 51.70862775786888],
            [39.15632167167116, 51.71052995421372],
            [39.15476627630657, 51.71046213480306],
            [39.15495971188836, 51.708661833036984],
            [39.15524500975904, 51.70576270952575],
            [39.15035856280818, 51.705562621840045],
            [39.148697376378564, 51.705533294112286],
            [39.14816790524875, 51.705736623417714],
            [39.147950055684994, 51.70589394178001],
            [39.14791191412436, 51.7064528738014],
            [39.14516736333574, 51.70636066392287],
            [39.14515658360218, 51.70662108915296],
            [39.145313481103116, 51.70689952318107],
            [39.14513561078696, 51.708886133866],
            [39.146833698035145, 51.709255733161044],
            [39.1466633470981, 51.71103850453959],
            [39.14561779664453, 51.71101538500844],
            [39.145109113607205, 51.710564864287406],
            [39.144506060405334, 51.71054695167115],
            [39.144877028754074, 51.712226957183354],
            [39.141571348085236, 51.7131112988831],
            [39.142743815163534, 51.714954917463594],
            [39.122897169522865, 51.72267020602734],
            [39.121536308413376, 51.72221221453527],
            [39.11846891109478, 51.72130933646621],
            [39.116282328128705, 51.720942890915765],
            [39.11414076009544, 51.7211695575433],
            [39.11312662522006, 51.71896760479951],
            [39.111256078653405, 51.713263767010616],
            [39.10972516443971, 51.71098602847239],
            [39.10715965281961, 51.70837218949753],
            [39.10115914932609, 51.70381505588839],
            [39.1011206139576, 51.70327300185066],
            [39.10061569672847, 51.70279967874784],
            [39.10261886504149, 51.70227133525327],
            [39.10422125859236, 51.70112296461316],
            [39.10477596893905, 51.69997949364538],
            [39.10383591594271, 51.696148179457644],
            [39.10047574617844, 51.69535836315671],
            [39.09482113055072, 51.69564506187928],
            [39.08995144672149, 51.69686499907058],
            [39.089016998032754, 51.695613099296],
            [39.11617872915614, 51.68473170560903],
            [39.135617894697646, 51.678234616573924],
            [39.135954325683194, 51.67860208408156],
            [39.13551138870434, 51.6787475718017],
            [39.136210700274816, 51.679669627851325],
            [39.1357666458225, 51.679785247004695],
            [39.13607791399469, 51.68013524337705],
            [39.13386894907699, 51.680862173220106],
            [39.136459335969704, 51.68294597899156],
            [39.13855226778249, 51.683073597469196],
            [39.140873369358296, 51.6842291650738],
            [39.14144786994644, 51.68495044820253],
            [39.14494286812997, 51.686885241593416],
            [39.14553572386637, 51.68692207341237],
            [39.15143407657898, 51.68555074629162],
            [39.15411395356354, 51.6857590780287],
            [39.1539765201556, 51.68674430265202],
            [39.16346589536242, 51.68714067086126],
            [39.163771183032885, 51.68533566621696],
            [39.16520884706495, 51.68540235510205],
            [39.1652732200815, 51.68448203976502],
            [39.16140011026319, 51.68456873693966],
            [39.16175416185379, 51.68378845636154],
            [39.164157421131655, 51.681414186164666],
            [39.161893636722375, 51.680613842166636],
            [39.161968738575204, 51.680367066566234],
            [39.162794858951194, 51.68044710204418],
            [39.16288068963987, 51.68066052929033],
            [39.1630952663609, 51.68058716378865],
            [39.16327765657405, 51.680166977248305],
            [39.163052351016816, 51.67980681423784],
            [39.16508010103199, 51.679333262193005],
            [39.16742971612948, 51.678379473247084],
            [39.16819146348959, 51.677378973632514],
            [39.16874936296449, 51.677378973632514],
            [39.17027285768485, 51.67628506862805],
            [39.17583039476361, 51.67953335521061],
            [39.17605570032186, 51.678993102015845],
            [39.17587331010889, 51.6781727051453],
            [39.17647412492718, 51.67757907533037],
            [39.17514374925618, 51.676885385635764],
            [39.17535832597759, 51.67590486371489],
            [39.17683890535364, 51.675998247674165],
            [39.17962840272882, 51.67655854736659],
            [39.181205541629645, 51.67719221123958],
            [39.18253591730108, 51.67902645116317],
            [39.184510023135914, 51.67905313046585],
            [39.18441346361149, 51.680473780497756],
            [39.18562582208635, 51.68064052052188],
            [39.18545416070904, 51.68152089761653],
            [39.18654850198783, 51.68162760882246],
            [39.186387569446424, 51.681961079710355],
            [39.18672016336455, 51.682047781737246],
            [39.186859638233386, 51.681740989200726],
            [39.18777158929818, 51.681954410316905],
            [39.187911064166684, 51.681760997473205],
            [39.189638406772715, 51.68216783042306],
            [39.19019630624781, 51.682221185290246],
            [39.18988517000195, 51.68278140759203],
            [39.19085076524737, 51.683008162255526],
            [39.19133356287007, 51.68310153149196],
            [39.19118335916541, 51.68393517680808],
          ],
        ],
      },
      properties: {
        description: '18',
        fill: '#56db40',
        'fill-opacity': 0.5,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
    {
      type: 'Feature',
      id: 24,
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.59418621083494, 51.887578110005336],
            [39.59313586129251, 51.886091054324105],
            [39.587878731623334, 51.88711346845481],
            [39.58405926598613, 51.88702052268078],
            [39.58371594323223, 51.886184002028195],
            [39.579338578119916, 51.88711346845402],
            [39.57959607018534, 51.89011418532814],
            [39.57787945641582, 51.890645264684956],
            [39.577514675989796, 51.89172068106336],
            [39.57481100930279, 51.891747234226976],
            [39.57470372094218, 51.89202604149266],
            [39.572858361139936, 51.8919065528774],
            [39.57296564950054, 51.89116306098493],
            [39.57315876854964, 51.8905390493176],
            [39.575111416712495, 51.89011418532709],
            [39.575295859190106, 51.88733753145594],
            [39.57349341473209, 51.88727114196977],
            [39.57321446499454, 51.88672674446953],
            [39.5700172718488, 51.887324253566184],
            [39.5693520840131, 51.885345804009596],
            [39.57433026394477, 51.88415072496063],
            [39.57445900997748, 51.8811628876182],
            [39.571809719578624, 51.88155661583915],
            [39.57043642856305, 51.87890061623316],
            [39.57708830691998, 51.87739990672778],
            [39.57807535983745, 51.879299026228324],
            [39.57957241311336, 51.87894698255876],
            [39.57952949776912, 51.8748962837039],
            [39.5814606882599, 51.874617369628425],
            [39.58457205071717, 51.873289183515844],
            [39.58259794488221, 51.86942393760232],
            [39.58360645547181, 51.869158272698826],
            [39.584936831143196, 51.87109759022443],
            [39.58553764596253, 51.87127026510686],
            [39.586760733273316, 51.870659258683794],
            [39.58643886819153, 51.87027405469405],
            [39.58798382058412, 51.869357521524314],
            [39.588477347042854, 51.869689600928496],
            [39.59004737284199, 51.86887089861328],
            [39.59408141520038, 51.86665252084985],
            [39.59367371943012, 51.86537723594761],
            [39.59811545755878, 51.8640354907258],
            [39.60038997080339, 51.8686317973154],
            [39.593823923134984, 51.87043830880939],
            [39.591527952218236, 51.87155405888533],
            [39.59727805574485, 51.88340370182574],
            [39.59741753061361, 51.88479632106928],
            [39.59619980772091, 51.8859200120146],
            [39.596417808735296, 51.88622873161228],
            [39.59691669961206, 51.88694906905615],
            [39.59418621083494, 51.887578110005336],
          ],
        ],
      },
      properties: {
        description: '1',
        fill: '#ed4543',
        'fill-opacity': 0.5,
        stroke: '#ed4543',
        'stroke-width': '3',
        'stroke-opacity': 0.9,
      },
    },
  ],
}

export default areas
