import { Area } from '../types'

const areas: { [l: string]: { [s: string]: Area } } = {
  city: {
    1: {
      people: 34111,
      news: [
        {
          title: 'Утопающие в мусоре избиратели Ходырева ничего не знают о своем депутате',
          url: 'https://bloknot-voronezh.ru/news/utopayushchie-v-musore-izbirateli-khodyreva-nicheg',
        },
      ],
      streets: [
        'Улицы: Белгородская, Беломорская, Богатырская, Богучарская, Боровская, Восточная, Деповская, Диспетчерская, Калининградская нечетные с № 21 по № 137, четные с № 38 по № 108',
        'Коммунальная, Кубанская, Кузнечная, Лермонтова нечетные с № 15 по № 49, четные с № 12 по № 34',
        'Литейная, Машинистов, Механическая, Минская нечетная с № 43 (БУЗ ВО «ВГКБ СМП № 10») по № 83',
        'Паровозная нечетные с № 47 по № 61, четные с № 64 по № 76',
        'Пионерская, Планетная, Розы Люксембург нечетные с № 87 по № 121',
        'Салтыкова-Щедрина, Северная, Солидарности, Столярная, Тепловозная, Токарная, Черноморская, Электровозная, Южная, 19 стрелковой дивизии',
        'Переулки: Венский, Деповский, Еловый, Малярный.',
        'Проезд Богучарский.',
        'Микрорайоны: Сомово, Краснолесный.',
      ],
    },
    2: {
      people: 35482,
      news: [
        {
          title: 'Избиратели проклинают единоросса Пинигина за бездействие в убитом округе №2',
          url: 'https://bloknot-voronezh.ru/news/izbirateli-proklinayut-edinorossa-pinigina-za-bezd',
        },
      ],
      streets: [
        'Улицы: 25 Января дома №№ 70, 72',
        'Ленинский проспект нечетные с № 139 по 227, № 162',
        'Анри Барбюса, Артамонова, Артема, Архитектора Троицкого, Богдана Хмельницкого, Братская, Вагонная, Витрука, Гоголевская, Грибоедова, Добролюбова, Добрый путь, Землячки, Зои Космодемьянской, Калининградская нечетные с № 3 по № 15, четные с № 2 по № 32',
        'Клары Цеткин, Колхозный путь, Красина, Куйбышева, Лермонтова №№ 6, 8, 8а',
        'Листопадная, Локомотивная, Луговая, Малиновского, Маршала Одинцова, Молодежная, Моховая, Нариманова, Новый Быт, Остужева №№ 5, 7, 11, 13, 15, 17',
        'Переверткина четные с № 26 по № 60, нечетные с № 43 по № 51',
        'Панфилова, Паровозная нечетные с № 1 по № 39, четные с № 2 по № 42',
        'Пугачева, Розы Люксембург нечетные с № 1 по № 79, №№ 6, 6а',
        'Рокоссовского, Сенная, Суворова, Тургенева, Халтурина, Юности.',
        'Переулки: Альпийский, Андрюши Санникова, Артема, Береговой, Богдана Хмельницкого, Гоголя, Диспетчерский, Добролюбова, Зои Космодемьянской, Инютинский, Кольцевой, Молодежный, Нариманова, Пионерский, Суворовский, Червонный.',
        'Железнодорожная будка 583 км',
      ],
    },
    3: {
      people: 33055,
      news: [
        {
          title: 'Матом и проклятиями отозвались о депутате Олеге Черкасове избиратели его округа',
          url: 'https://bloknot-voronezh.ru/news/matom-i-proklyatiyami-otozvalis-o-deputate-olege-ch',
        },
      ],
      streets: [
        'Улицы: 25 Января четные с № 2 по № 52а',
        'Весенняя, Гаршина, Гончаровская, Димитрова №№ 5, 7, 27, 49',
        'Жуковского, Запорожская, Ильича нечетные с № 1 по № 15, четные с № 2 по № 38, Комсомольская, Котовского, Красная Поляна, Красногвардейца Зубрилова, Ленинградская дом № 1а',
        'Ленинский просп. нечетные с № 119а по № 137, четные с № 124 по № 154, Лизы Чайкиной, Минская №№ 2, 2в, нечетные с № 1 по 35, Набережная, Нахимовская, Остужева №№ 1, 1а, 3, 3а, 5а, четные с № 6 по № 50, Переверткина четные с № 2 по № 24а, нечетные с № 11 по № 41, Перекопская, Серафимовича, Спартаковская, Старых Большевиков №№ 2, 14, 16, 18, 20/1, 20/2, четные с № 28а по № 54а, Тракторная, Ушакова, Филатова, Фурмановская.',
        'Переулки: Запорожский, Колхозный, Морской, Нахимовский, Серафимовича.',
      ],
    },
    4: {
      people: 33771,
      news: [
        {
          title: 'В наплевательском отношении к решению проблем обвинили избиратели Николая Образцова',
          url: 'https://bloknot-voronezh.ru/news/v-naplevatelskom-otnoshenii-k-resheniyu-problem-ob',
        },
      ],
      streets: [
        'Улицы: 17 сентября, Абрикосовая, Алданская, Багратиона, Баррикадная нечетные с № 5 по № 13',
        'Безоблачная, Васильевская, Витебская, Волгоградская (кроме домов №№ 47, 49, 51), Воробьевская, Героев Хасана, Гомельская, Гринько, Давыдовская, Дальневосточная, Димитрова нечетные с № 75 по № 157, четные с № 102 по № 144',
        'Дунайская, Егоровская, Живописная, Жигулина, Зеленая, Знаний, Ивановская, Измайловская, Иркутская (кроме домов №№ 9а и 15а), Калачеевская, Клинская, Композитора Алябьева, Кряжовская, Куликовская, Лапшова, Лосевская, Малиновая, Минская № 14, Новохоперская, Обручева, Окружная, Омская, Оскольская, Писательницы Кретовой, Полтавская, Пшеничная, Пятилетка, Репненская, Ржевская, Рижская, Розовая, Рыбацкая, Севастопольская, Солженицына, Сосновка, Софьинская, Союзная, Тверская, Тиханкина, Туполева №№ 1, 2а, 3, 3а, 3б, 3в, 5, 5а, 7, 8, 9, 10, 11, 11а, 11б, 12, 13б, 15, 16, 20',
        'Туровская, Тюльпанная, Уездная, Уразовская, Урывского, Черенкова, Черниговская, Юбилейная, Яблоневая.',
        'Переулки: Абрикосовый, Бродского, Брусилова, Витебский, Волочаевский, Гимназический, Гранитный, Грушовый, Димитрова, Доблести, Ивановский, Измайловский, Клеверный, Корниловский, Красивый, Лавровый, Лиловый, Магазинный, Международный №№ 50, 52, 54',
        'Монтажный, Павловский, Первомайский, Полевой, Полтавский, Природный, Путевой, Пчелиный, Речной, Россошанский, Садовый, Самоцветный, Севастопольский, Таловский, Томский, Уточкина, Флотский, Чаплыгина, Челябинский, Ярослава Белова.',
        'Территории: ДП СДТ «Нефтянник-2», НСТ «Дружба» (ул. Тверская), Питомник «Озерки», Площадка 587 км (Путевой пер.), СНТ «Никольское».',
      ],
    },
    5: {
      people: 32509,
      news: [
        {
          title: 'Прославившийся коррупционным скандалом депутат Кочетов остался безымянным для воронежцев',
          url: 'https://bloknot-voronezh.ru/news/proslavivshiysya-korruptsionnym-skandalom-deputat--1202547',
        },
      ],
      streets: [
        'Улицы: Арзамасская, Брусилова, Димитрова четные с № 2 по № 90',
        'Ильича нечетные с № 53 по № 75, четные с № 126 по № 150',
        'Красногвардейская, Круглая, Ленинградская нечетные с № 3 по № 27б, четные с № 4а по № 112а',
        'Ленинский пр-т нечетные с № 43 по № 117а, четные с № 28/1 по № 116',
        'МОПРа, Нижняя, Огарева, Озерная, Осоавиахима, Порт-Артурская, Саперная, Серова, Спортивная наб., Старых Большевиков четные с № 90 по № 102',
        'Тельмана, Тихая, Щорса (кроме №№ 105, 107, 164, 166).',
        'Переулки: Бобровский, Гвардейский, Гражданский, Заречный, Кавказский, Казанский, Крамского, Международный нечетные с № 1 по № 43, четные с № 2 по № 44',
        'Мостостроителей, Набережный, Ольховатский, Ольховый (дома нечетной стороны), Парашютистов, Планерный, Поленова, Репина, Сквозной, Тельмана, Усманский, Хоперский.',
      ],
    },
    6: {
      people: 31862,
      news: [
        {
          title: 'В равнодушии к реальным делам воронежцы обвинили таинственного депутата Ковалева',
          url: 'https://bloknot-voronezh.ru/news/v-ravnodushii-k-realnym-delam-voronezhtsy-obvinili-1202968',
        },
      ],
      streets: [
        'Улицы: Авиастроителей наб. (кроме дома № 4), Айвазовского, Баррикадная нечетные с № 19 по № 41, четные с № 26 по № 38а;',
        'Беляевой, Владимира Высоцкого, Волгоградская №№ 47, 49, 51;',
        'Ермака, Иркутская дома №№ 9а, 15а;',
        'Красный Октябрь, Ленинградская №№ 55, 55а, четные с № 114 по № 136б;',
        'Ленинский пр-т нечетные с № 13 по № 39, четные с № 20/1 по № 26/3;',
        'Осипенко Полины, Писарева, Ползунова, Славянская, Туполева дома №№ 13, 15а, 15б, 17, 19, 21, 22, 23, 24, 25, 27, 28, 29, 29а, 31, 31б, 33, 35, 37а, 38, 38б, 39, 40, 41, 43, 48, 50, 71, 72, 73, 75;',
        'Уральская, Циолковского, Черепанова, Щорса №№ 105, 107, 164, 166.',
        'Переулки: Ольховый (дома четной стороны), Отличников, Сторожевой',
        'Территория СМП-863 (пер. Отличников, 6).',
      ],
    },
    7: {
      people: 35621,
      news: [
        {
          title: 'Быть ближе к народу призвали воронежцы анонимного депутата Владимира Калинина',
          url: 'https://bloknot-voronezh.ru/news/byt-blizhe-k-narodu-prizvali-voronezhtsy-anonimnog',
        },
      ],
      streets: [
        'Улицы: Авиастроителей наб. № 4, Азовская, Балашовская, Барнаульская, Варшавская, Венецианова, Волго-Донская, Волжская, Волкова, Вологодская, Воронихина, Вьетнамская, Героев Стратосферы, Донецкая, Дружбы, Заслонова, Ковалевской, Корейская, Костромская, Кронштадтская, Кулибина, Лебедева, Левитана, Ленинградская №№ 59/7, 140;',
        'Ленинский пр-т четные с № 2/3 по № 18, нечетные с № 3 по № 11;',
        'Ленская, Лихачева, Макаренко, Менделеева, Меркулова, Мечникова, Небольсина, Новикова, Ново-Воронежская, Новороссийская, Новосибирская четные с № 2 по № 80, №№ 9, 11;',
        'Пекинская, Печерская, Просторная, Путилина, Ростовская нечетные с № 1 по № 37, четные с № 4 по 48/2, №№ 50, 50/2;',
        'Саврасова, Сеченова, Танеева, Третьякова, Цимлянская, Читинская, Шахтинская, Шинников, Шубина, Ярославская.',
        'Переулки: Баженова, Балашовский, Заслонова, Ковыльный, Новороссийский, Прохладный, Ростовский, Русанова, Сеченова, Химический, Цимлянский, Шубина. ',
        'Территории: Площадка 596 км (ул. Донецкая), СНТ «Кировец», СНТ «Шинник-1». ',
      ],
    },
    8: {
      people: 32101,
      news: [
        {
          title: 'Блокнот',
          url: 'https://bloknot-voronezh.ru/news/izbirateli-obvinili-deputata-aleksandra-tyurina-v-',
        },
      ],
      streets: [
        'Улицы: 6 Стрелковой Дивизии, 206 Стрелковой Дивизии, Амбулаторная, Ани Максимовой, Ароматная (Луговая), Большая Советская, Бережная, Боевская, Великого Октября, Василия Кубанева, Глинки, Горняков, Доковая, Дубровина, Дубянского, Звездная (Ленина), Земляничная, Земская, Ильская, Каширская, Ковтуна, Колодезная, Кольцевая, Корабельная, Космическая (Космонавтов), Лейтенанта Цветкова, Лесничья (Лесная), Лозовая, Лызлова, Майская, Масловская, Медовая, Межевая (Ново-Воронежская), Мирная (Депутатская), Никольская, Новосибирская нечетные с № 17 по № 61д, № 96;',
        'Новоусманская, Околицкая, Ольховая (Степная), Оранжевая (Садовая), Пахотная, Первоцветная (Первомайская), Петровская, Пилотов, Подклетненская, Подлесная, Подольская, Полякова, Приморская, Проселочная (Железнодорожная), Радостная (Солнечная), Ратная (Лесная), Ровная, Рождественская (Луговая), Ростовская № 48/3, четные с № 50/3по № 86, нечетные с № 53 по № 73а;',
        'Сафронова, Сказочная, Смородиновая (Садовая), Снежная (Тихая), Совхозная, Солдатское поле, Спутниковая (Комарова), Степная, Сухомлинова, Тавровская, Туманная (Свободы), Чебышева, Якорная, Ясеневая.',
        'Переулки: Брусничный, Волшебный, Душистый, Звездный, Карбышева, Колыбельный (Зеленый), Ложбинный, Мирный, Петра Великого (Петра Первого), Подлесный, Привокзальный, Рождественский, Хуторской, Ягодный.',
        'Территории: 62632-Ч, Дома Воронежского гидроузла, ПКС «Оргнефть», Площадка 594 км, Площадка 600 км (ул.Горняков), Площадка 604 км, Площадка 605 км, ПОС «Электрон», СНТ «Березка», СНТ «Березовское», СНТ «Березовское-2», СНТ «Домостроитель-1», СНТ «Дорожник», СНТ «Елочка», СНТ «Заря», СНТ «Иволга», СНТ «Отдых», СНТ «Садовод ВАСО», СНТ «Сельские зори», СНТ «Уголек», СНТ «Шинник-2», СНТ «Южный», СПК «Русское Поле».',
      ],
    },
    9: {
      people: 36818,
      news: [
        {
          title: 'Воронежцы призвали вице-спикера Провоторова перестать кормить их обещаниями',
          url: 'https://bloknot-voronezh.ru/news/voronezhtsy-prizvali-vitse-spikera-provotorova-per-1226218',
        },
      ],
      streets: [
        'Улицы: Героев Сибиряков нечетные с № 23 по № 105, четные с № 24 по № 54',
        'Космонавта Комарова четные с № 2 по № 4, № 7',
        'Краснозвездная №№ 10, 18, 22, 24, 26, 28, 32, 36, 38, 40, 42',
        'Кривошеина №№ 66, 68, 70, 72',
        'Любы Шевцовой №№ 5, 5/1, 9, 11, 13, 15',
        'Молодогвардейцев четные с № 4 по № 22, нечетные с № 7 по № 23',
        'Олеко Дундича нечетные с № 1 по № 23',
        'Писателя Маршака нечетные с № 3 по № 23',
        'Проспект Патриотов №№ 1, 1/б, 1/г, 1/д, 1/е, 2, 2/а, 4, 4/а, 7/б, четные с № 6 по № 18',
        'Чеботарева, Шендрикова №№ 2, 9, 10, 12, 13, 14, 15',
        'Южно-Моравская №№ 5, 9, 11, 13, 15, 15/а, 15/б, 17, 19, 19/а, 21, 40, 42, 44, 46, 50/1, 50/2, 50/3, 50/4, 50/5, 52, 54, 56, 58, 70',
        'Юлюса Янониса.',
      ],
    },
    10: {
      people: 36898,
      news: [
        {
          title: 'Депутата Сергея Оганезова воронежцы обвинили в наплевательстве к жизни окраин',
          url: 'https://bloknot-voronezh.ru/news/deputata-sergeya-oganezova-voronezhtsy-obvinili-v-',
        },
      ],
      streets: [
        'Улицы: 11-я Железнодорожная, 146-го авиаполка (Ягодная), 1-я Железнодорожная, 1-я Садовая, 2-я Железнодорожная, 2-я Садовая,3-я Садовая, 3-я Сельмашевская, 41-го пограничного полка (Набережная), 4-я Садовая, 6-я Линия, 6-я Мехзаводская, 6-я Садовая, 7-я Сельмашевская, 9-я Железнодорожная, Агрономическая, Алма-Атинская, Антоновская (Кооперативная), Аэродромная, Байконурская, Белый Колодец, Берег реки Дон, Березовая аллея, Березовая, Благовещенская (Хохольская), Ближняя, Борзова, Булынина (Полевая), бульвар Воинской славы, в/ч 32891, в/ч 51025, Васильковая, Весенняя, Вешних вод, Виноградная, Вишневая, Владимирская, Волнистая, Вольховского, Восточная, Вяземская, Генерала Перхоровича №№ 1, 1/А, 3, 5, 7, 9, 11;',
        'Дарьинская, Декоративная, Долинная, Дружная, Дубравная, жилая зона Фрунзе-ЖК, Журавлиная, Зеленодольская, Золотистая, Ивовая, Изумрудная, Ильинская, Инженерная, Испытателей, Казачья, Кленовая аллея, Клочкова (Тупиковая), Кольцевая, Конструктора Косберга, Космонавта Комарова нечетные с № 3 по № 5, № 11, № 13 и четные с № 6 по № 16;',
        'Крайняя, Краснозвездная четные с № 2 по № 8, №№ 12, 14, 16;',
        'Кузнецкая, Курчатова, Лазарева, Лазурная, Лаковая, Леонида Утесова, Лесная аллея, Лесная, Липовая, Луговая, Лунная (Полевая), Любы Шевцовой №№ 1, 3, 17, 17а, 17а/1, 19, 21;',
        'Малая Сосновая, Малышевская, Марьинская, Междуреченская, Милосердия, Мостовая, Нижнедевицкая, Обнинская, Октябрьская, Олеко Дундича № 25, Осиновая, Острогожская № 168, Павловского, Панина-Коломенкина, Первая, Пойменная (Набережная), Покорителей космоса, Поселок Тенистый, Привольная, Придорожная, Природная (Новая), Приусадебная, Проспект Патриотов четные с № 20 по № 40, №№ 50, 50а, 50б, 50в, 52, нечетные №№ 23, 31, 31/1, 31/2, 51, 53, 57, 59, 61;',
        'Прохладная, Радужная, Рассветная, Ромашковая, Ромашковый луг, Российская, Рубежная, Рушниковая, Рябиновая (Весенняя), Семаго (Аэродромная), Семеновская, Серебрянского (Белый Колодец), Слободская, Снесарева, Сосновая, Спортивная, Суворина (Кооперативная), Сусанинская, Тенистая, Тепличная, Теплоэнергетиков, Тополиная, Факельная, Федяевского, Хохольская, Цветочная, Центральная, Черненко, Чуйская, Чукотская, Шендрикова №№ 1, 3, 5;',
        'Школьная, Щепкина, Южно-Моравская нечетные с № 25 по № 35, четные с № 60 по № 74;',
        'Яркая, Ясногорская.',
        'Переулки: Алехиных, Барышниковых, Мастеровой, Милицыной, Ново-Садовый, Сомовых, Стременной, Тополиный, Федосеенко.',
        'Улицы садоводческих некоммерческих товариществ: «Ветеран», «Дзержинец-1», «Дон», «Дружба», «Железнодорожник-1», «Железнодорожник-2», «Железнодорожник-6», «Заветы Мичурина», «Калининец», «Коминтерновец-1», «Лазурит-Тепличный», «Лесовод-2», «Мичуринец», «Надежда», «Объединение», «Память Мичурина», «Путь Мичурина», «Радист», «Радиодетали», «Радуга», «Расцвет», «Садовод», «Садовод-любитель», «Садовод-строитель», «Строитель МВО», «Текстильщик», «Тихий Дон», «СНТ «Тихий Дон-2», «Тихий Дон-3», «Тихий Дон-4», «Электросигнал», «Яблочко».',
      ],
    },
    11: {
      people: 35998,
      news: [
        {
          title: 'Маргинальный рассадник поставили в вину депутату Алексею Золотареву воронежцы',
          url: 'https://bloknot-voronezh.ru/news/marginalnyy-rassadnik-postavili-v-vinu-deputatu-al',
        },
      ],
      streets: [
        'Улицы: Антокольского четные с № 2 по № 14;',
        'Бахметьева № 1/А, Богачева, Бульвар Пионеров, Ворошилова четные с № 20 по № 50, нечетные с № 7 по № 17;',
        'Героев Сибиряков четные с № 8 по № 16, нечетные с № 1 по № 19;',
        'Депутатская, Домостроителей нечетные с № 1 по № 79, четные с № 2 по № 14/а, №№ 20, 22;',
        'Дорожная, Изобретателей, Карла Либкнехта нечетные с № 53 по № 57, №№ 74,76;',
        'Конструкторов, Космонавтов №№ 7, 9, 11, 13, 15, 23, четные с № 8 по № 62;',
        'Крымская, Летчика Колесниченко №№ 42, 44, 44/А;',
        'Моисеева четные с № 40 по № 44, №№ 80, 82, 84;',
        'Пеше-Стрелецкая, Пирогова с № 8 по № 89;',
        'Писателя Маршака четные с № 2 по № 30;',
        'Путиловская нечетные №№ 1, 3, 5, 5/а, четные №№ 2/а, 2/б;',
        'Свободы нечетные с № 79 по № 89.',
        'Переулки: Бригадный, Производственный.',
      ],
    },
    12: {
      people: 36450,
      news: [
        {
          title: 'В убитых дворах воронежцы обвинили депутата-единоросса Игоря Костырева',
          url: 'https://bloknot-voronezh.ru/news/v-ubitykh-dvorakh-voronezhtsy-obvinili-deputata-ed-1230975',
        },
      ],
      streets: [
        'Улицы: 232-й Стрелковой Дивизии, 9 января № 241 с дробями, № 243 с дробями, № 245 с дробями;',
        'Автодорожная (Дорожная), Алфавитная (Школьная), Антокольского нечетные с № 1 по № 17;',
        'Апрельская (Полевая), Арбатская (9 Января), Афанасьева, Беляева, Берегового, Березовая, Богатая (Донская), Большая (Ленина), Бульвар Фестивальный, Весны, Взлетная (Нижняя), Генерала Перхоровича №№ 2, 4, 6, 8, 10, 12;',
        'Геофизическая, Германа Титова (Гагарина), Героев Сибиряков №№ 18, 20;',
        'Героев Спорта (Мира), Груздевая (Луговая), Дачная, Евдокима Огнева, Жилая зона 235 км, Заветная (Молодежная), Заполярная, Защитников Родины, Кемеровская, Киселева, Ключевая (Тенистая), Комарова, Красочная (Строителей), Крейзера, Латненская, Любы Шевцовой №№ 25, 27, 29;',
        'Магнитогорская, Мазлумова, Малявина, Митрофановская (Мичурина), Мосина, Мусихина, Надежная (Советская), Небесная (Садовая), Нестерова, Новая, Новоселов, Огаджаняна, Октября, Осенняя, Папова № 10, Персиковая (Южная), Песочная (Песчаная), Петра Праслова (Космонавтов), Почтовая (Гагарина), Пригородная (Заводская), Придонская (п. Подпольное), Приозерная (Свободы), Проточная (Донская), Путейский городок, Путиловская четные с № 2 по № 54 (кроме №№ 2/а, 2/б), №№ 7, 9, 9/а, 11, 13, 13/а, 15, 15/а, 17, 19;',
        'Разъезд 239 км, Родная (Зеленая), Романтиков (Садовая), Росистая (Дачная), Садовое кольцо, Светлая, Северцова, Силикатная, Соловьиная (Сосновая), Счастливая (Школьная), Тихий Дон (Придонская), Цветочная, Центральная (Первомайская), Центральный бульвар, Черемушки (Набережная), Южно-Моравская четные с № 2 по № 38/В;',
        'Ясная (Молодежная).',
        'Переулки: 9 Января, Ани Скоробогатько, Антокольского, Архипова, Беляева, Березки, Верный (Свободы), Дъякова, Заполярный, Земнухова с № 1 по № 35;',
        'Коновкина, Лебединый (Набережный), Луговой, Магнитогорский, Мухиной, Нестерова, Путиловский, Радужный (Лесной), Сергея Тюленина, Смирнова, Сосновый, Ульяны Громовой, Шевцовой.',
        'Улицы садоводческих товариществ: «Придонье», «Придонье-2»',
      ],
    },
    13: {
      people: 33365,
      news: [
        {
          title: 'Разбитыми дворами и грязью воронежцы пристыдили депутата Александра Головина',
          url: 'https://bloknot-voronezh.ru/news/razbitymi-dvorami-i-gryazyu-voronezhtsy-pristydili-1231330',
        },
      ],
      streets: [
        'Улицы: 30-летия Октября, 100-й стрелковой Дивизии, 121-й стрелковой Дивизии (кроме №№ 58, 60), Аксакова, Андреева, Астраханская, Броневая, Верхняя, Возрождения, Волнухина, Врубеля, Газетчиков, Грамши, Госпитальная (кроме четные с № 2 по № 10, нечетные с № 1 по № 5), Гремяченская, Гродненская нечетные с № 41 по № 91, четные с № 48 по № 88;',
        'Есенина, Журналистов, Звенигородская, Иртышская, Калужская, Камская, Карельская, Карла Либкнехта нечетные с № 11 по № 49, четныес № 30 по № 52;',
        'Краснознаменная №№ 71а – в/ч, 153/5-в/ч, 165а – в/ч, 171, 171а, 171б, 231 «ВИГПС МЧС», нечетные с № 103 по № 131, с № 175по № 229, четные с № 92 по № 158, в том числе № 100 и № 106 (в/ч), с № 160 по № 216;',
        'Крылова, Курганская, Летчика Колесниченко нечетные с № 23 по № 33, четные с № 12 по № 38;',
        'Ладожская, Лейтенанта Дзотова, Лишенко, Львовская с № 1 по № 78 (все);',
        'Маленькая, Маршала Неделина, Маршала Шапошникова, Матросова № 4а – в/ч, нечетные с № 1 по № 127, № 135, четные с № 6 по 58а;',
        'Маяковского нечетные с № 1 по № 23, четные с № 2 по № 26;',
        'Молдавская, Направника, Новогодняя, Общественная, Одесская, Одоевского нечетные с № 1 по № 39, четные с № 2 по № 52;',
        'Онежская, Орловская, Островского, Острогожская с № 1 по № 95 (все), четные c № 96 по № 142;',
        'Петрозаводская, Праздничная, Профессора Харина (Свободная) с № 16 по № 40 (все);',
        'Римского-Корсакова, Серпуховская, Средняя, Степана Солодовникова (кроме № 2), Тушинская, Ульяновская, Успенского с № 1 по № 52 (все);',
        'Уфимская, Херсонская, Чапаева нечетные с № 1 по № 167, №№ 56а – в/ч, 110, 120, 122, 122а, 124, 124а, 124б, 130а, четные с № 112по № 118, №№ 126, 128,130, 132, с № 160 по № 194;',
        'Черновицкая с № 41 по № 59 (все);',
        'Черняховского, Чехова, Чижовская, Шиловская.',
        'Переулки: 30-летия Октября, Аксакова, Астраханский, Байкальский, Благодатный, Виноградова, Вишневый, Возрождения, Госпитальный, Грибной, Даргомыжского, Днепровский, Карельский, Каховского с № 46 по № 70 (все);',
        'Каштановый, Керченский, Кирпичный, Кленовый, Коллективизации, Короткий, Корпусный, Краснознаменный, Кривоколенный, Ленский, Летчиков, Лиственный, Люлина, Малый, Можайский, Молдавский, Новый, Одесский, Орловский, Пестеля, Прямой, Раздольный, Рябиновый, Свободный, Сивашский, Симферопольский, Стрелковый, Туркменский, Херсонский, Чапаева, Ялтинский. ',
        'Проезды: 30-летия Октября, Иртышский, Ладожский, Летний, Маяковского, Лучевой, Острогожский, Туркменский, Хвойный, Яблочный, Ялтинский. ',
        'Тупики: Голубиный, Охотничий, Рыбачий, Соколиный, Уфимский.',
      ],
    },
    14: {
      people: 32177,
      news: [
        {
          title: 'За убитый центр Воронежа избиратели нецензурно отозвались о депутате Сергее Колиухе',
          url: 'https://bloknot-voronezh.ru/news/za-ubityy-tsentr-voronezha-izbirateli-netsenzurno--1233311',
        },
      ],
      streets: [
        'Улицы: 5 Декабря, 20-летия Октября (кроме №№ 90, 92 и 94), 121-й стрелковой Дивизии №№ 58, 60;',
        'Артиллерийская, Артековская, Бархатный Бугор, Белостокская, Бестужева, Бетховена, Веры Фигнер, Волоколамская, Ворошилова №№ 43, 45, 45б, 47, 49, 49а, 55, нечетные с № 21 по № 41, в т.ч. № 27 (в/ч);',
        'Выборгская (кроме № 1), Госпитальная четные с № 2 по № 10, нечетные с № 1 по № 5;',
        'Гродненская № 1, нечетные с № 9 по № 39, четные с № 2 по № 46;',
        'Державина, Енисеевская, Карамзина, Карла Либкнехта нечетные с № 1 по № 9, четные с № 2 по № 28, №№ 51, 72;',
        'Кирова №№ 8, 10, 22, 24;',
        'Клубная, Конноармейская, Конно-Стрелецкая, Коперника, Красная горка, Красноармейская нечетные с № 1 по № 17, четные с № 2 по № 38, №№ 56, 60;',
        'Красных партизан, Краснознаменная нечетные № 11, с № 15 по № 97 (кроме 71а – в/ч), с № 133 по 167 (кроме 153/5-в/ч, 165а – в/ч), четные с № 16 по № 88;',
        'Красноярская, Кривошеина № 1, № 1а, № 9, нечетные с № 17 по № 23, четные с № 2 по № 62;',
        'Крупской, Кубанская гора, Куцыгина №№ 10, 12, 18, 19, 21, 27, 29а;',
        'Летчика Колесниченко нечетные с № 1 по № 21, с № 51 по № 57, № 65, № 67, четные с № 2 по № 10, № 40 и № 50;',
        'Лескова, Летчика Щербакова с № 33 по № 72а (все);',
        'Льва Толстого, Львовская с № 79 по № 90 (все);',
        'Майкова, Мало-Красноармейская, Мало-Стрелецкая, Марата, Матросова нечетные с № 137 по № 207, четные с № 64 по № 102а, № 102а - в/ч;',
        'Маяковского, Моисеева №№ 69, 71, 73, 75;',
        'Народных ополченцев, Некрасова, Новаторов, Одоевского нечетные с № 41 по № 79, четные с № 54 по № 94;',
        'Песчаная гора, Петра Сазонова, Профессора Харина (Свободная) с № 2 по 15б (все);',
        'Пушкарская, Пушкинская №№ 28, 35, 36, 36а, 41, 42, 45;',
        'Ремесленная гора, Саратовская, Свободы №№ 22, 24, 45, 55, 59;',
        'Сергея Лазо, Серго, Скрибиса, Станкевича нечетные с № 17 по № 37а, четные с № 14 по № 40;',
        'Степана Панкова, Степана Солодовникова № 2, Танкистов (переулок), Ударная, Успенского с № 53 по № 94 (все);',
        'Фридриха Энгельса нечетные с № 59 по № 91, № 74;',
        'Чапаева четные с № 2 по № 80 (кроме № 56а – в/ч);',
        'Челюскинцев (кроме №№ 136, 136а и 149), Черновицкая с № 1 по № 40 (все);',
        'Чернышевский Бугор, Энтузиастов.',
        'Переулки: Алтайский, Ангарский, Бакинский, Балтийский, Бессарабский, Бестужева, Веры Фигнер, Глинки, Городской, Енисейский, Жасминный, Заозерный, Казарменный, Карпатский, Каховского, Кедровый, Кишиневский, Кондратия Булавина, Конно-Стрелецкий, Корабельный, Красных партизан, Лескова, Минина, Муравьева, Мурманский, Нансена, Невский, Ореховый, Передовой, Поворотный, Пожарского, Полярный, Пролетарский, Пушкарский, Снайперский, Цветной, Челюскинцев, Штурмовой. ',
        'Тупик Балтийский.',
      ],
    },
    15: {
      people: 31164,
      news: [
        {
          title: 'Депутата Александра Жукова воронежцы пристыдили депрессивными кварталами',
          url: 'https://bloknot-voronezh.ru/news/deputata-aleksandra-zhukova-voronezhtsy-pristydili-1233566',
        },
      ],
      streets: [
        'Улицы: III Интернационала №№ 35, 38, 41, 42, 44, 45, 55, 57, 59;',
        '9 Января №№ 30, 32, 34, 35, 39, 47, четные с № 42 по № 54, № 41, нечетные с № 49по № 69;',
        '20-летия Октября №№ 90, 92 и 94;',
        '40 лет Октября, Базарная гора, Бакунина (Бакунинская), Бахметьева № 8а, Белинского нечетные с № 19 по № 37, четные с № 16 по № 28;',
        'Войкова №№ 6, 15, 17, 19, 37а, 37б, 39, 41, 48, 50, 50а, 58, 60, 60а;',
        'Володарского № 15, № 17, нечетные с № 25 по № 37;',
        'Ворошилова №№ 2, 4, 6, 10, 12;',
        'Выборгская № 1, Гора Металлистов, Девицкий выезд, Донбасская, Кирова №№ 1, 3, 26 и 28;',
        'Кольцовская №№ 33, 37/1, 37/2, 39, 41, 47, 49, 76, 82, четные с № 52 по № 72;',
        'Краснознаменная четные с № 4 по № 14;',
        'Красноармейская №№ 23, 27, 31/1, 33, 33 с дробями,№ 52д, № 54д, четные с № 62 по № 66;',
        'Кропоткина, Куколкина, Куцыгина с № 1 по № 9а (все), № 30, № 35, № 35/1;',
        'Ленина площадь №№ 3, 4, 5, 6;',
        'Летчика Демьянова, Летчика Злобина, Летчика Щербакова с № 1 по № 32б (все);',
        'Моисеева №№ 1, 3, 11, 11а, 13, 13/1, 13/2, 15, 15а, 25, нечетные с № 33 по № 67;',
        'Нарвская, Никитинская нечетные с № 29 по № 49/7, четные с № 36 по № 54;',
        'Орджоникидзе № 36, № 36-б, № 36/5, № 41;',
        'Пирогова нечетныес № 1 по № 13, четные с № 2 по № 6;',
        'Платонова, Плехановская нечетные с № 1 по № 59;',
        'Пограничная, Промышленная, Профсоюзная;',
        'Пушкинская с № 10 по № 22 (все), № 44;',
        'Революции 1905 года №№ 54/5, 62, 66, 80, 80б, нечетные с № 23 по № 51;',
        'Свободы нечетные с № 1 по № 43, четные с № 2 по № 12, с № 28 по № 36, №№ 65, 67, 77а;',
        'Севастьяновский съезд, Софьи Перовской №№ 67, 73, 100, 102, 104, 108;',
        'Станкевича нечетные с № 1 по № 15, четные с № 4 по № 12;',
        'Стрелецкая Большая, Фридриха Энгельса нечетные с № 39 по № 53, №№ 62, 70, 72;',
        'Фрунзе, Шевченко, Челюскинцев № 136, № 136а, № 149.',
        'Переулки: Амурский, Бакунинский, Белинского, Кабельный, Мало-Московский, Ново-Слободской, Печатников, Рабкоровский, Севастьяновский, Слесарный, Специалистов, Старинный, Электронный.',
      ],
    },
    16: {
      people: 33047,
      news: [
        {
          title: 'На предвыборный популизм депутата Кандыбина пожаловались его избиратели в Воронеже',
          url: 'https://bloknot-voronezh.ru/news/na-predvybornyy-populizm-deputata-kandybina-pozhal',
        },
      ],
      streets: [
        'Улицы: 9 Января нечетные с № 81 по № 101, с № 111 по № 217, № 231 с дробями, № 233 с дробями, № 110, № 114, четные с № 122 № 236;',
        'Бородина, Варейкиса №№ 71, 72, 73, 74, 76, 78;',
        'Вольная, Газовая нечетные с № 1 по№ 27 и четные с № 2 по № 20, № 24;',
        'Гайдара нечетные с № 1а по № 15, четные с № 2 по № 54;',
        'Героев Бреста, Динамо с № 1 по № 12;',
        'Еремеева, Жигулевская с № 1 по № 42 (все), нечетные с № 43 по № 95;',
        'Загородная четные с № 6 по № 60, №№ 7а, 15, нечетные с № 21 по № 41, с № 47 по № 63;',
        'Землянская, Ивана Туркенича, Керамическая с № 1 по № 33 (все), №№ 35, 37, четные с № 42 по № 68;',
        'Краснодонская с № 1 по № 15 (все), нечетные с № 17 по № 23а, № 31;',
        'Красных Зорь, Малаховского нечетные с № 1 по № 47, четные с № 2 по № 50, № 50а;',
        'Машиностроителей нечетные с № 9 по № 51, четные с № 18 по № 94;',
        'Московский проспект № 3, № 6;',
        'Олега Кошевого, Песчаная, Пешеходная с № 12 по № 73;',
        'Питомник, Подклетенская, Поселковая, Свердлова нечетные с № 15 по № 39, четные с № 62 по № 94;',
        'Семилукская, Солнечная нечетные с № 1 по № 13а, №№ 2, 10, 23, 27;',
        'Торпедо, Труда проспект четные с № 52 по № 88, нечетные с № 73 по № 159;',
        'Урицкого №№ 68, 118, 124, 126, 128, нечетные с № 135 по № 157;',
        'Урожайная, Утренняя, Холмистая.',
        'Переулки: Автоматчиков, Анненский, Валуйский, Вольный, Газовый, Газопроводный, Грибановский, Дозорный, Дружный, Жигулевский, Красно-Лимановский, Лискинский, Партизанский, Подклетенский, Попутный, Поселковый, Рамонский, Станичный, Уютный, Хреновской, Чесменский, Чистый, Ямской. ',
      ],
    },
    17: {
      people: 33973,
      news: [
        {
          title: 'Воронежцы с Крестов оценили своего депутата Сергея Кудрявцева',
          url: 'https://bloknot-voronezh.ru/news/voronezhtsy-s-krestov-otsenili-svoego-deputata-ser',
        },
      ],
      streets: [
        'Улицы: 303 стрелковой дивизии дома №№ 1, 3, 5;',
        '45 стрелковой дивизии нечетные с № 197 по № 285, четные с № 192 по № 220; №№ 226а, 230а, 232, 234, 234г;',
        'Антонова-Овсеенко дома №№ 7б, 7л, 9, 11, 13, 15, 19, 21, 23;',
        'Беговая № 98, четные с № 104 по № 118, №№ 122, 128, с № 130 по № 172, нечетные с № 163 по № 193, №№ 199, 201, 203а, 219, 219б, 219ж, 223, 223в, 225а, 225в; Беспаловой, Брянская четные с № 2 по № 76, нечетные с № 7 по № 91;',
        'Ведугская нечетные с № 75 по № 127, четные с № 62 по № 116;',
        'Верещагина нечетные с № 1 по № 55, четные с № 10 по № 58;',
        'Владимира Невского № 1а,',
        'Генерала Лизюкова нечетные с № 91 по № 105;',
        'Донская, Киевская, Крайняя, Курская, Народная, Новгородская четные с № 2 по № 124, нечетные с № 1 по № 141;',
        'Остроухова, Подгоренская, Правды, Радиозаводская, Рязанская, Связистов, Солнечная нечетные с № 37 по № 105, четные с № 12 по № 66;',
        'Строителей, Тамбовская, Товарищеская, Тульская, Хользунова четные с № 98 по № 118, нечетные с № 91 по № 125.',
        'Переулки: Верещагина, Веселый, Правды, Радиозаводской, Солнечный, Станкостроителей.',
        'Проезд Ясный.',
      ],
    },
    18: {
      people: 33241,
      news: [
        {
          title: 'Воронежцы заявили о полном равнодушии депутата Чистякова к их проблемам',
          url: 'https://bloknot-voronezh.ru/news/voronezhtsy-zayavili-o-polnom-ravnodushii-deputata-1237695',
        },
      ],
      streets: [
        'Улицы: 60 Армии №№ 2, 4, 6, 8;',
        '60-летия ВЛКСМ №№ 5, 7, 9;',
        '9 Января четные с № 238 по № 302, №№ 304а, 304б;',
        'Антонова-Овсеенко №№ 1, 23а, 25а;',
        'Владимира Невского №№ 1, 3, 7, 9, 9а, 15а;',
        'Гайдара №№ 17, 19, 21;',
        'Генерала Лизюкова №№ 42, 44, 46, 46а, четные с № 66а по № 80, нечетные с № 61в по № 89;',
        'Дружинников, Екатерины Зеленко, Жемчужная, Московский проспект №№ 5, 7, 8, 10;',
        'Победы бульвар №№ 45а, 47, 49, 51, 51а;',
        'Труда проспект №№ 36, 40, 42, 44, 59, 61;',
        'Хользунова четные с № 80 по № 96;',
        'Электросигнальная.',
      ],
    },
    19: {
      people: 34055,
      news: [
        {
          title: 'В напряженности с парковками в убитых дворах воронежцы обвинили депутата Гребенкина',
          url: 'https://bloknot-voronezh.ru/news/v-napryazhennosti-s-parkovkami-v-ubitykh-dvorakh-v-1238029',
        },
      ],
      streets: [
        'Улицы: 60 Армии №№ 22а, 22б, 23, 25, 26, 27, 28, 29а, 37;',
        '60-летия ВЛКСМ №№ 11, 13, 15, 21, 23, 25, 29;',
        'Антонова-Овсеенко дома №№ 29, 31, 31а, 33, 35в, 41;',
        'Владимира Невского четные с № 4 по № 38е, нечетные с № 13 по № 27, №№ 31, 33, 33а, 44, 46, 46/2, 46/3, 46/4;',
        'Генерала Лизюкова №№ 8, 8а, 10, 12, 14, 28, 32, 34, 54, 56, 66;',
        'Маршала Жукова № 5а, Миронова, Победы бульвар нечетные с № 13 по № 43, четные с № 12 по № 50в;',
        'Шукшина.',
      ],
    },
    20: {
      people: 34998,
      news: [
        {
          title: 'В напряженности с парковками в убитых дворах воронежцы обвинили депутата Гребенкина',
          url: 'https://bloknot-voronezh.ru/news/v-napryazhennosti-s-parkovkami-v-ubitykh-dvorakh-v-1238029',
        },
      ],
      streets: [
        'Улицы: 60 Армии №№ 29, 31, 35;',
        'Владимира Невского №№ 31а, 31б, нечетные с № 35 по № 85б, №№ 40, 48, 48в;',
        'Маршала Жукова №№ 5, 7, 9, четные с № 6 по № 26;',
        'Мордасовой, Московский проспект нечетные с № 105 по № 185;',
        'Победы бульвар нечетные с № 1 по № 9, четные с № 2 по № 10.',
        'Аэропорт (общежитие).',
        'Жилые массивы: Задонье, Хвойный.',
        'Микрорайон Подгорное.',
      ],
    },
    21: {
      people: 35241,
      news: [
        {
          title: 'Воронежцы высказались о работе депутата-врача Юлии Поповой',
          url: 'https://bloknot-voronezh.ru/news/voronezhtsy-vyskazalis-o-rabote-deputata-vracha-yu-1240125',
        },
      ],
      streets: [
        'Улицы: 303 стрелковой дивизии дома №№ 2, 9, 11;',
        '45 стрелковой дивизии четные с № 2 по № 24, с № 66 по № 190, нечетные с № 1 по № 195;',
        'Абызова, Алексея Геращенко, Багрицкого, Беговая нечетные с № 1 по № 161, четные № 2/1 и с № 4 по № 94;',
        'Березовская, Брянская №№ 1, 3;',
        'Варейкиса №№ 5, 7, 51, 53, 55, 68, 69;',
        'Ватутина, Ведугская нечетные с № 1 по № 73, четные с № 2 по № 60;',
        'Верещагина №№ 2, 4, 6, 8;',
        'Вокзальная нечетные с № 3 по № 61, четные с № 2 по № 60;',
        'Елецкая, Задонская, Карпинского, Леваневского, Лидии Рябцевой, Московский проспект № 15, нечетные с № 23 по № 89, четные с № 12 по № 82;',
        'Нагорная, Овражная, Павлова, Победы, Просвещения, Рабочий проспект нечетные с № 47 по № 103, четные с № 62 по № 122;',
        'Республиканская нечетные с № 13 по № 21, четные с № 32 по № 78;',
        'Свердлова четные с № 2 по № 30;',
        'Славы, Социалистическая, Сочинская, Транспортная нечетные с № 1 по № 61;',
        'Труда проспект четные с № 4 по № 32, №№ 40а, 1, 3, нечетные с № 17 по № 37;',
        'Урицкого нечетные с № 7 по № 45, № 69, четные с № 4 по № 44, с № 56 по № 66а, с № 74 по № 84, №№ 88, 90, 92;',
        'Фруктовая, Хользунова №№ 13, 15, 19, 21, нечетные с № 23 по № 41, с № 83 по № 89;',
        'Чернышова, Чкалова, Шишкова нечетные с № 1по № 57, четные с № 4 по № 56. ',
        'Переулки: Автогенный, Бабушкина, Ботанический, Вокзальный, Дальний, Исполкомовский, Клинический, Коллективный, Коминтерновский, Мечникова, Новоселов, Политехнический, Приветливый, Ракетный, Республиканский, Самойловский, Славы, Снежный, Спокойный, Транспортный, Ученический, Федеративный, Школьный.',
      ],
    },
    22: {
      people: 35195,
      news: [
        {
          title: 'Рытвины на дорогах и запустение поставили в вину депутату Клецову в Воронеже',
          url: 'https://bloknot-voronezh.ru/news/rytviny-na-dorogakh-i-zapustenie-postavili-v-vinu--1240425',
        },
      ],
      streets: [
        'Улицы: 45 стрелковой дивизии четные с № 26 по № 62, № 62а;',
        '60 Армии нечетные с № 1 по № 17, № 21;',
        'Айдаровская, Багряная, Беговая №№ 2/2, 2/3;',
        'Веневитиновская, Генерала Лизюкова нечетные с № 3 по № 61, №№ 6, 16, 18, 36, 36а, 38, 40;',
        'Ипподромная, Историка Веселовского, Кораблинова, Курортная, Маршала Жукова №№ 3, 4;',
        'Московский проспект нечетные с № 91 по № 101, четные с № 90а по № 114;',
        'Ракитинская, Транспортная нечетные с № 65 по № 83;',
        'Хользунова четные с № 2 по № 76, нечетныес № 3 по № 9, №№ 17, 21а;',
        'Шишкова нечетные с № 61 по № 97, четные с № 60 по № 134;',
        'Шолохова.',
        'Переулки: Здоровья нечетные с № 1 по № 75 (кроме №№ 53а, 53б), четные с № 6 по № 34, с № 74 по № 90о.',
        'Жилой массив Ясенки.',
      ],
    },
    23: {
      people: 30765,
      news: [
        {
          title: 'Избиратели депутата Александра Трубецкого оценили его работу в округе №23 в Воронеже',
          url: 'https://bloknot-voronezh.ru/news/izbirateli-deputata-aleksandra-trubetskogo-otsenil',
        },
      ],
      streets: [
        'Улицы: 8 Марта, Академика Конопатова, Академика Першина, Академика Прянишникова, Березовая Роща, Бунакова, Бурденко, Вавилова, Гастелло, Героев Красной Армии, Героев Революции, Героев Труда, Городовикова, Дарвина, Демократии, Дзиньковского, Добровольческого Коммунистического Полка (Линейная), Докучаева, Дуговая, Дурова, Жилина, Железноводская, Калинина, Казакова, Кавалерийская, Кисловодская, Келлера, Коммунаров, Коммунистической Молодежи, Красовского, Крестьянская, Крутая, Красной Работницы, Лейтенанта Бовкуна, Ленина, Лесной Поселок, Логвиновская, Лобачевского, Ломоносова, Луначарского, Марии Копыловой, Максима Горького, Максима Горького набережная, Мало-Смоленская, Мало-Терновая, Массалитинова набережная с № 8 по № 32;',
        'Мичурина, Морозова, Нарядная, Обороны Революции, Октябрьской Революции, Олимпийский бульвар, Полевая, Поспеева, Пролетарской Диктатуры, Пролетарская нечетные с № 1 по № 27, четные с № 2 по № 18;',
        'Пятигорская, Рабочий проспект нечетные с № 1 по № 45/4, четные с № 2 по № 46а;',
        'Рабочего Класса, Рабочий Городок, Республиканская нечетные с № 1 по № 11, четные с № 2 по № 30;',
        'Рылеева, Сакко и Ванцетти нечетные с № 1 по № 69, четные с № 2 по № 78а;',
        'Смоленская, Советская, Танкиста Серебрякова, Тимирязева, Транспортная четные с № 2 по № 60а;',
        'Трудовая, Урицкого №№ 2, 2а, 3, 5;',
        'Учебный кордон, Ушинского, Фронтовая, Цветущая, Целинная, Швейников, Шишкова нечетные с № 99 по № 107а, №№ 142, 142/5, 142/8, 144б, 144в, 146в;',
        'Юных Натуралистов.',
        'Переулки: Академика Гмелина, Героев Революции, Дуговой, Здоровья №№ 53а, 53б, четные с № 32а по № 72;',
        'Ессентуки, Купянский, Лесной, Лесокультурный, Лечебный, Опытный, Оранжерейный, Отрадный, Проходной, Разведчиков, Советский, Спортивный, Солдатский, Тимирязева, Учебный, Учительский, Целинный.',
        'Проезд Кисловодский.',
        'Жилые массивы: Ветряк, Лесная поляна, Лесная поляна-1, Лесная поляна-3, Олимпийский.',
        'Жилые дома: Ботанического сада, санатория имени Максима Горького, Правобережного лесничества.',
      ],
    },
    24: {
      people: 32182,
      news: [
        {
          title: 'О неприглядной стороне центра Воронежа рассказали избиратели депутата Сысоева',
          url: 'https://bloknot-voronezh.ru/news/o-nepriglyadnoy-storone-tsentra-voronezha-rasskaza',
        },
      ],
      streets: [
        'Улицы: 20-летия ВЛКСМ, 25 Октября, 3 Интернационала нечетные с № 1 по № 31, № 4;',
        'Авиационная, Алексеевского, Арсенальная, Батуринская, Белинского нечетные с № 1 по № 17б, четные с № 2 по № 14;',
        'Бехтерева, Вайцеховского, Войкова № 8, Володарского четные с № 10 по № 70, нечетные с № 37 по № 41;',
        'Генерала Черняховского площадь, Герцена, Дзержинского, Декабристов, Детей площадь, Достоевского, Желябова, Каляева, Кардашова, Карла Маркса, Кольцовская нечетные с № 1 по № 31, четные с № 2 по № 46а;',
        'Комиссаржевской, Короленко, Кости Стрелюка, Красненькая, Ленина площадь с № 8 по № 15;',
        'Летчика Замкина, Манежная Малая, Манежная Большая, Массалитинова набережная с № 33 по № 39а;',
        'Мира, Никитинская нечетные с № 1 по № 27, четные с № 2 по № 32;',
        'Ольминского, Орджоникидзе нечетные с № 3 по № 31, четные с № 2/4 по № 26;',
        'Освобождение Труда, Петра Алексеева, Первомайская, Плехановская четные с № 2 по № 66б;',
        'Помяловского, Пролетарская нечетные с № 29 по № 87б, четные с № 22 по № 72;',
        'Пушкинская нечетные с № 1 по № 7, №№ 2, 4;',
        'Пятницкого, Революции 1905 года нечетные с № 1 по № 11, четные с № 2 по № 42;',
        'Революции проспект, Сакко и Ванцетти нечетные с № 73 по № 93, четные с № 80 по № 106;',
        'Сиреневая, Софьи Перовской с № 1 по № 76;',
        'Средне-Московская, Студенческая, Степана Разина, Суконовка Левая, Суконовка Правая, Таранченко, Театральная, Университетская площадь, Фридриха Энгельса нечетные с № 1 по № 37, четные с № 2 по № 56;',
        'Феоктистова, Художника Бучкури, Цюрупы, Чайковского, Чернышевского, Чернавская Большая, Эртеля.',
        'Переулки: Бауманский, Бехтерева, Горный, Детский, Индустриальный, Мельничный, Мордовцева, Пряничный, Рабфаковский, Скорняжный, Старомосковский, Тимуровцев, Фабричный, Эртеля.',
      ],
    },
  },
  region: {
    1: { people: 69593 },
    2: { people: 66826 },
    3: { people: 64371 },
    4: { people: 67722 },
    5: { people: 73716 },
    6: { people: 72448 },
    7: { people: 65542 },
    8: { people: 64211 },
    9: { people: 67214 },
    10: { people: 69053 },
    11: { people: 70436 },
    12: { people: 62947 },
    13: { people: 68395 },
    14: { people: 69718 },
    15: { people: 68119 },
    16: { people: 61583 },
    17: { people: 71050 },
    18: { people: 70836 },
    19: { people: 61136 },
    20: { people: 78141 },
    21: { people: 71896 },
    22: { people: 67774 },
    23: { people: 62775 },
    24: { people: 55919 },
    25: { people: 75085 },
    26: { people: 61278 },
    27: { people: 73084 },
    28: { people: 57236 },
  },
}
export default areas
