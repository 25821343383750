import { CandidateStatuses, LocationType, Parties, Person } from '../../types'

const ldpr: Person[] = [
  {
    alias: 'abramov_denis',
    name: 'Абрамов Денис Дмитриевич',
    birthdate: '09.02.1998',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 10,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 10,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 13,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'bekhov_maksim',
    name: 'Бехов Максим Николаевич',
    birthdate: '28.04.2000',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 5,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 5,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'burdakov_maksim',
    name: 'Бурдаков Максим Алексеевич',
    birthdate: '24.04.1996',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 2,
        callNumber: 5,
        locationType: LocationType.city,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 2,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 11,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'goverdovskij_nikita',
    name: 'Говердовский Никита Сергеевич',
    birthdate: '25.10.2000',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 4,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 4,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'guzenko_konstantin',
    name: 'Гузенко Константин Эдуардович',
    birthdate: '31.01.2000',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 12,
        callNumber: 5,
        locationType: LocationType.city,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 12,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'dorohin_vladimir',
    name: 'Дорохин Владимир Николаевич',
    birthdate: '04.11.1993',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 15,
        locationType: LocationType.city,
        callNumber: 5,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 15,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 8,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kizenuk_alla',
    name: 'Киценюк Алла Александровна',
    birthdate: '04.06.1984',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 8,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 8,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 7,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'parinov_igor',
    name: 'Паринов Игорь Максимович',
    birthdate: '07.09.2000',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 19,
        callNumber: 5,
        locationType: LocationType.city,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 0,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'polubavkina_alina',
    name: 'Полубавкина Алина Николаевна',
    birthdate: '25.06.1995',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 13,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 13,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 16,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'popov_maksim',
    name: 'Попов Максим Валерьевич',
    birthdate: '26.08.1999',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 23,
        callNumber: 5,
        locationType: LocationType.city,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 23,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'starodubov_oleg',
    name: 'Стародубов Олег Владимирович',
    birthdate: '13.10.1977',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 22,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 22,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'filippov_maksim',
    name: 'Филиппов Максим Иванович',
    birthdate: '14.02.1977',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 20,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 20,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 21,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shvynda_denis',
    name: 'Швында Денис Михайлович',
    birthdate: '04.09.1978',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 6,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 6,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 3,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'yakovlev_yurij',
    name: 'Яковлев Юрий Васильевич',
    birthdate: '08.06.1963',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 16,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 0,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 13,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'bahirev_artem',
    name: 'Бахирев Артем Геннадьевич',
    birthdate: '25.12.1974',
    candidate: [
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 13,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 13,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'belousov_sergej',
    name: 'Белоусов Сергей Александрович',
    birthdate: '15.04.1984',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 17,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 11,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 17,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 11,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'boldin_roman',
    name: 'Болдин Роман Алексеевич',
    birthdate: '15.10.1978',
    candidate: [
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 23,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 23,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'volkova_irina',
    name: 'Волкова Ирина Владимировна',
    birthdate: '04.04.1979',
    candidate: [
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 3,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 4,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 3,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'volodarcev_anatolij',
    name: 'Володарцев Анатолий Викторович',
    birthdate: '24.03.1956',
    candidate: [
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 27,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 27,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'gutorov_mihail',
    name: 'Гуторов Михаил Сергеевич',
    birthdate: '22.01.1980',
    candidate: [
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 19,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 19,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'dzhogan_andrej',
    name: 'Джоган Андрей Васильевич',
    birthdate: '22.06.1977',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 24,
        callNumber: 5,
        locationType: LocationType.city,
        status: CandidateStatuses.registered,
      },
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 12,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 24,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 12,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'dunaeva_elizaveta',
    name: 'Дунаева Елизавета Владимировна',
    birthdate: '06.03.1984',
    candidate: [
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 21,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 21,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zheleznyak_yulian',
    name: 'Железняк Юлиан Юрьевич',
    birthdate: '04.07.1990',
    candidate: [
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 7,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 15,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 7,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kanevskij_anton',
    name: 'Каневский Антон Андреевич',
    birthdate: '02.02.1997',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 7,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 4,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 7,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 4,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kvitko_maksim',
    name: 'Квитко Максим Александрович',
    birthdate: '28.03.1991',
    candidate: [
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 24,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 24,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kiciyov_petr',
    name: 'Кициёв Петр Алексеевич',
    birthdate: '05.09.1988',
    candidate: [
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 25,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 25,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kudyakov_aleksej',
    name: 'Кудяков Алексей Андреевич',
    birthdate: '27.06.1995',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 3,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 15,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 3,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 2,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'lapturov_vyacheslav',
    name: 'Лаптуров Вячеслав Митрофанович',
    birthdate: '17.08.1964',
    candidate: [
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 28,
        status: CandidateStatuses.lost,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 28,
        listNumber: 1,
        status: CandidateStatuses.lost,
      },
    ],
  },
  {
    alias: 'mamchun_andrej',
    name: 'Мамчун Андрей Фёдорович',
    birthdate: '27.02.1981',
    candidate: [
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 20,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 15,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'mishchenko_evgenij',
    name: 'Мищенко Евгений Александрович',
    birthdate: '20.08.1996',
    candidate: [
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 22,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 22,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ovsyannikov_aleksandr',
    name: 'Овсянников Александр Александрович',
    birthdate: '25.02.1980',
    candidate: [
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 0,
        listNumber: 2,
        status: CandidateStatuses.registered,
        deputat: {},
      },
    ],
  },
  {
    alias: 'pavlenko_evgenij',
    name: 'Павленко Евгений Михайлович',
    birthdate: '16.03.1993',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 9,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 5,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 9,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'parinov_andrej',
    name: 'Паринов Андрей Николаевич',
    birthdate: '05.05.1997',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 1,
        locationType: LocationType.city,
        callNumber: 5,
        status: CandidateStatuses.registered,
      },
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 1,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 1,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'rodionov_rodion',
    name: 'Родионов Родион Витальевич',
    birthdate: '10.11.1997',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 14,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 8,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 14,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 8,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'roslyakov_andrej',
    name: 'Росляков Андрей Александрович',
    birthdate: '27.04.1970',
    candidate: [
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 26,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 26,
        listNumber: 1,
        status: CandidateStatuses.lost,
      },
    ],
  },
  {
    alias: 'seliverstov_vladimir',
    name: 'Селиверстов Владимир Владимирович',
    birthdate: '15.04.1990',
    candidate: [
      {
        party: Parties.ldpr,
        areaNumber: 11,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 6,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 11,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 6,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'cevovalnikov_andrej',
    name: 'Цевовальников Андрей Алексеевич',
    birthdate: '02.10.1972',
    candidate: [
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 17,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 17,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shishkina_tatyana',
    name: 'Шишкина Татьяна Михайловна',
    birthdate: '07.07.1983',
    candidate: [
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 18,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 18,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'yankauskene_tatyana',
    name: 'Янкаускене Татьяна Александровна',
    birthdate: '31.01.1984',
    candidate: [
      {
        callNumber: 7,
        party: Parties.ldpr,
        locationType: LocationType.region,
        areaNumber: 14,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 14,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zhirinovskij_vladimir',
    name: 'Жириновский Владимир Вольфович',
    birthdate: '25.04.1946',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 0,
        listNumber: 1,
        status: CandidateStatuses.registered,
        deputat: {},
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 0,
        listNumber: 1,
        status: CandidateStatuses.mandatRejected,
      },
    ],
  },
  {
    alias: 'nebrechin_sergej',
    name: 'Небренчин Сергей Алексеевич',
    birthdate: '14.01.1990',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 1,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'sabzalieva_anna',
    name: 'Сабзалиева Анна Александровна',
    birthdate: '05.01.1986',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 2,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 3,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'spicyna_marina',
    name: 'Спицына Марина Алексеевна',
    birthdate: '10.04.1972',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 3,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 27,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kanevskaya_anna',
    name: 'Каневская Анна Андреевна',
    birthdate: '08.07.1990',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 5,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 23,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'domnickaya_olesya',
    name: 'Домницкая Олеся Николаевна',
    birthdate: '15.09.1980',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 6,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'meshcheryakov_aleksandr',
    name: 'Мещеряков Александр Иванович',
    birthdate: '17.06.1981',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 8,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 5,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zadorozhnaya_anastasiya',
    name: 'Задорожная Анастасия Юрьевна',
    birthdate: '18.12.1995',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 9,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'tolstyh_elena',
    name: 'Толстых Елена Андреевна',
    birthdate: '17.01.1997',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 10,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 8,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zalozhnyh_dmitrij',
    name: 'Заложных Дмитрий Юрьевич',
    birthdate: '17.07.1996',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 11,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 6,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kosicyn_dmitrij',
    name: 'Косицын Дмитрий Андреевич',
    birthdate: '13.05.1982',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 12,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 6,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shatskih_aleksandr',
    name: 'Шатский Александр Иванович',
    birthdate: '04.01.1995',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 13,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'tabakov_dmitrij',
    name: 'Табаков Дмитрий Викторович',
    birthdate: '09.01.1977',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 14,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 2,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'golovina_irina',
    name: 'Головина Ирина Николаевна',
    birthdate: '20.12.1981',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 16,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 9,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'bartenev_aleksandr',
    name: 'Бартенев Александр Викторович',
    birthdate: '14.07.1983',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 16,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 20,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'andryakov_dmitrij',
    name: 'Андряков Дмитрий Анатольевич',
    birthdate: '16.09.1970',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 17,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 11,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'voronin_grigorij',
    name: 'Воронин Григорий Дмитриевич',
    birthdate: '15.04.1990',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 18,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 10,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kolcov_vladimir',
    name: 'Кольцов Владимир Иванович',
    birthdate: '16.07.1982',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 18,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 28,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kamasheva_irina',
    name: 'Камашева Ирина Валерьевна',
    birthdate: '28.05.1983',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 19,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 10,
        listNumber: 3,
        status: CandidateStatuses.lost,
      },
    ],
  },
  {
    alias: 'minakova_irina',
    name: 'Минакова Ирина Васильевна',
    birthdate: '18.02.1961',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 19,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 9,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'starodubova_ustinya',
    name: 'Стародубова Устинья Владимировна',
    birthdate: '22.08.1979',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 20,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 22,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'voronina_darya',
    name: 'Воронина Дарья Константиновна',
    birthdate: '22.03.1990',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 21,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 9,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'obrazcov_artyom',
    name: 'Образцов Артём Владимирович',
    birthdate: '12.05.1996',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 21,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 18,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'laptev_oleg',
    name: 'Лаптев Олег Дмитриевич',
    birthdate: '29.11.1999',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 22,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'bazhina_veronika',
    name: 'Бажина Вероника Владиславовна',
    birthdate: '14.04.1996',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 22,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 12,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'fadeev_denis',
    name: 'Фадеев Денис Валерьевич',
    birthdate: '14.06.2000',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 23,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'gricenko_mihail',
    name: 'Гриценко Михаил Романович',
    birthdate: '09.07.1994',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 24,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 17,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'bolshov_pavel',
    name: 'Большов Павел Павлович',
    birthdate: '30.06.1989',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 0,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'rodionov_vitalij',
    name: 'Родионов Виталий Николаевич',
    birthdate: '23.01.1969',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 1,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'tonkih_aleksandr',
    name: 'Тонких Александр Анатольевич',
    birthdate: '30.11.1974',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 1,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'nebrenchin_sergej',
    name: 'Небренчин Сергей Алексеевич',
    birthdate: '14.01.1990',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 2,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ledovskoj_vladimir',
    name: 'Ледовской Владимир Романович',
    birthdate: '15.02.1997',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 4,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.ldpr,
        areaNumber: 7,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'domnickaya_olesya',
    name: 'Домницкая Олеся Николаевна',
    birthdate: '15.09.1980',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 4,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'pavlenko_evgenij',
    name: 'Павленко Евгений Михайлович',
    birthdate: '16.03.1993',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 5,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zadorozhnaya_anastasiya',
    name: 'Задорожная Анастасия Юрьевна',
    birthdate: '18.12.1995',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 5,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'korolev_pavel',
    name: 'Королев Павел Сергеевич',
    birthdate: '23.08.1996',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 7,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'majorova_galina',
    name: 'Майорова Галина Юрьевна',
    birthdate: '26.11.1987',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 10,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'bugaj_nataliya',
    name: 'Бугай Наталия Романовна',
    birthdate: '27.11.1997',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 12,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kosicyna_irina',
    name: 'Косицына Ирина Владимировна',
    birthdate: '20.01.1989',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 14,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kulapina_karina',
    name: 'Кулапина Карина Андреевна',
    birthdate: '14.06.1999',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 14,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'biryukov_maksim',
    name: 'Бирюков Максим Юрьевич',
    birthdate: '21.09.1994',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 15,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'evtuhova_olga',
    name: 'Евтухова Ольга Васильевна',
    birthdate: '27.07.1969',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 15,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'bocharov_vladislav',
    name: 'Бочаров Владислав Александрович',
    birthdate: '02.04.1995',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 16,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'trineeva_anna',
    name: 'Тринеева Анна Александровна',
    birthdate: '30.05.1989',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 16,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'mishchenko_elena',
    name: 'Мищенко Елена Сергеевна',
    birthdate: '10.02.1976',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 17,
        listNumber: 2,
        status: CandidateStatuses.lost,
      },
    ],
  },
  {
    alias: 'kalchenko_ekaterina',
    name: 'Кальченко Екатерина Николаевна',
    birthdate: '16.01.1992',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 18,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shnyak_larisa',
    name: 'Шняк Лариса Аркадьевна',
    birthdate: '25.06.1955',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 19,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'bujvolov_ivan',
    name: 'Буйволов Иван Александрович',
    birthdate: '30.04.1984',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 19,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'fadeeva_anna',
    name: 'Фадеева Анна Андреевна',
    birthdate: '06.01.1993',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 20,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'stepanov_viktor',
    name: 'Степанов Виктор Петрович',
    birthdate: '30.07.1971',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 20,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kozyreva_tatyana',
    name: 'Козырева Татьяна Александровна',
    birthdate: '01.01.1969',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 21,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'boldina_mariya',
    name: 'Болдина Мария Михайловна',
    birthdate: '03.11.1982',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 23,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'fadeev_dmitrij',
    name: 'Фадеев Дмитрий Валерьевич',
    birthdate: '24.02.1989',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 24,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'berlyakova_marina',
    name: 'Берлякова Марина Ивановна',
    birthdate: '26.07.1964',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 24,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'lapturova_olga',
    name: 'Лаптурова Ольга Валерьевна',
    birthdate: '12.04.1990',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 25,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shatskij_aleksandr',
    name: 'Шатский Александр Иванович',
    birthdate: '04.01.1995',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 25,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shapoval_boris',
    name: 'Шаповал Борис Григорьевич',
    birthdate: '08.02.1978',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 26,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'bondarenko_yurij',
    name: 'Бондаренко Юрий Иванович',
    birthdate: '17.06.1978',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 26,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'skoryukov_vladimir',
    name: 'Скорюков Владимир Александрович',
    birthdate: '18.04.1967',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 27,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'gelunov_anatolij',
    name: 'Гелунов Анатолий Афанасьевич',
    birthdate: '19.08.1950',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.ldpr,
        areaNumber: 28,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
]

export default ldpr
