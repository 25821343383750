import * as photos from '../../images/candidates'
import { CandidateStatuses, LocationType, Parties, Person } from '../../types'

const sr: Person[] = [
  {
    alias: 'akulov_evgenij_alekseevich',
    name: 'Акулов Евгений Алексеевич',
    birthdate: '20.01.1970',
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 20,
        locationType: LocationType.city,
        callNumber: 5,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 20,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 10,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'gajchenya_svetlana',
    name: 'Гайченя Светлана Станиславовна',
    birthdate: '18.01.1972',
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 1,
        callNumber: 5,
        locationType: LocationType.city,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 1,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 12,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zheltuha_evgenij',
    name: 'Желтуха Евгений Васильевич',
    birthdate: '25.02.1980',
    photo: photos.zheltukha,
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 19,
        locationType: LocationType.city,
        callNumber: 5,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 19,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zemlyanskaya_nina',
    name: 'Землянская Нина Павловна',
    birthdate: '09.03.1941',
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 3,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 3,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 2,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ivannikov_nikita',
    name: 'Иванников Никита Геннадьевич',
    birthdate: '07.04.1997',
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 14,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 7,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 14,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 7,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kazaryan_paruir',
    name: 'Казарян Паруир Арутюнович',
    birthdate: '08.02.1986',
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 21,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 21,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 11,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kolyagin_yurij',
    name: 'Колягин Юрий Иванович',
    birthdate: '27.12.1964',
    photo: photos.kolyagin,
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 17,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 24,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'lyskov_anton',
    name: 'Лысков Антон Юрьевич',
    birthdate: '05.01.1980',
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 22,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 22,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'maslennikova_margarita',
    name: 'Масленникова Маргарита Юрьевна',
    birthdate: '04.03.1974',
    photo: photos.maslennikova,
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 5,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 3,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 5,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 3,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'naumov_ilya',
    name: 'Наумов Илья Сергеевич',
    birthdate: '30.04.1984',
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 18,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 9,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 18,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 9,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'neznamova_natalya',
    name: 'Незнамова Наталья Анатольевна',
    birthdate: '14.05.1992',
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 6,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 6,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 3,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'petrov_denis',
    name: 'Петров Денис Сергеевич',
    birthdate: '23.10.1977',
    photo: photos.petrov,
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 11,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 11,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 6,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'peshkov_gennadij',
    name: 'Пешков Геннадий Геннадьевич',
    birthdate: '28.10.1989',
    photo: photos.peshkov,
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 16,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 8,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 16,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 8,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'repp_vitalij',
    name: 'Репп Виталий Александрович',
    birthdate: '26.08.1998',
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 7,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 17,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 7,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 17,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'rubcov_evgenij',
    name: 'Рубцов Евгений Николаевич',
    birthdate: '10.12.1980',
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 12,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 12,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'rymar_artem',
    name: 'Рымарь Артем Сергеевич',
    birthdate: '16.04.1976',
    photo: 'http://gorduma-voronezh.ru/wp-content/uploads/2019/05/rymar_as.jpg',
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 23,
        callNumber: 5,
        locationType: LocationType.city,
        status: CandidateStatuses.registered,
        deputat: {
          office: 'Ленинский проспект, 158/2',
          phones: ['204-52-31', '8 -808-141-13-20'],
          website: 'http://gorduma-voronezh.ru/rymar-artem-sergeevich/',
          helpers: ['Краус Анжела Александровна'],
        },
      },
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 12,
        status: CandidateStatuses.registered,
        deputat: {},
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 0,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 0,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'slepchenko_yurij',
    name: 'Слепченко Юрий Николаевич',
    birthdate: '24.01.1975',
    photo: photos.slepchenko,
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 9,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 9,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 5,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'suyazova_anastasiya',
    name: 'Суязова Анастасия Андреевна',
    birthdate: '10.02.1996',
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 15,
        locationType: LocationType.city,
        callNumber: 5,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 18,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'titaev_sergej',
    name: 'Титаев Сергей Владимирович',
    photo: photos.titaev_sergey,
    birthdate: '25.04.1978',
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 10,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 10,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'tyuhin_aleksej',
    name: 'Тюхин Алексей Иванович',
    birthdate: '22.12.1981',
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 24,
        callNumber: 5,
        locationType: LocationType.city,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 24,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 20,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'hoperskov_aleksandr',
    name: 'Хоперсков Александр Сергеевич',
    birthdate: '29.08.1994',
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 4,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 4,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 2,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'chernyavskij_oleg',
    name: 'Чернявский Олег Викторович',
    birthdate: '15.03.1987',
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 13,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 13,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 27,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shershnev_yurij',
    name: 'Шершнев Юрий Андреевич',
    birthdate: '11.04.1950',
    candidate: [
      {
        party: Parties.sr,
        areaNumber: 8,
        status: CandidateStatuses.registered,
        callNumber: 5,
        locationType: LocationType.city,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 8,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 4,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'belkov_vyacheslav',
    name: 'Бельков Вячеслав Евгеньевич',
    birthdate: '30.04.1993',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 18,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 18,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zemlyanuhin_evgenij',
    name: 'Землянухин Евгений Александрович',
    birthdate: '15.10.1992',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 15,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 15,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kashirin_vitalij',
    name: 'Каширин Виталий Владимирович',
    birthdate: '24.05.1963',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 4,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 7,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 4,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kvasov_konstantin',
    name: 'Квасов Константин Алексеевич',
    birthdate: '22.06.1972',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 5,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 5,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'klimov_vitalij',
    name: 'Климов Виталий Николаевич',
    birthdate: '01.10.1954',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 27,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 27,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kolobov_anton',
    name: 'Колобов Антон Андреевич',
    birthdate: '24.06.1985',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 20,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 15,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 20,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kolupaev_nikolaj',
    name: 'Колупаев Николай Андреевич',
    birthdate: '25.11.1998',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 21,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 21,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'korneva_lyudmila',
    name: 'Корнева Людмила Райнольдовна',
    birthdate: '03.09.1963',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 13,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 13,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'korolev_sergej',
    name: 'Королев Сергей Юрьевич',
    birthdate: '21.09.1954',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 1,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'korotkov_sergej',
    name: 'Коротков Сергей Александрович',
    birthdate: '13.05.1978',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 23,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 23,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kurenkov_boris',
    name: 'Куренков Борис Владимирович',
    birthdate: '28.01.1984',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 26,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 25,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'larin_denis',
    name: 'Ларин Денис Олегович',
    birthdate: '18.04.1977',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 6,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 11,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 6,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'markova_olga',
    name: 'Маркова Ольга Яковлевна',
    birthdate: '05.01.1980',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 28,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 28,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'mordvinov_vitalij',
    name: 'Мордвинов Виталий Николаевич',
    birthdate: '01.03.1985',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 19,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 19,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'myagkov_aleksandr',
    name: 'Мягков Александр Юрьевич',
    birthdate: '11.07.1987',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 16,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 16,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ponomarev_andrej',
    name: 'Пономарев Андрей Сергеевич',
    birthdate: '28.12.1995',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 11,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 11,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'sinelnikov_aleksej',
    name: 'Синельников Алексей Николаевич',
    birthdate: '01.01.1986',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 22,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 22,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'stupnikova_olga',
    name: 'Ступникова Ольга Викторовна',
    birthdate: '04.05.1977',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 14,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 14,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'harcyzov_roman',
    name: 'Харцызов Роман Александрович',
    birthdate: '21.08.1986',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 25,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 25,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shalimov_sergej',
    name: 'Шалимов Сергей Витальевич',
    birthdate: '10.03.1961',
    candidate: [
      {
        party: Parties.sr,
        callNumber: 7,
        locationType: LocationType.region,
        areaNumber: 10,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zaharov_denis',
    name: 'Захаров Денис Олегович',
    birthdate: '28.04.1988',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 0,
        listNumber: 2,
        status: CandidateStatuses.registered,
        deputat: {},
      },
    ],
  },
  {
    alias: 'suverin_andrej',
    name: 'Суверин Андрей Александрович',
    birthdate: '01.11.1971',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 0,
        listNumber: 3,
        status: CandidateStatuses.registered,
        deputat: {},
      },
    ],
  },
  {
    alias: 'chigirin_vladimir',
    name: 'Чигирин Владимир Иванович',
    birthdate: '31.03.1964',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 1,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 13,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'repp_vladimir',
    name: 'Репп Владимир Александрович',
    birthdate: '26.08.1998',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 2,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 17,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'vasnev_igor',
    name: 'Васнев Игорь Николаевич',
    birthdate: '03.10.1992',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 2,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'portej_vasilij',
    name: 'Портей Василий Игоревич',
    birthdate: '29.05.1999',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 3,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 17,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'perunova_irina',
    name: 'Перунова Ирина Владимировна',
    birthdate: '20.07.1971',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 4,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 2,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'borisov_fedor',
    name: 'Борисов Федор Александрович',
    birthdate: '16.12.1995',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 5,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 3,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'perunov_vladimir',
    name: 'Перунов Владимир Владимирович',
    birthdate: '02.06.1977',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 6,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ponomareva_alena',
    name: 'Пономарева Алена Александровна',
    birthdate: '30.07.2000',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 8,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'alekseeva_olga',
    name: 'Алексеева Ольга Владимировна',
    birthdate: '24.01.1978',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 9,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 23,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'sviridova_irina',
    name: 'Свиридова Ирина Сергеевна',
    birthdate: '30.10.1986',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 10,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 5,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'titaev_dmitrij',
    name: 'Титаев Дмитрий Владимирович',
    birthdate: '08.01.1974',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 12,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 5,
        listNumber: 4,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kolesnik_larisa',
    name: 'Колесник Лариса Александровна',
    birthdate: '16.06.1981',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 13,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'bozhenov_vadim',
    name: 'Боженов Вадим Олегович',
    birthdate: '29.03.1994',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 14,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 7,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'osipov_anton',
    name: 'Осипов Антон Валерьевич',
    birthdate: '22.12.1989',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 15,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 8,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'bazhenova_ekaterina',
    name: 'Баженова Екатерина Андреевна',
    birthdate: '07.04.1995',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 16,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'smirnova_elvira',
    name: 'Смирнова Эльвира Сергеевна',
    birthdate: '21.12.1999',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 17,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'suchkov_dmitrij',
    name: 'Сучков Дмитрий Николаевич',
    birthdate: '10.08.1997',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 17,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 23,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'obolonskaya_elena',
    name: 'Оболонская Елена Алексеевна',
    birthdate: '30.06.1964',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 18,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 9,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'lalaeva_veronika',
    name: 'Лалаева Вероника Михайловна',
    birthdate: '02.05.1974',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 19,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 10,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'krivonos_valentina',
    name: 'Кривонос Валентина Игоревна',
    birthdate: '05.07.1999',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 20,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 6,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'bychkov_igor',
    name: 'Бычков Игорь Николаевич',
    birthdate: '01.02.1998',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 21,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 21,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'danilova_viktoriya',
    name: 'Данилова Виктория Вячеславовна',
    birthdate: '13.05.1998',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 22,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 11,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'litvinova_nadezhda',
    name: 'Литвинова Надежда Сергеевна',
    birthdate: '07.06.1987',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 23,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 12,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'tronchuk_olga',
    name: 'Трончук Ольга Анатольевна',
    birthdate: '29.01.1989',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 23,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 12,
        listNumber: 4,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kraus_anzhela',
    name: 'Краус Анжела Александровна',
    birthdate: '11.01.1976',
    candidate: [
      {
        locationType: LocationType.city,
        callNumber: 5,
        party: Parties.sr,
        areaNumber: 24,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 12,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shmygalev_anatolij',
    name: 'Шмыгалев Анатолий Петрович',
    birthdate: '14.11.1965',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 0,
        listNumber: 1,
        status: CandidateStatuses.registered,
        deputat: {},
      },
    ],
  },
  {
    alias: 'oryupin_evgenij',
    name: 'Орюпин Евгений Викторович',
    birthdate: '12.09.1981',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 0,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'dolgih_yurij',
    name: 'Долгих Юрий Викторович',
    birthdate: '05.05.1980',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 1,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'brazhnik_lev',
    name: 'Бражник Лев Александрович',
    birthdate: '20.03.1997',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 1,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kolyagin_vladislav',
    name: 'Колягин Владислав Юрьевич',
    birthdate: '07.06.1995',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 4,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'yakovenko_andrej',
    name: 'Яковенко Андрей Алексеевич',
    birthdate: '06.01.1978',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 7,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'bobrovskaya_margarita',
    name: 'Бобровская Маргарита Евгеньевна',
    birthdate: '08.10.1975',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 8,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'pronina_galina',
    name: 'Пронина Галина Геннадьевна',
    birthdate: '27.09.1981',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 9,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shalimov_roman',
    name: 'Шалимов Роман Сергеевич',
    birthdate: '26.09.1989',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 10,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'dubinina_nataliya',
    name: 'Дубинина Наталия Георгиевна',
    birthdate: '01.01.1955',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 12,
        listNumber: 5,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'grinyova_darya',
    name: 'Гринёва Дарья Владимировна',
    birthdate: '15.06.1982',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 13,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'koncov_evgenij',
    name: 'Концов Евгений Алексеевич',
    birthdate: '22.01.1990',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 14,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'egorkina_inna',
    name: 'Егоркина Инна Ивановна',
    birthdate: '29.08.1965',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 14,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'berezhnaya_marina',
    name: 'Бережная Марина Владимировна',
    birthdate: '07.01.1962',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 15,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'podkopaev_vladislav',
    name: 'Подкопаев Владислав Сергеевич',
    birthdate: '11.08.1998',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 15,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'panov_petr',
    name: 'Панов Петр Владимирович',
    birthdate: '15.09.1983',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 16,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'kolyagina_irina',
    name: 'Колягина Ирина Александровна',
    birthdate: '01.07.1970',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 16,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'belkov_dmitrij',
    name: 'Бельков Дмитрий Сергеевич',
    birthdate: '13.10.1986',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 18,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'ruj_igor',
    name: 'Руй Игорь Андреевич',
    birthdate: '28.05.1988',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 19,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'zajcev_aleksej',
    name: 'Зайцев Алексей Геннадьевич',
    birthdate: '06.09.1982',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 19,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'pravduhina_lidiya',
    name: 'Правдухина Лидия Евгеньевна',
    birthdate: '26.10.1988',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 20,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'bychkova_nataliya',
    name: 'Бычкова Наталия Евгеньевна',
    birthdate: '01.11.1990',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 21,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'frolov_vadim',
    name: 'Фролов Вадим Васильевич',
    birthdate: '30.11.1976',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 22,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'shahbazov_yurij',
    name: 'Шахбазов Юрий Фемистоколович',
    birthdate: '03.11.1954',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 22,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'topchiev_aleksandr',
    name: 'Топчиев Александр Николаевич',
    birthdate: '27.03.1970',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 25,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'sorokina_oksana',
    name: 'Сорокина Оксана Алексеевна',
    birthdate: '19.12.1992',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 26,
        listNumber: 1,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'pobit_olga',
    name: 'Побит Ольга Павловна',
    birthdate: '02.08.1973',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 26,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'pozdnyakov_aleksej',
    name: 'Поздняков Алексей Иванович',
    birthdate: '29.07.1980',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 26,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'popova_diana',
    name: 'Попова Диана Александровна',
    birthdate: '01.08.1999',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 27,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'suyazov_andrej',
    name: 'Суязов Андрей Валериевич',
    birthdate: '16.09.1971',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 28,
        listNumber: 2,
        status: CandidateStatuses.registered,
      },
    ],
  },
  {
    alias: 'krutskih_vladimir',
    name: 'Крутских Владимир Иванович',
    birthdate: '25.11.1972',
    candidate: [
      {
        locationType: LocationType.region,
        callNumber: 7,
        party: Parties.sr,
        areaNumber: 28,
        listNumber: 3,
        status: CandidateStatuses.registered,
      },
    ],
  },
]

export default sr
